<template>
  <v-card rounded="0" elevation="3" class="bgLight2Theme" outlined>
    <v-container fluid class="d-flex justify-space-between align-center">
      <div class="d-flex">
        <v-spacer></v-spacer>

        <v-btn :to="{ name: 'nuevoticket' }" class="btn-system" size="small"
          prepend-icon="fa:fa-solid fa-ticket" elevation="0"><small>NUEVO TICKET</small></v-btn>

        <v-btn v-if="$vuetify.display.xs" @click="openFiltroMobil()" class="btn-system px-2" size="small" elevation="0"
          icon>
          <v-icon icon="mdi:mdi-dock-right"></v-icon>
        </v-btn>

        <v-btn v-if="$vuetify.display.mdAndUp" @click="hidefilters = !hidefilters" class="btn-system px-2" size="small" elevation="0"
          icon>
          <v-icon icon="mdi:mdi-dock-right"></v-icon>
        </v-btn>

      </div>
    </v-container>
  </v-card>

  <v-container fluid>
    <v-row class="pa-0">
      <v-col cols="12" :md="hidefilters ? '12' : 'auto'" :xl="hidefilters ? '12' : '10'" class="pa-1 mx-auto"
        :style="[$vuetify.display.mdAndDown ? 'height: 500px' : '', $vuetify.display.mdAndUp ? 'width: 80%' : '']">
        <ListTickets :tickets="listTickets" :visitor="isRoleVisitor()"/>
      </v-col>

      <v-col v-if="!hidefilters && $vuetify.display.mdAndUp" cols="12" md="auto" xl="2" style="height: 100vh; 
    border: 1px solid #cfd7df;" class="bgLight2Theme px-4" :style="$vuetify.display.mdAndUp ? 'width: 20%' : ''">
        <FiltrosTicketsComponent :visitor="isRoleVisitor()"></FiltrosTicketsComponent>
      </v-col>
    </v-row>
  </v-container>

</template>
 
<script>
import { shallowRef } from 'vue';
import FiltrosTicketsComponent from "../components/FiltrosTicketsComponent.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import ListTickets from "../components/ListTickets.vue";
import Filters from '@/modules/Shared/Filters';
export default {
  mixins: [Filters],
  components: {
    ListTickets,
    FiltrosTicketsComponent,
  },

  data() {
    return {
      hidefilters: false
    };
  },

  computed: {
    ...mapState("tickets", ["listTickets"]),
    ...mapState("agentes", ["listaAgentes"])
  },

  methods: {

    ...mapActions("agentes", ['getAgentesAxios']),
    ...mapMutations(["changeSilderMulti", "changeComponentDynamic"]),
    ...mapMutations('answers', ['resetListAnswers']),

    openFiltroMobil()
    {
      const  component= shallowRef(FiltrosTicketsComponent)
      this.changeSilderMulti()
      this.changeComponentDynamic({componentName: component, props:{ class: "pa-5"}})
    },



  },

  mounted() {

    this.resetListAnswers()
  }
};
</script>

<style></style>
