import Module from './Module.vue';
import Tickets from './views/TicketsView.vue';
import TicketView from './views/TicketView.vue';
import NuevoTicketView from './views/NuevoTicketView.vue'
const moduleRoute = {
    path: "/tickets",
    component: Module,
    children: [
      {
        path: "", 
        component: Tickets,
        name: "tickets",
        meta: {
          requiresAuth: true,
          title: "Tickets",
        },
      },
      {
        path: "id/:codigoticket",
        component: TicketView,
        name: "ticketView",
        meta: {
          requiresAuth: true,
          title: "Ticket",
        },
      },
      {
        path: "nuevo",
        component: NuevoTicketView,
        name: "nuevoticket",
        meta: {
          requiresAuth: true,
          title: "Nuevo Ticket",
        },
      },
      
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };