<template>
  <v-bottom-navigation :elevation="0" class="bgBlue1Theme" style="bottom: 0px !important;">
    <v-btn :to="{ name: 'Dashboard' }" class="text-white" v-if="$abillity.can('read', 'panel-metricas')">
      <v-icon size="14">fa:fa-solid fa-gauge</v-icon>
      <small class="mt-1 text-white">DASHBOARD</small>
    </v-btn>
    <v-btn :to="{ name: 'profileUser' }" class="text-white">
      <v-icon size="14">fa:fa-solid fa-user</v-icon>
      <small class="mt-1 text-white">MI PERFIL</small>
    </v-btn>
    <v-btn :to="{ name: 'tickets' }" v-if="$abillity.can('read', 'tickets-area')">
      <v-icon color="white">mdi:mdi-ticket-outline</v-icon>
      <small class="mt-1 text-white">TICKETS</small>
    </v-btn>
    <v-btn :to="{ name: 'usuarios' }" v-if="$abillity.can('read', 'usuarios-area')">
      <v-icon color="white">mdi:mdi-account-search</v-icon>
      <small class="mt-1 text-white">USUARIOS</small>
    </v-btn>

  </v-bottom-navigation>
</template>
