import Module from './Module.vue';
import Login from './views/LoginView';
import RestorePasswordView from './views/RestorePasswordView.vue'
import MicrosoftLogin from './views/MicrosoftLogin.vue'
const moduleRoute = {
    path: "/login",
    component: Module,
    children: [
      {
        path: "/login",
        component: Login,
        name: "Login",
        meta: {
          requiresGuest: true,
          title: "Login",
        },
      },
      {
        path: "/restore-password/:email",
        component: RestorePasswordView,
        name: "RestorePass",
        meta: {
          requiresGuest: true,
          title: "Restaurar contraseña",
        },
      },
      {
        path: "/microsoft-login/:token",
        component: MicrosoftLogin,
        name: "MicrosoftLogin",
        meta: {
          requiresGuest: true,
          title: "Login Microsoft",
        },
      }
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };