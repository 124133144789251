<template>
    <v-card  elevation="0" class="bg-white mb-3" variant="outlined"
        style="border: 1px solid #cfd7df !important; opacity: 1 !important;">
        <v-card-text>
            <div class="mt-3">
                <span class="d-block text-center font-weight-medium">¿Necesitas crear un nuevo ticket referido a este caso? </span>
                <v-btn :to="'/tickets/nuevo?ref=' + ticket.codigo_ticket" block color="blue-accent-4" class="mt-3" elevation="0"><small>NUEVO TICKET ASOCIADO</small></v-btn>
            </div>
        </v-card-text>
        <v-divider></v-divider>
    </v-card>

</template>

<script>
import Filters from '@/modules/Shared/Filters';

export default{
    mixins:[Filters],
    props:{
        ticket:{
            type: Object,
            required: true
        }
    }

}
</script>