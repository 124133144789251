<template>
    <v-dialog :width="urlFile && isOthersArchive(getFileNameFromURL(urlFile)['extension']) ? 500 : 900"
        v-model="handleDialogAdjunto">
        <v-card class="bg-blue-grey-darken-2">
            <v-card-text class="pa-0">
                <iframe v-if="getFileNameFromURL(urlFile)['extension'] === 'pdf'" width="100%" height="800"
                    :src="urlFile" frameborder="0"></iframe>
                <v-img v-if="isImage(getFileNameFromURL(urlFile)['extension'])" :src="urlFile"></v-img>


                <div class="video-container" v-if="isVideo(getFileNameFromURL(urlFile)['extension'])">
                    <video controls >
                        <source :src="urlFile" v-if="getFileNameFromURL(urlFile)['extension'] == 'mp4'"
                            type="video/mp4">
                        <source :src="urlFile" v-if="getFileNameFromURL(urlFile)['extension'] == 'webm'"
                            type="video/webm">
                        <source :src="urlFile" v-if="getFileNameFromURL(urlFile)['extension'] == '3gp'"
                            type="video/3gp">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <audio v-if="isAudio(getFileNameFromURL(urlFile)['extension'])" controls>
                    <source :src="urlFile" type="audio/mp3">
                </audio>

                <div class="pa-4 text-center" v-if="isOthersArchive(getFileNameFromURL(urlFile)['extension'])">

                    <div class="d-inline-block px-8 mt-3 bg-white rounded-lg">
                        <div class="d-flex align-center justify-center pa-2 ">
                            <div class="mr-4">
                                <v-img
                                    :src="require('@/assets/' + iconExtension(getFileNameFromURL(urlFile)['extension']))"
                                    :width="90" class="mx-auto"></v-img>
                            </div>
                            <div class="text-center">
                                <div class="text-subtitle-1">
                                    {{ getFileNameFromURL(urlFile)['name'] + '.' +
                                        getFileNameFromURL(urlFile)['extension']
                                    }}
                                </div>
                                <div class="font-weight-bold text-subtitle-2">
                                    <a :href="urlFile" download>DESCARGAR</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="isOfficeDocuments(getFileNameFromURL(urlFile)['extension'])">
                    <a :href="urlFile" download style="text-decoration: none !important;">
                        <div class="text-subtitle-2 pa-2 text-center text-white">DESCARGAR AHORA</div>
                    </a>
                    <iframe frameborder="0" :src="`https://docs.google.com/viewer?url=${urlFile}&embedded=true`"
                        width="100%" height="600px"></iframe>
                </div>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapMutations, mapState } from 'vuex';

export default {
    mixins: [Filters],
    data() {
        return {
        }
    },
    computed: {
        ...mapState('tickets', ['dialogFileAdjunto', 'urlFile']),
        handleDialogAdjunto: {
            get() {
                return this.dialogFileAdjunto
            },

            set(val) {
                this.switchDialogFileAdjunto()
            }
        }
    },
    methods: {
        ...mapMutations('tickets', ['switchDialogFileAdjunto']),
        isImage(extension) {
            const imageTypes = ['jpg', 'jpeg', 'png', 'svg', 'ico', 'gif'];
            return imageTypes.includes(extension);
        },

        isVideo(extension) {
            const imageTypes = ['mp4', 'avi', '3gp'];
            return imageTypes.includes(extension);
        },
        isAudio(extension) {
            const imageTypes = ['mp3', 'audi'];
            return imageTypes.includes(extension);
        },

        isOfficeDocuments(extension) {
            const imageTypes = ['docx', 'xlsx', 'pptx'];
            return imageTypes.includes(extension);
        },



        isOthersArchive(extension) {
            const files = ['jpg', 'jpeg', 'png', 'svg', 'ico', 'gif', 'pdf', 'mp4', 'avi', '3gp', 'docx', 'xlsx', 'pptx', 'mp3', 'audi']
            return !files.includes(extension)
        },



    },

    mounted() {

    }
}
</script>

<style>
.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* Proporción de aspecto 16:9 */
    overflow: hidden;
    max-width: 100%; /* Ajusta el ancho máximo si es necesario */
    margin: 0 auto; /* Centra el contenedor */
  }

  .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ajusta cómo se redimensiona el contenido del video */
  }

audio {
    width: 100%;
}
</style>