import Module from './Module.vue';
import Home from './views/HomeView';

const moduleRoute = {
    path: "/",
    component: Module,
    children: [
      {
        path: "/",
        component: Home,
        name: "Home"
      }
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };