<template>
  <v-dialog v-model="onOpenDialog" width="900" persistent>
    <v-card elevation="0" class="pa-2">
      <v-card-text class="pb-0">
        <div class="mb-3">
          <div class="font-weight-medium text-md-h6 text-subtitle-1">
            Respuesta
          </div>
          <small>Puedes responder por este medio, o por email</small>
        </div>
        <v-progress-linear v-if="loadingAddAnswer" color="blue-lighten-3" indeterminate :height="7"></v-progress-linear>
        <v-form v-model="formavalidate" :style="loadingAddAnswer ? 'opacity: 0.5;' : 'opacity: 1;'"
          :disabled="loadingAddAnswer">
          <v-textarea class="d-none" :rules="[rules.required, rules.textareaSimple]" v-model="answer.message">
          </v-textarea>

          <Editor :api-key="apiTynyMCE" :init="configTiny"
            v-model="answer.message" />

          <v-row class="pa-4">
            <v-col cols="12" md="6">
              <label for="" class="font-weight-medium text-subtitle-2">ADJUNTAR ARCHIVO: (optional-solo si es
                necesario)</label>
              <small class="d-block">Archivos permitidos: PDF, jpg, jpge, png</small>
              <v-file-input variant="outlined" hide-details="auto" density="compact" bg-color="grey-lighten-5"
                label="pdf, jpg, jpge, png" prepend-icon="fa:fa-solid fa-circle-up" class="mt-2"
                @change="changeFileInput">
                <template v-slot:selection>

                </template>
              </v-file-input>

            </v-col>

            <v-col cols="12" md="6">
              <div v-if="answer.file.length > 0">Archivos cargados</div>
              <div class="pa-2">
                <div v-for="file in answer.file" class="ma-2">
                  <v-chip label color="primary">
                    <v-icon end @click="deleteFileUpload(file)" size="x-small">fa:fa-solid fa-circle-xmark</v-icon>
                    <small class="font-weight-medium">{{ file['name'] }} </small>
                  </v-chip>
                </div>
              </div>
            </v-col>
          </v-row>

          <div class="py-4">
            <v-btn :loading="loadingAnswers" @click="switchDialogTicket()" color="blue-grey-lighten-4"
              class="mr-2 ml-auto" size="large" elevation="0">
              <v-icon start>mdi:mdi-close</v-icon>
              Cancelar</v-btn>

            <v-btn :loading="loadingAnswers" :disabled="!formavalidate" @click="setAnswer(idticket)"
              color="blue-grey-darken-1" class="mr-2 ml-auto" size="large" elevation="0">
              <v-icon start>mdi:mdi-comment-text-multiple-outline</v-icon>
              Responder</v-btn>

            <v-row v-if="!isRoleVisitor()">
              <v-col cols="12" md="6">
                <div class="d-flex justify-center align-center" style="width: 200px;">
                  <v-checkbox id="notificar" class="ma-0" hide-details="auto" true-value="si" false-value="no"
                    v-model="answer.notificar"></v-checkbox>
                  <label for="notificar"> Notificar por correo</label>
                </div>
              </v-col>
              <v-col cols="12" md="6" >
                <div>
                  <label for="">Enviar una copia de la respuesta a:</label>
                  <v-autocomplete variant="outlined" density="compact" placeholder="correo@email.com"
                    v-model="answer.cc" multiple chips :disabled="answer.notificar == 'no'" :items="listaUsuarios" item-value="email" item-title="email" auto-select-first>
                  </v-autocomplete>
                </div>
              </v-col>
              <!--
              <v-col cols="12" md="6" >
                <div>
                  <label for="">Enviar CC:</label>
                  <v-text-field variant="outlined" density="compact" placeholder="correo@email.com"
                    v-model="copiaCorreo.email" @keypress="addEmailCC()" :disabled="answer.notificar == 'no'">
                    <template v-slot:default>
                      <div style="width: 100%;">
                        <v-chip label v-for="(e, index) in answer.cc" class="ma-1" size="small">
                          {{ e }}

                          <template v-slot:close>
                            <v-icon @click="removeCC(index)" style="cursor: pointer;">mdi:mdi-close</v-icon>
                          </template>
                        </v-chip>
                      </div> 
                    </template>
                  </v-text-field>
                </div>
              </v-col>
              -->
            </v-row>
          </div>
        </v-form>
      </v-card-text>


    </v-card>
  </v-dialog>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import Editor from "@tinymce/tinymce-vue";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    Editor,
  },
  props: {
    idticket:
    {
      type: Number
    }
  },
  mixins: [Filters],
  data() {
    return {
      formavalidate: true,
      configTiny: {
        selector: "textarea#basic-example",
        height: 300,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "preview",
          "anchor",
          "searchreplace",
          "code",
          "fullscreen",
          "media",
          "table",
          "wordcount",
          "fullscreen",
          "image",
          "imagetools"
        ],
        menubar: "table ",
        toolbar:
          "undo redo | blocks | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat preview image",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
        fullscreen_native: false,
        automatic_uploads: true,
        file_picker_types: 'image',
        image_title: true,
        images_upload_handler: function (blobInfo, success, failure) {
          const reader = new FileReader();
          reader.onload = function () {
            const base64 = reader.result.split(',')[1];
            success('data:image/png;base64,' + base64);
          };
          reader.readAsDataURL(blobInfo.blob());
        },
      },
    };
  },
  computed: {
    ...mapState('answers', ['answer', 'loadingAnswers', 'loadingAddAnswer', 'dialogCommentTicket', 'copiaCorreo']),
    ...mapState('usuarios', ["listaUsuarios"]), 
    ...mapState('tickets/utiles',["apiTynyMCE"]),
    onOpenDialog: {
      get() {
        return this.dialogCommentTicket;
      },

      set() {
        this.switchDialogTicket()
      }

    }
  },
  methods: {

    ...mapActions('answers', ['setAnswer']),
    ...mapMutations('answers', ['changeFileInput', 'deleteFileUpload', 'switchDialogTicket', 'addEmailCC', 'removeCC']),

    ...mapActions('usuarios', ['getAllUser']),
  },

  mounted() {
    this.getAllUser()
   },
};
</script>

<style>
.tox-fullscreen {
  position: absolute;
  z-index: 1000;
}

.tox-tinymce-aux {
  z-index: 999999 !important;
}
</style>
