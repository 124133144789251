import swal from "sweetalert";
import roles from "./roles"
import utiles from "@/modules/Shared/utiles/utiles";

import ApiColaboradorService from "@/modules/Shared/Services/ApiColaboradorService";
import RegisterUserService from "../services/RegisterUserService";
import AuthService from "../services/AuthService"

import Auth from "./Auth";
import store from "@/store";

const AuthSV = new AuthService();

const ApiColaboradorSV = new ApiColaboradorService();
const RegisterUserSV = new RegisterUserService();
export default {
  namespaced: true,
  modules: {
    roles,
    utiles,
    Auth
  },
  state: {
    tiposUsuario: [
      {
        title: "Colaborador",
        value: "colaborador",
      },
      {
        title: "Estudiante",
        value: "estudiante",
      },
    ],

    dialogEdit: false,
    dialogPass: false,
    user: {
      nombres: "",
      apellidos: "",
      email: "",
      tipo_documento: "",
      numero_documento: "",
      role_id: "",
      cargo: "",
      password: "",
      area: "",
      tipouser:"colaborador",
      carrera: "",
      admision: "",
      celular: ""
    },

    userDefault: {
      nombres: "",
      apellidos: "",
      email: "",
      tipo_documento: "",
      numero_documento: "",
      role_id: "",
      cargo: "",
      password: "",
      area: "",
      tipouser:"colaborador",
      carrera: "",
      admision: "",
      celular: ""
    },

    userPass: "",

    listaUsuarios: [],

    filter: 
    {
      buscar: ""
    },

    loadingbuscar: false,

    loadingregisteruser: false,

    loadingUpdatePass: false,

    loadingShowUser:false
  },

  mutations: {

    editUser(state, payload) {
      state.user = Object.assign({}, payload);
    },

    setApiNombreCompleto(state, data) {
      let separar = data.split(" ");
      //si el nombrecompleto tiene 4 elementos: son dos apellidos y dos nombres
      if (separar.length == 4) {
        state.user.apellidos = separar[0] + ' ' + separar[1]
        state.user.nombres = separar[2] + ' ' + separar[3]
      }
      //si el nombrecompleto tiene 3 elementos: son dos apellidos y un nombre
      if (separar.length == 3) {
        state.user.apellidos = separar[0] + ' ' + separar[1]
        state.user.nombres = separar[2]
      }
      //si el nombrecompleto tiene mas de 4 elementos: son dos apellidos y mas de un nombre
      // solo se usara el primer nombre
      if (separar.length > 4) {
        state.user.apellidos = separar[0] + ' ' + separar[1]
        state.user.nombres = separar[2]
      }
    },

    setListUsers(state, payload)
    {
      state.listaUsuarios = payload
    },

    resetUser(state)
    {
      state.user = Object.assign({}, state.userDefault)
    },

    pushUser(state, payload)
    {
      state.user = Object.assign({}, payload)
    }
  },

  actions: {

    async getDNIColaborador({ commit, state }) {
      try {
        const data = await ApiColaboradorSV.get(state.user.numero_documento)
        commit('setApiNombreCompleto', data.nombrecompleto)
        state.user.cargo = data.cargo;
        state.user.password = 'Cet' + data.documento + '*'
        state.user.email = data.correo
        state.user.area = data.area
      }
      catch (error) {
        console.log(error)
      }
    },


    async getAllUser({ commit, state }) {
      state.loadingbuscar =true
      try {
        const data = await RegisterUserSV.getAll(state.filter)
        commit('setListUsers', data)
        state.loadingbuscar =false
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError') 
        state.loadingbuscar =false
      }

    },


    async showUser({commit, state}, iduser)
    {
      state.loadingShowUser = true
      try {
        const data = await AuthSV.showUser(iduser)
        commit('pushUser', data)
        state.loadingShowUser = false
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError') 
        state.loadingShowUser = false

      }
    },

    async createUser({ commit, dispatch, state }, showalert = true) {
      if(showalert) {commit('utiles/alertLoading')}
      state.loadingregisteruser = true
      try {
        await RegisterUserSV.createUser(state.user)
        state.loadingregisteruser = false
        dispatch('getAllUser')
        if(showalert)
        {
          commit('utiles/alertConfirm', "Se ha registrado un nuevo usuario")
        }
        commit('resetUser')
        state.loadingregisteruser = false
      }
      catch (error) {
        console.log(error)
        if(error.response.status == 501)
        {
          commit('utiles/alertWarning', error.response.data.error)
          return
        }
        commit('utiles/alertError')
        state.loadingregisteruser = false
      }

    },

    async createUserInvitado({ commit, dispatch, state }) {
      state.loadingregisteruser = true
      try {
        await RegisterUserSV.registerSolicitante(store.state.login.datauserregister)
        commit('utiles/alertConfirm', "Se guardo la información")

        state.loadingregisteruser = false
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
        state.loadingregisteruser = false
      }

    },

  
    async updateUser({ commit, dispatch, state }) {
      commit('utiles/alertLoading')
      try {
        const data = await RegisterUserSV.updateUser(state.user) 
        console.log(data)
        dispatch('getAllUser')
        commit('utiles/alertConfirm', "Se ha actualizado el usuario")

      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
      }

    },

    async updateUserWithReload({ commit, dispatch, state }) {
      commit('utiles/alertLoading')
      try {
        const data = await RegisterUserSV.updateUser(state.user) 
        commit('utiles/alertConfirm', "Se ha actualizado el usuario")
        store.dispatch('login/reloadDataUser', data)

      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
      }

    },

    async switchActiveUser({commit, dispatch, state}, iduser)
        {
            commit('utiles/alertLoading')
            try
            {
                await AuthSV.switchActiveUser(iduser);
                dispatch('getAllUser')
                commit('utiles/alertConfirm', 'Se ha realizado el cambio')
            }
            catch(error)
            {
                console.log(error)
                commit('utiles/alertError')
            }
        }
  },
};
