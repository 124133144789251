import utiles from "@/modules/Shared/utiles/utiles"
import store from "@/store"
import axios from "axios"
import Swal from "sweetalert2"
import TipoService from "../services/TipoService"

const TipoSv = new TipoService();

export default {
    namespaced: true,
    modules: {
        utiles
    },
    state: {
        listTipo: [],
        loading: false,
        tipo: {
            nombre: "",
            descripcion: "",
            icon: "",
            color: ""
        },
        tipoDefault: {
            nombre: "",
            descripcion: "",
            icon: "",
            color: ""
        },
    },

    mutations: {
        setTipo(state, payload) {
            state.listTipo = payload
        },
        resetTipo(state) {
            state.tipo = Object.assign({}, state.tipoDefault)
        },

        editTipo(state, tipo) {
            state.tipo = Object.assign({}, tipo)
        }
    },

    actions: {
        async getAxiosTipo({ commit, state }) {
            state.loading = true
            try {
                const data = await TipoSv.getAll();
                commit('setTipo', data.tipos)
                state.loading = false
            } catch (error) {
                console.log(error)
                state.loading = false
                commit('utiles/alertError')
            }
        },


        async createTipoAxios({ commit, dispatch, state }) {
            commit('utiles/alertLoading');
            try {
                const data = await TipoSv.createTipo(state.tipo)
                commit('utiles/alertConfirm', "Se agregó nuevo registro");
                commit('resetTipo')
                dispatch('getAxiosTipo')
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }

        },


        async updateTipoAxios({ commit, dispatch, state }) {
            commit('utiles/alertLoading');
            try {
                const data = await TipoSv.updateTipo(state.tipo)
                commit('utiles/alertConfirm', "Se actualizó el registro");
                commit('resetTipo')
                dispatch('getAxiosTipo')

            } catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },


        async deleteTipoAxios({ commit, dispatch, state }, idtipo) {
            const confirm = await Swal.fire({
                title: "¿Desea eliminar este elemento?",
                icon: "question",
                cancelButtonText: "cancelar",
                confirmButtonText: "CONFIRMAR",
                showCancelButton: true,
            })

            if (!confirm.isConfirmed) { return }

            commit('utiles/alertLoading');
            try {
                await TipoSv.deleteTipo(idtipo);
                commit('utiles/alertConfirm', "Se eliminó el registro");
                commit('resetTipo')
                dispatch('getAxiosTipo')
            } catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }


        }

    }
}