<template>
  <v-row dense>
    <v-col cols="12" md="6">
      <v-img :src="require('@/assets/reset-password.svg')" class="mx-auto" width="100%"></v-img>
    </v-col>
    <v-col cols="12" md="6">
      <v-card elevation="0">
        <v-card-text>
          <div class="mb-8 text-left">
            <div class="text-md-h5 text-h6 font-weight-medium mb-2">Recuperar Contraseña</div>
            <span>Ingresa tu email para porder recibir los pasos para cambiar tu
              contraseña</span>
          </div>
          <v-form v-model="formvalidate">
            <label>Email</label>
            <v-text-field type="email" placeholder="correo@cetemin.edu.pe" density="compact" variant="outlined" hide-details="auto"
              class="mb-3" v-model="restorepass.email" :rules="[rules.email, rules.required]"></v-text-field>

            <v-btn variant="flat" size="small" class="mb-2" @click="switchComponent('FormLoginComponent')">
              Volver a Login</v-btn>

            <v-btn :disabled="!formvalidate" @click="getsendemailrestorepass()" :loading="loadingsendmail"
              color="indigo 900" block class="font-weight-medium py-5" rounded x-large>
              ENVIAR ENLACE</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapMutations, mapActions, mapState } from 'vuex'

export default {
  mixins: [Filters],
  data() {
    return {
      formvalidate: true
    }
  },
  computed: {
    ...mapState('login', ['restorepass', 'loadingsendmail'])
  },
  methods: {
    ...mapMutations('login', ['switchComponent']),
    ...mapActions('login', ['getsendemailrestorepass'])
  }
}
</script>
