import Module from './Module.vue';
import DashboardAgentesView from './views/DashboardAgentesView';

const moduleRoute = {
    path: "/",
    component: Module,
    children: [
      {
        path: "/agentes",
        component: DashboardAgentesView,
        name: "agentes"
      }
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };