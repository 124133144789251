import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";

export default class AgenteService
{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers:{
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization:'Bearer ' + store.state.user.token
            }
        })

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );


    }

    async getAll()
    {
        const response = await this.axios.get('/agente');
        return response.data
    }

    async register(data)
    {
        const response = await this.axios.post('/agente', data);
        return response.data
    }

    async delete(idagente)
    {
        const response = await this.axios.delete('/agente?idagente='+idagente);
        return response.data;
    }
}