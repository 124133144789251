import axios from "axios";

export default class ApiEstudianteService{
    constructor(){
        this.axios = axios.create({
            baseURL: "https://api-estudiantes.cetemin.edu.pe/api/get-api-for-dni",
            headers: {
                'Content-Type': "application/json",
                Accept: "application/json",
                Authorization: "1|FHwV5zZDcRuaGoUmWUuwWTmrdDgh4b05X6Cep7Kq5a64d070"
            }
        })
    }


    async get(numero){
        const response = await this.axios.get('?dni=' + numero)
        return response.data
    }
}