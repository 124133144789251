<template>
    <v-col cols="12" md="11" xl="10" class="py-0 mx-auto">
        <v-list-item class="my-0 px-2">
            <v-list-item-title class="text-h5 font-weight-medium">Dashboard</v-list-item-title>
        </v-list-item>
        <v-list class="bg-transparent d-flex" density="compact">
            <v-list-item class="bg-white my-1 pr-4 pl-2 mx-2" :to="{ name: 'dashboard-ticketsopen' }">
                <template v-slot:prepend>
                    <v-avatar size="small" class="bg-blue-grey-darken-2 text-white rounded-sm">
                        <v-icon size="x-small">fa:fa-solid fa-people-group</v-icon>
                    </v-avatar>
                </template>
                <v-list-item-title class="text-subtitle-2">Resumen de Tickets</v-list-item-title>
            </v-list-item>


            <v-list-item class="bg-white my-1 pr-4 pl-2" :to="{ name: 'dashboard-agente-perfomance' }">
                <template v-slot:prepend>
                    <v-avatar size="small" class="bg-blue-grey-darken-2 text-white rounded-sm">
                        <v-icon size="x-small">fa:fa-solid fa-users-gear</v-icon>
                    </v-avatar>
                </template>
                <v-list-item-title class="text-subtitle-2">Rendimiento de Agentes</v-list-item-title>
            </v-list-item>

        </v-list>
    </v-col>
</template>