<template>
    <v-navigation-drawer location="right" temporary v-model="slider" :width="detectedWidth">
        <div :class="props.class" :style="props.style">
            <keep-alive>
                <component v-bind:is="componentDynamic"></component>
            </keep-alive>
        </div>
    </v-navigation-drawer>
</template>


<script>
import { mapState, mapMutations } from 'vuex';
export default {

    watch:{
        silderMultipurpose(newVal,oldVal){
            if(newVal){
                document.documentElement.classList.add('no-scroll');
            }else{
                document.documentElement.classList.remove('no-scroll'); 
            }
        }
    },
    computed: {
        ...mapState(["silderMultipurpose", "componentDynamic", "props"]),

        slider: {
            get() {
                return this.silderMultipurpose
            },

            set() {
                this.changeSilderMulti()
            }
        },


        detectedWidth() {
            let display = this.$vuetify.display;
            if (display.xs) {
                return "350";
            } 
             if (display.md || display.lg) {
                return "500";
            } 
             if (display.xl) {
                return "650";
            }
        }
    },

    methods: {
        ...mapMutations(["changeSilderMulti"]),


    }
}
</script>


<style>
.no-scroll {
  overflow: hidden; /* Ocultar el scrollbar de la página HTML cuando se abre el cajón de navegación */
}
</style>