import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'


const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ user: state.user }),
}); 


export default createStore({
  plugins: [ 
    vuexLocal.plugin,
  ], 
  state: {
    silderMultipurpose: false,
    componentDynamic: "",
    props: {},
    user:
    {
      email: "",
      password: "",
      token: ""
    },
    userDefault:
    {
      email: "",
      password: "",
      token: ""
    },
    overlayLogin: false
  },
  getters: {
    getterLogginIn(state) { 
      return state.user != null && state.user.token;
    },
    getterToken(state)
    {
      return state.user.token;
    }
  },
  mutations: {
    changeSilderMulti(state){
      state.silderMultipurpose = !state.silderMultipurpose;
    },

    changeComponentDynamic(state, payload){
      const { componentName, props } = payload;
      state.componentDynamic = componentName
      state.props = Object.assign({}, props)
      console.log(state.props)
    },


  },
  actions: {
  },
  modules: {
  }
})
