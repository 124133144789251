import utiles from "@/modules/Shared/utiles/utiles";
import AgenteService from "../services/AgenteService"
import Swal from "sweetalert2";

const AgenteSV = new AgenteService();

export default{
    namespaced: true,
    modules:{
        utiles
    },
    state:
    {
        agente: {
            user_id: null
        },
        agenteDefault: {
            user_id: null
        },
        listAgentes: [],
        loadingAgente:false
    },
    mutations:
    {
        setAgentes(state, payload)
        {
            state.listAgentes = payload;
        },

        resetAgente(state)
        {
            state.agente = Object.assign({}, state.agenteDefault)
        }
    },

    actions:
    {
        async getAgentes({commit, state})
        {
            state.loadingAgente = true
            try{
                const data = await AgenteSV.getAll();
                commit('setAgentes', data)
                state.loadingAgente = false
            }
            catch(error){
                console.log(error)
                state.loadingAgente = false
            }
        },


        async registerAgente({commit, dispatch, state})
        {
            commit('utiles/alertLoading')
            try
            {
                const data = await AgenteSV.register(state.agente)
                commit('utiles/alertConfirm', 'Se registró el Agente')
                commit('resetAgente')
                dispatch('getAgentes')
            }catch(error)
            {
                if(error.response.status === 501)
                {
                    commit('utiles/alertWarning', error.response.data.error)
                    return
                }
                console.log(error)
                commit('utiles/alertError')
            }

        },

        async deleteAgente({commit, dispatch, state}, idagente)
        {
            const confirmar = await Swal.fire({
                title: '¿Desea eliminar este Agente?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "CONFIRMAR"
            })

            if(!confirmar.isConfirmed){return }

            commit('utiles/alertLoading')  
            try{
                await AgenteSV.delete(idagente);
                commit('utiles/alertConfirm', 'Se eliminó el Agente')
                dispatch('getAgentes')
            }
            catch(error)
            {
                
                console.log(error)
                commit('utiles/alertError')

            
            }
        }
    }
}