<template>
  <v-select hide-details="auto" density="compact" :items="listTipo" item-title="nombre" item-value="idtipo"
    :style="{width: width}" :readonly="readonly"
    style="border-bottom: none !important;" bg-color="bg-transparent" hide-no-data class="select-template" v-model="setectedItem"
    :menu-icon="readonly ? '' : 'fa:fa-solid fa-chevron-down fa-2xs'" :loading="loadingChangeTipo"  
    @update:modelValue="changeSelect({ idticket: idticket, ticket_tipo_new: setectedItem })">
    <template v-slot:selection="{ item }">
      <div class="d-flex align-center">
        <v-avatar start size="25" rounded="sm" :style="'background-color:' + item.raw.color"
          class="mr-2 text-white"><v-icon size="15">{{ item.raw.icon }}</v-icon></v-avatar>
        <div style="font-size: 12px" class="font-weight-bold">{{ item.raw.nombre }}</div>
      </div>
    </template>
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :subtitle="''" :title="''">
        <template v-slot:prepend>
          <v-avatar start size="25" rounded="sm" :style="'background-color:' + item.raw.color" class="mr-2 text-white"><v-icon
              size="15">{{ item.raw.icon }}</v-icon></v-avatar>
        </template>
        <v-list-item-title style="font-size: 12px" class="font-weight-bold">
          {{ item.raw.nombre }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-select>

</template>

 
<script>
import { mapActions, mapState } from 'vuex';
export default {
  props: {
    tipo: {
      type: Object
    },
    idticket: {
      type: Number
    },

    width:
    {
      type: String,
      default: '180px'
    },
    readonly:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tipoticket: {},
      setectedItem: null,
      loadingChangeTipo:false
    }
  },

  computed: {
    ...mapState('configuracion/tipo', ["listTipo"]),
    
  },

  methods: {
    ...mapActions('tickets', ['changeTypeAxios']),

    async changeSelect(data){
      this.loadingChangeTipo = true
      await this.changeTypeAxios(data)
      this.loadingChangeTipo = false
    }

  },

  mounted() {
    this.setectedItem = this.tipo.idtipo
  },

 
}
</script>

<style>
.select-template .v-field__outline{
  display: none !important;
}

.select-template .v-field__append-inner i{
  font-size: 13px;
  font-weight: bold;
}
</style>
  