<template>
  <v-container>
    <v-row class="pa-0">
      <v-col cols="12" md="9" xl="8" class="mx-auto" style="position: relative">

        <!--
        <component :is="component"></component>
          -->
        <FormNuevoTicketComponent/>
        <!--------------------->
        <v-overlay :model-value="overlay" class="d-flex align-center justify-center bgBlackTheme" style="opacity: 1;" >
          <Background></Background>
        </v-overlay>

        <!--------------------->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Background from "@/components/background.vue";
import FormNuevoTicketComponent from "../components/RegistrarNuevoTicket/FormNuevoTicketComponent.vue";
import SuccessTicketComponent from "../components/RegistrarNuevoTicket/SuccessTicketComponent.vue";
import { mapState } from "vuex";
export default {
  components: {
    FormNuevoTicketComponent,
    SuccessTicketComponent,
    Background
  },

  data() {
    return {};
  },
  computed: {
    ...mapState("tickets/registroticket", ["component","overlay"]),
  },
};
</script>


<style>
.v-overlay__scrim
{
  opacity: 0.5 !important;
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Ajusta el valor de z-index según sea necesario */
}
</style>