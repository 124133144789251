import Module from './Module.vue';
import ChatHomeView from './views/ChatHomeView.vue';

const moduleRoute = {
    path: "/chat",
    component: Module,
    children: [
      {
        path: "",
        component: ChatHomeView,
        name: "ChatHome",
        meta:{
          title: "Chat"
        }
      }
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };