import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";


export default class notificacionService
{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers:{
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        })

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }

    async getAll()
    {
        const response = await this.axios.get('notificacion')
        return response.data;
    }

    async saveConfigNotification(data)
    {
        const response = await this.axios.post('notificacion', data)
        return response.data;
    }

    async updateConfigNotification(data)
    {
        const response = await this.axios.put('notificacion', data)
        return response.data;
    }
    async updateConfigNotificationPersonal(data)
    {
        const response = await this.axios.post('notificacion-user-ticket', data)
        return response.data;
    }
    
}