import Swal from "sweetalert2"
import PrioridadService from "../services/PrioridadService"
import utiles from "@/modules/Shared/utiles/utiles";

const PrioridadSV = new PrioridadService();

export default {
    namespaced: true,
    modules: {
        utiles
    },
    state: {
        prioridad: {
            nombre: "",
            descripcion: "",
            color: "",
            tiempomaximo: 0,
            periodo: "HORAS"
        },
        prioridadDefault: {
            nombre: "",
            descripcion: "",
            color: "",
            tiempomaximo: 0,
            periodo: "HORAS"
        },

        listPrioridad: [],
        loading: false
    },
    mutations: {
        setPrioridades(state, payload) {
            state.listPrioridad = payload
        },

        editPrioridad(state, prioridad) {
            state.prioridad = Object.assign({}, prioridad)
        },

        resetPrioridad(state) {
            state.prioridad = Object.assign({}, state.prioridadDefault)
        }
    },
    actions: {

        async getPrioridadesAxios({ commit, state }) {
            state.loading = true
            try {
                const data = await PrioridadSV.getAll()
                commit('setPrioridades', data)
                console.log(data)
                state.loading = false
            } catch (error) {
                commit('utiles/alertError')
                state.loading = false
            }
        },


        async createPrioridadAxios({ commit, dispatch, state }) {
            commit('utiles/alertLoading')
            try {
                const data = await PrioridadSV.createPrioridad(state.prioridad)
                commit('utiles/alertConfirm', "Se creó una nueva prioridad")
                dispatch('getPrioridadesAxios')
                commit('resetPrioridad')
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },


        async updatePrioridadAxios({ commit, dispatch, state }) {
            commit('utiles/alertLoading')
            try {
                const data = await PrioridadSV.updatePrioridad(state.prioridad)
                commit('utiles/alertConfirm', "Se actualizó la prioridad")
                dispatch('getPrioridadesAxios')
                commit('resetPrioridad')
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },


        async deletePrioridadAxios({ commit, dispatch, state }, idprioridad) {

            const confirm = await Swal.fire({
                title: "¿Desea eliminar este elemento?",
                icon: "question",
                cancelButtonText: "cancelar",
                confirmButtonText: "CONFIRMAR",
                showCancelButton: true,
            })
            if (!confirm.isConfirmed) { return }
            commit('utiles/alertLoading')
            try {
                await PrioridadSV.deletePrioridad(idprioridad);
                commit('utiles/alertConfirm', "Se eliminó la prioridad")
                dispatch('getPrioridadesAxios')
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        }
    }
}