<template>

<div id="frame">
  <div class="rocket">
    <div class="rocket__body">
      <div class="rocket__body__window">
        <div class="shadow"></div>
      </div>
      <div class="rocket__body__inner">
        <div class="shadow"></div>
      </div>
    </div>
    <div class="rocket__wing rocket__wing--left"></div>
    <div class="rocket__wing rocket__wing--right">
      <div class="shadow shadow--full"></div>
    </div>
    <div class="rocket__label">
      <p class="labels">3</p>
      <p class="labels">2</p>
      <p class="labels">1</p>
    </div>
    <div class="rocket__smoke rocket__smoke--left">
      <div class="rocket__smoke__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="rocket__smoke rocket__smoke--left">
      <div class="rocket__smoke__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="rocket__smoke rocket__smoke--left">
      <div class="rocket__smoke__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="rocket__smoke rocket__smoke--left">
      <div class="rocket__smoke__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="rocket__smoke rocket__smoke--left">
      <div class="rocket__smoke__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="rocket__smoke rocket__smoke--right">
      <div class="rocket__smoke__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="rocket__smoke rocket__smoke--right">
      <div class="rocket__smoke__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="rocket__smoke rocket__smoke--right">
      <div class="rocket__smoke__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="rocket__smoke rocket__smoke--right">
      <div class="rocket__smoke__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="rocket__smoke rocket__smoke--right">
      <div class="rocket__smoke__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="rocket__fire"></div>
  </div>
</div>
</template>

<script>
export default{
  mounted()
  {
  
    
  let tl = gsap.timeline({repeat: -1, repeatDelay: 1}),
     labels = document.getElementsByClassName("labels"),
     rocket = document.querySelectorAll(".rocket__body, .rocket__wing, .rocket__fire"),
     smokeL = document.querySelectorAll(".rocket__smoke--left"),
     smokeR = document.querySelectorAll(".rocket__smoke--right"),
     fire = document.getElementsByClassName("rocket__fire");
  
  // Durations!
  let cdStart = 1.25, cdLeave = cdStart / 2,
      esDuration = 0.10, esRepeat = 15,
      smDuration = 1.5;
  
  // Animations!
  tl.addLabel("countdown")
      .from(labels, {
        duration: cdStart,
        scale: 0,
        x: "50px", y: "50px",
        stagger: cdStart / labels.length,
      }, "countdown")
      .to(labels, {
        duration: cdLeave,
        scale: 0,
        x: "20px", y: "20px",
        opacity: 0,
        stagger: cdStart / labels.length,
      }, "countdown+=" + cdStart / labels.length) 
    .addLabel("engine-start")
      .from(rocket, {
        duration: esDuration,
        x: "+=3px",
        repeat: esRepeat,
      }, "engine-start-=.5")
      .from(rocket, {
        duration: esDuration * 20,
        y: "+=5px",
      }, "engine-start-=1")
      .from(smokeL, {
        duration: smDuration,
        scale: 0,
        opacity: 2,
        stagger: smDuration / smokeL.length,
        x: "+=45px", y: "+=30px",
      }, "engine-start-=.5")
      .from(smokeR, {
        duration: smDuration,
        scale: 0,
        opacity: 2,
        stagger: smDuration / smokeR.length,
        x: "-=45px", y: "+=30px",
      }, "engine-start-=.5") 
      .from(fire, {
        duration: smDuration,
        scale: 0,
      }, "engine-start-=.5")
    .addLabel("lift-off")
      .to(rocket, {
        duration: 2,
        y: "-=100px",
      }, "lift-off-=1")
      .to(fire, {
        duration: .25,
        scale: 2,
      }, "lift-off-=1")  
    .addLabel("launch")
      .to(rocket, {
        duration: 3,
        y: () => "-=" + (document.body.offsetHeight) + "px",
        ease: "power4",
      }, "launch-=1.5")
      .to(fire, {
        duration: .25,
        scale: 1.75,
        repeat: 10,
      }, "launch-=1.8")  
    ;


  }
}

</script>

<style lang="scss">

:root {
  --color: #40407a;
  --font-color: #f1c40f;  
  
  --rocket-main: white;
  --rocket-highlight: #e74c3c;
  --rocket-glass: #9AECDB;
  --rocket-smoke: #f1f2f6;
  --rocket-fire: #f0932b;
  --rocket-fire-highlight: #f1c40f;
}


#frame {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}


.shadow {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  background: rgba(100, 100, 100, .1);
  z-index: 1;
}

.shadow--full {
  width: 100%;
}


$rocket-width: 55px;
$rocket-height: 100px;
$rocket-margin: 1.25;

.rocket {
  position: relative;
  width: $rocket-width;
  height: $rocket-height;
  
  &__body {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10;
    
    &__window {
      $window-width: $rocket-width * .4;
      $window-frame: 3px;
      position: absolute;
      width: $window-width;
      height: $window-width;
      top: $window-width + ($window-width / 2) - ($window-frame);
      left: ($rocket-width / 2) - ($window-width / 2) - ($window-frame);
      border-radius: 50%;
      background: var(--rocket-glass);
      z-index: 1;
      border: $window-frame solid var(--rocket-highlight);
    }
    
    &__inner {
      position: relative;
      width: $rocket-width;
      height: $rocket-height * $rocket-margin;
      border-radius: 80%;
      background: var(--rocket-main);
      overflow: hidden;

      &:before,
      &:after {
        content: "";
        position: absolute;
        background: var(--rocket-highlight);
        border-radius: 50%;
      }

      &:before {
        $height: $rocket-height * .5; 
        width: 100%;
        height: $height;
        top: -$height * .6;
      }

      &:after {
        $height: $rocket-height * .1; 
        width: 100%;
        height: $height;
        bottom: $height + ($height * $rocket-margin);
      }    
    }
  }
  
  $wing-width: $rocket-width * .8;
  $wing-height: $rocket-height * .2;
  $wing-margin: -$wing-width + 15px;
  $wing-angle: 50deg;
  
  &__wing {  
    position: absolute;
    background: var(--rocket-highlight);
    bottom: -$wing-height + $wing-height;    
    z-index: 5;
  }
  
  &__wing--left {
    left: $wing-margin;
    width: $wing-width;
    height: $wing-height;
    border-radius: 100% 0 0 0;
    transform: rotate(-$wing-angle);
  }
  
  &__wing--right {
    right: $wing-margin;
    width: $wing-width;
    height: $wing-height;
    border-radius: 0 100% 0 0;
    transform: rotate($wing-angle);
  }
  
  &__label {
    width: 250px;
    position: absolute;
    top: -80px;
    left: -80px;
    
    p {
      $font-size: 80px;
      position: absolute;
      top: 0;
      left: 0;
      font-family: 'Bowlby One SC', cursive;
      font-size: $font-size;
      line-height: $font-size;
      margin: 0;
      text-transform: uppercase;
      color: var(--font-color);
      transform: rotate(-25deg);
    }
  }
  
  $smoke-width: 50px;
  $smoke-height: 10px;
  $smoke-scale: .5;
  
  &__smoke {
    position: absolute;
    width: $smoke-width;
    height: $smoke-height;
    bottom: $smoke-height - 5px;
    opacity: 0;
    z-index: 15;

    &__inner {
      position: relative;
      margin: 30px 0 0 0;
      width: 100%;
      height: 100%;
      background: var(--rocket-smoke);      
    
      div {
        position: absolute;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        left: -5px;
        bottom: 0;
        box-shadow: inset -2px -3px 0 0 var(--rocket-smoke);    
        background: #fff;    
        z-index: 10;      
      }

      div:nth-child(1) {
        transform: scale(1.5);
        left: 10%;
        bottom: 30%;
        z-index: 9;
      }

      div:nth-child(2) {
        transform: scale(2.5);
        left: 50%;
        bottom: 90%;
        z-index: 8;
      }

      div:nth-child(3) {
        transform: scale(1.1);
        left: 84%;
        bottom: 4.5%;
        z-index: 7;
      }  
    }      
  }  
  
  &__smoke--right {
    right: -$smoke-width;
  }
  
  &__smoke--left {
    left: -$smoke-width;
    transform: rotateY(180deg);
  }  
  
  $fire-size: $rocket-width / 4;
  $fire-radius: 80% 0 55% 50% / 55% 0 80% 50%;
  
  &__fire {
    position: absolute;
    width: $fire-size;
    height: $fire-size;
    bottom: -$fire-size - 2px;
    left: $fire-size + 7px;
    background: var(--rocket-fire);
    border-radius: $fire-radius;
    margin-top: 20px;
    transform: rotate(135deg);
    
    &:after {
      $size: $fire-size - ($fire-size * .25);
      content: "";
      position: absolute;
      top: 3px;
      left: 1px;
      width: $size;
      height: $size;
      background: var(--rocket-fire-highlight);
      border-radius: $fire-radius;
    }
  }
}

</style>