<template>
<v-container fluid>
    <v-row class="pa-0">
        <v-col cols="12" lg="3" class="bg-white" :style="{height: calculeHeightVH()}">
            <v-list>
                <v-list-item prepend-avatar="https://cdn.vuetifyjs.com/images/john.png"
                    :title="capitalizeWords(oneName(user.nombres) + ' ' + oneName(user.apellidos))" :subtitle="capitalizeWords(user.cargo)">
                </v-list-item>
            </v-list>
        </v-col>

        <v-col cols="12" lg="9">
            <v-card elevation="0" class="d-flex flex-column" height="100%">
                <v-card-title>
                    <v-list>
                        <v-list-item prepend-avatar="https://cdn.vuetifyjs.com/images/john.png"
                            :title="capitalizeWords(oneName(user.nombres) + ' ' + oneName(user.apellidos))"
                            subtitle="en linea">
                        </v-list-item>
                    </v-list>
                </v-card-title>
                <v-card-text class="fill-height bgLight2Theme"></v-card-text>
                <v-card-actions class="px-2">
                    <v-text-field class="solo-no-elevation" density="compact" placeholder="Escribe un mensaje" variant="solo" hide-details="auto">
                        <template v-slot:append-inner>
                            <div>
                                <v-icon>fa-regular fa-paper-plane</v-icon>
                            </div>
                        </template>
                    </v-text-field>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapState } from 'vuex';

export default
    {
        mixins: [Filters],
        computed: {
            ...mapState(['user'])
        }
    }
</script>


<style>
.fill-height {
  flex: 1; /* Hace que ocupe todo el alto disponible */
}
</style>