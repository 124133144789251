import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";


export default class SolicitanteService{
    constructor() {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers: {
                'Content-Type': "multipart/form-data",
                Accept: "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        })

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }


    async get(correo){
        const response = await this.axios.post('solicitante', {correo: correo});
        return response.data;
    }
}