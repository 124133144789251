<template>
    <v-card elevation="0" class="my-0 px-2 px-md-5 rounded-0 bg-grey-lighten-4">
        <v-card-title class="">
            <div class="text-subtitle-2">Respuestas</div>
        </v-card-title>
        <div>
            <div class="pa-5 text-center" v-if="loadingAnswers">
                <v-col cols="12" class="mx-auto py-2"><v-progress-linear color="blue-lighten-3" indeterminate
                        :height="7"></v-progress-linear></v-col>
            </div>


            <div class="pa-5 text-center" v-if="answers.length == 0 && !loadingAnswers">
                <span> No hay respuestas </span>
            </div>

            <div v-for="answ in answers">
                <div class="d-flex justify-end" v-if="!isRoleVisitor() && isMyID(answ.user_id)">
                    <v-avatar
                        @click="deleteAnswer({ loading: true, idticket: answ.ticket_id, idanswer: answ.idanswer })"
                        size="x-small" elevation="0" class="bgLight2Theme rounded-sm" style="cursor: pointer;"><v-icon
                            size="14">mdi:mdi-delete</v-icon></v-avatar>
                </div>
                <v-card class="mb-3 modern-elevated" >

                    <v-card-actions style="min-height: 20px !important;"
                        class="text-blue-grey d-flex flex-column flex-md-row justify-space-between pt-3">
                        <div>
                            <v-avatar v-if="answ.user.avatar" size="30" class="bg-blue-grey mr-2"> 
                                <img :src="answ.user.avatar" alt="Avatar" width="40">
                            </v-avatar>
                            <v-avatar v-else size="30" class="bg-blue-grey mr-2">
                                <v-img v-if="!answ.user.avatar"
                                    :src="require('@/assets/' + answ.user.tipouser + '.png')"></v-img>
                                <v-icon v-else size="20">mdi:mdi-account-tie</v-icon>
                            </v-avatar>

                            <span class="text-caption mr-2">
                                {{ capitalizeWords(oneName(answ.user.nombres) + ' ' + oneName(answ.user.apellidos)) }}
                            </span>
                            <span class="text-caption font-weight-medium">{{ answ.user.email }}</span>

                        </div>

                        <span class="text-caption font-weight-medium">{{ fechasmall("DD MMM YYYY", answ.created_at) }} a
                            las
                            {{ fechasmall("hh:mm a",
                                answ.created_at) }}</span>

                    </v-card-actions>

                    <v-card-text class="pa-4">
                        <div v-html="answ.message" class="body-comment-ticket caja-texto-styles "
                            style="overflow: auto; max-height: 1800px;"></div>
                        <div class="py-3">
                            <v-chip label v-for="f in answ.file" size="large" @click="onOpenDialogAdjunto(f)"
                                class="font-weight-medium text-caption mr-2 pl-1 bg-blue-grey-darken-2">
                                <div start class="bg-white rounded-sm pa-1 d-flex align-center justify-center">
                                    <v-img
                                        :src="require('@/assets/' + iconExtension(getFileNameFromURL(f)['extension']))"
                                        class="mx-auto" :width="25" cover />
                                </div>
                                <div class="d-inline-block text-truncate px-2" style="max-width: 150px;">{{
                                    getFileNameFromURL(f) ? getFileNameFromURL(f)['name'] : '' }}</div>
                            </v-chip>
                        </div>
                    </v-card-text>

                    <v-card-actions class="d-flex flex-wrap justify-end">
                        <div class="mr-2 font-weight-bold" style="font-size: 12px;">
                            Respuesta con copia a:
                        </div>
                        <v-card v-for="cc in answ.answerCopies" variant="outlined" class="modern-elevated rounded-lg mr-2 pa-1" width="160" style="border: 1px solid #e0e0e0;">
                            <v-card-text class="d-flex align-center pa-0">
                                <v-avatar class="bg-green-accent-3 text-white mr-2" size="25" >
                                    <v-icon size="14">mdi:mdi-email</v-icon>
                                </v-avatar>
                                <v-card-text class="pa-0">
                                    <div class=" mr-2 font-weight-bold text-truncate d-inline-block" style="max-width: 150px; font-size: 10px;">
                                        {{ capitalizeWords(oneName(cc.nombres) + ' ' + oneName(cc.apellidos)) }}
                                    </div>
                                    <!--<div class="text-caption text-truncate d-inline-block" style="font-size: 10px; max-width: 150px;">
                                        {{ cc.email }}
                                    </div>-->
                                </v-card-text>
                            </v-card-text>
                        </v-card>
                    </v-card-actions>
                </v-card>
            </div>
            <VisualizarAdjuntoComponent></VisualizarAdjuntoComponent>
        </div>

    </v-card>
</template>


<script>
import Filters from '@/modules/Shared/Filters'
import VisualizarAdjuntoComponent from '@/modules/Tickets/components/VisualizarTicket/VisualizarAdjuntoComponent.vue';
import { socket } from '@/ws-client';
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    mixins: [Filters],
    components: { VisualizarAdjuntoComponent },
    props:
    {
        idticket: {
            type: Number
        }
    },
    data() {
        return {

        }
    },

    computed: {
        ...mapState('answers', ['answers', 'loadingAnswers']),
        ...mapState('tickets', ['ticket'])
    },

    methods: {
        ...mapActions('answers', ['getAllAnswers', 'deleteAnswer']),
        ...mapMutations('answers', ['resetListAnswers']),
        ...mapMutations('tickets', ['switchDialogFileAdjunto', 'setUrlFile', 'resetUrlFile']),
        onOpenDialogAdjunto(file) {
            this.resetUrlFile()
            this.switchDialogFileAdjunto()
            this.setUrlFile(file)
        }
    },
    created() {
        this.resetListAnswers()
    },
    mounted() {
        console.log('ID TICKET', this.idticket)
        this.resetUrlFile()
        this.resetListAnswers() 
        this.getAllAnswers({ loading: true, idticket: this.ticket.idticket });

        socket.on('LOAD_ANSWER', data => {
            this.resetListAnswers()
            this.getAllAnswers({ loading: data, idticket: this.idticket });
        })
    },
}
</script>

<style>
.body-comment-ticket img {
    max-width: 100% !important;
}
</style>