import { createRouter, createWebHistory } from 'vue-router'
import App from '../App.vue'
import store from '@/store'
import BuscarTicketView from '@/modules/Tickets/views/BuscarTicketView.vue'
import RegisterUserView from '@/modules/Login/views/RegisterUserView.vue'
import ProfileUserView from "@/modules/Usuarios/views/ProfileUserView.vue"
import TicketView from '@/modules/Tickets/views/TicketView.vue';

const routes = [
  {
    path: '/',
    name: "homePrincipal",
    //redirect: '/tickets',
    redirect: () =>{
      if(store.getters.getterLogginIn){
        let rol = store.state.user.role_user.name
      
        if(rol === 'SUPERADMINISTRADOR'){
          return '/ticket-overview'
        }
        else{
          return '/tickets'
        }
      }else
      {
        return '/login'
      }
    }
 

  },
  {
    path: "/view/ticket/:codigoticket",
    component: TicketView,
    name: "visualizeTicket",
    meta: {
      requiresAuth: true,
      title: "Ticket",
    }, 
  },
  {
    path: "/search/ticket",
    component: BuscarTicketView,
    name: "searchTicket",
    meta: {
      requiresAuth: true,
      title: "Ticket",
    },
  },
  {
    path: "/register/user/:email/:dni/:microsoft?",
    component: RegisterUserView,
    name: "registerUser",
    meta: {

      title: "Registrar Usuario",
    },
  },

  {
    path: "/mi-perfil", 
    component: ProfileUserView,
    name: "profileUser",
    meta: {
      requiresAuth: true,
      title: "Mi Perfil",
    },
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
  var IsLoggeIn = store.getters.getterLogginIn;
  document.title = "HELPDESK | " + to.meta.title;
  if (requiresAuth && !IsLoggeIn) {
    next("/login");
  } else if (requiresGuest && IsLoggeIn) {
    next("/");
  } else {
    next();
  }
});

export default router
