<template>
    <div>
        <v-menu open-on-hover :close-on-content-click="false" location="top">
            <template v-slot:activator="{ props }">
                <v-chip v-bind="props" style="cursor: pointer; height: auto !important;" size="small" label color="primary"
                    class="font-weight-medium py-1 mr-2">
                    <div style="text-wrap: wrap; ">{{ ticket.categoria.nombre }}</div>
                </v-chip>
            </template>
            <v-card width="300">
                <v-list>
                    <v-list-item>
                        <div style="text-wrap: wrap;">{{ ticket.categoria.nombre }}</div>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-text class="bgLight2Theme text-wrap">
                    <div class="text-subtitle-2">
                        {{ ticket.categoria.descripcion }}
                    </div>
                </v-card-text>
            </v-card>
        </v-menu>


        <v-menu v-if="ticket.subcategoria" open-on-hover :close-on-content-click="false" location="top">
            <template v-slot:activator="{ props }">
                <v-chip v-bind="props" style="cursor: pointer; height: auto !important;" size="small" label color="primary"
                    class="font-weight-medium py-1 mr-2">
                    <div style="text-wrap: wrap; "> {{ ticket.subcategoria.nombre }}</div>
                </v-chip>
            </template>
            <v-card width="300">
                <v-list>
                    <v-list-item>
                        <div style="text-wrap: wrap;">{{ ticket.subcategoria.nombre }}</div>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-text class="bgLight2Theme text-wrap">
                    <div class="text-subtitle-2">
                        {{ ticket.subcategoria.descripcion }}
                    </div>
                </v-card-text>
            </v-card>
        </v-menu>

        <v-chip @click="onOpenDialogEditCategories()" style="cursor: pointer; height: auto !important;" size="small" label
            color="amber-accent-4" class="font-weight-medium py-1 mr-2">
            <v-icon>fa:fa-solid fa-pen-to-square</v-icon>
        </v-chip>

    </div>




    <v-dialog width="600" v-model="onDialog" :key="ticket.idticket">
        <v-card class="pa-2">
            <v-card-text>
                <v-form v-model="formvalidate" :disabled="loadingChangeCategoria">
                    <v-row>
                        <v-col cols="12">
                            <div class="font-weight-bold text-h6">Cambiar Categoria y Subcategoria de ticket</div>
                        </v-col>
                        <v-col cols="12">
                            <div>
                                <label for="" class="font-weight-medium text-subtitle-2">Categoria de solicitud</label>
                                <small class="d-block">Seleccione una categoria</small>
                                <v-select variant="outlined" hide-details="auto" density="compact" bg-color="grey-lighten-5"
                                    class="mt-2" :loading="loadingcategorias" hide-no-data :rules="[rules.required]"
                                    v-model="cambioCategories.categoria_ticket"
                                    :items="cambioCategories.tipo_ticket != '' && !loadingcategorias ? listCategorias : []"
                                    item-value="idcategoria" item-title="nombre">
                                    <template v-slot:selection="{ item }">
                                        <span v-if="item.title == ''">{{ loadingcategorias ? 'Cargando...' : 'Selecciona'
                                        }}</span>
                                        <div class="text-truncate">{{ item.title }}</div>
                                    </template>
                                </v-select>
                            </div>
                        </v-col>

                        <v-col cols="12">
                            <div>
                                <label for="" class="font-weight-medium text-subtitle-2">SubCategoria de solicitud</label>
                                <small class="d-block">Seleccione una subcategoria</small>
                                <v-select variant="outlined" hide-details="auto" density="compact" bg-color="grey-lighten-5"
                                    class="mt-2" :loading="loadingsubcategorias" hide-no-data :rules="[rules.required]"
                                    v-model="cambioCategories.subcategoria_id"
                                    :items="cambioCategories.categoria_ticket != '' && !loadingsubcategorias ? listsubcategorias : []"
                                    item-value="id_subcategoria" item-title="nombre">
                                    <template v-slot:selection="{ item }">
                                        <span v-if="item.title == ''">{{ loadingsubcategorias ? 'Cargando...' : 'Selecciona'
                                        }}</span>
                                        <div class="text-truncate">{{ item.title }}</div>
                                    </template>
                                </v-select>
                            </div>
                        </v-col>

                        <v-col cols="12">
                            <v-btn @click="changeCategories()" :loading="loadingChangeCategoria" :disabled="!formvalidate"
                                block class="bgLightTheme text-white" elevation="0">CAMBIAR</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import Filters from '@/modules/Shared/Filters';
import { mapState, mapMutations, mapActions } from 'vuex';


export default {
    mixins: [Filters],
    data() {
        return {
            formvalidate: true,
            isFirstChange: true
        }
    },

    watch: {

        'cambioCategories.categoria_ticket': function (newvalue, oldvalue) {
            if (!this.isFirstChange) {
                this.cambioCategories.subcategoria_id = "";
            } else {
                this.isFirstChange = false
            }
            this.getSubCategoriasAxios(newvalue);
        },


    },
    computed: {
        ...mapState('configuracion/categoria', ['listCategorias', 'loadingcategorias']),
        ...mapState('configuracion/subcategoria', ['listsubcategorias', 'loadingsubcategorias']),
        ...mapState('tickets', ['dialogCambiarCategoria', 'cambioCategories', 'loadingChangeCategoria']),
        ...mapState('tickets', ['ticket']),
        onDialog: {
            get() {
                return this.dialogCambiarCategoria
            },

            set() {
                this.switchDialogCategories()
            }
        }
    },

    methods: {
        ...mapMutations('tickets/registroticket', ['switchDialogCategories']),
        ...mapActions('configuracion/categoria', ['getCategoriasAxios']),
        ...mapActions('configuracion/subcategoria', ['getSubCategoriasAxios']),
        ...mapMutations('tickets', ['switchDialogCategories', 'setEditCambiarCategoriaTicket']),
        ...mapActions('tickets', ['changeCategories']),
        onOpenDialogEditCategories() {
            //this.editChangeCategories(this.ticket)
            this.getCategoriasAxios(this.ticket.tipo_ticket)
            this.getSubCategoriasAxios(this.ticket.categoria_ticket)
            this.setEditCambiarCategoriaTicket(this.ticket)
            this.switchDialogCategories()
        }
    },

    mounted() {
       
    }
}
</script>