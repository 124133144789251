<template>
    <v-container fluid class="pa-0 pa-md-2" >
        <v-row dense class="pa-0">
            <v-col cols="12" class="bgLight2Theme" >
                <SilderLeftMenu />
            </v-col>

            <v-col cols="12">
                <router-view name="dashboardview"></router-view>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import Filters from '@/modules/Shared/Filters';
import SilderLeftMenu from '../components/SilderLeftMenu.vue';

export default {
    mixins: [Filters],
    components:{
        SilderLeftMenu
    },


    created()
    {
        if(!this.$abillity.can('read', 'panel-metricas'))
        {
            this.$router.push({name: 'tickets'})
        }
    }
}
</script>