import AuthService from "@/modules/Usuarios/services/AuthService";

import store from "@/store";
import router from "@/router";
import ConfirmRegistrationService from "../service/confirmRegistrationService";
import CryptoJs from "crypto-js";
import utiles from "@/modules/Shared/utiles/utiles";
import RegisterUserService from "@/modules/Usuarios/services/RegisterUserService";
import Swal from "sweetalert2";
const Auth = new AuthService();
const ConfirmRegistrationSV = new ConfirmRegistrationService;
const RegisterUserSV = new RegisterUserService;

export default {
  namespaced: true,
  modules: { utiles },
  state: {
    currentComponent: "FormLoginComponent",
    errorLogin: {
      type: "",
      message: "",
    },

    errorLoginDefault: {
      type: "",
      message: "",
    },

    datauserregister: {
      numero_documento: "",
      apellidos: "",
      nombres: "",
      cargo: "",
      area: "",
      carrera: "",
      admision: "",
      celular: "",
      email: "",
      tipouser: "",
      password: ""
    },

    updatePassword: {
      newpass: "",
      confirmpass: "",
      email: ""
    },

    datauserPass: {
      numero_documento: "",
      apellidos: "",
      nombres: "",
      cargo: "",
      area: "",
      carrera: "",
      admision: "",
      celular: "",
      email: "",
      tipouser: "",
      password: ""
    },


    restorepass:{
      email: ""
    },
    loadingUpdatePass: false,
    loadingsendmail:false,
    loadingvalidatetoken: false
  },


  mutations: {
    switchComponent(state, nameComponent) {
      state.currentComponent = nameComponent
    },

    setUser(state, payload) {
      store.state.user = Object.assign({}, payload);
    },

    logout(state) {
      store.state.user = Object.assign({}, store.state.userDefault)
    },

    setdatauserregister(state, payload) {
      console.log(payload)
      state.datauserregister = Object.assign({}, payload)
    },

    resetUser(state) {
      store.state.user = Object.assign({}, store.state.userDefault)
      location.reload();
    },

    renderError(state, errorData) {
      let type, message, status;

      status = typeof errorData === 'object' ? errorData.response.status : 0;

      if (status === 501) {
        type = "warning";
      }
      else if (status === 502) {
        type = "error";
      }
      else {
        type = "info"
      }


      state.errorLogin.type = type
      state.errorLogin.message = typeof errorData === 'object' ? errorData.response.data.error : errorData
    },

    resetError(state) {
      state.errorLogin = Object.assign({}, state.errorLoginDefault)
    },

    badErrorAuth(state, error) {
      console.log(error);
      if (error.response.status == 401) {
        state.overlay = true;
        store.state.token = null;
        store.state.user = Object.assign({}, store.state.userDefault)
        location.reload();
      }
    },

    setDataUserPass(state, payload)
    {
      state.datauserPass = Object.assign({}, payload)
    },



  },

  actions: {
    async loginMicrosoft({ commit, dispatch, state }, token) {
      store.state.overlayLogin = true
      try {
        const data = await Auth.loginMicrosoft(token)
        console.log(data)
        commit('setUser', data)
        router.push({ name: "homePrincipal" })
        store.state.overlayLogin = false
      }
      catch (error) {
        console.log(error)
        commit('renderError', error)
        store.state.overlayLogin = false
      }
    },
    

    async login({ commit, dispatch, state }, datalogin = null) {
      store.state.overlayLogin = true

      try {
        const data = await Auth.login(datalogin || store.state.user);
        commit('setUser', data)
        store.state.overlayLogin = false
        router.push({ name: "homePrincipal" })

        commit('resetError')
      }
      catch (error) {
        console.log(error)
        commit('renderError', error)
        store.state.overlayLogin = false

        if (error.response.status === 404) {
          dispatch('validateRegister', { email: store.state.user.email, password: store.state.user.password })
        }

        //store.state.user = Object.assign({}, store.state.userDefault)
      }
    },

    async reloadDataUser({ commit, state }, data) {
      try {
        commit('setUser', data)
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
      }
    },

    async logout({ commit, state }) {
      store.state.overlayLogin = true
      try {
        const data = await Auth.logout(store.state.user);
        commit('resetUser')
        store.state.overlayLogin = false
      }
      catch (error) {
        console.log(error)
        commit('renderError', error)
        store.state.overlayLogin = false
        commit('badErrorAuth', error)
      }
    },


    /**ENVIAR EMAIL PARA RESTAURAR PASSWORD */

    async getsendemailrestorepass({state, commit})
    {
      state.loadingsendmail = true
      try{
        const data = await Auth.getsendemailrestorepass(state.restorepass.email)
        state.loadingsendmail = false
        Swal.fire({
          title: "Email enviado!",
          text: "Revisa tu bandeja de entrada, hemos enviado un correo para restaurar tu contraseña",
          icon: "success"
        })
      }
      catch(error)
      {
        console.log(error)
        Swal.fire({
          title: "Usuario no encontrado!",
          text: "El email ingresado no está registrado",
          icon: "warning"
        })
        state.loadingsendmail = false
      }
    },

  /**OBTENER DATOS DE USUARIO CON EMAIL Y DNI */

  async getUserEmail({state, commit}, emailencrypted)
  {
    state.loadingvalidatetoken = true
    try
    {
      const data = await Auth.validatetoken(emailencrypted)
      commit('setDataUserPass', data)
      console.log(data)
      state.loadingvalidatetoken = false
    }
    catch(error)
    {
      console.log(error)
      state.loadingvalidatetoken = false
      router.push({name: 'Login'})
    }
  },  

  async updatePassConfirm({ commit, dispatch, state }) {
    state.loadingUpdatePass = true
    try {
      state.updatePassword.email = state.datauserPass.email
      await RegisterUserSV.updatePassword(state.updatePassword)
      state.loadingUpdatePass = false

      dispatch('login', { email: state.datauserPass.email, password: state.updatePassword.confirmpass })
    }
    catch (error) {
      console.log(error)
      commit('utiles/alertError')
      state.loadingUpdatePass = false
    }

  },



  /********************************************************************* */

    async updatePassInvitado({ commit, dispatch, state }) {
      state.loadingUpdatePass = true
      try {
        state.updatePassword.email = state.datauserregister.email
        await RegisterUserSV.updatePassword(state.updatePassword)
        state.loadingUpdatePass = false

        dispatch('login', { email: state.datauserregister.email, password: state.updatePassword.confirmpass })
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
        state.loadingUpdatePass = false
      }

    },


    async validateRegister({ commit }, params) {
      try {
        const { email, password } = params;
        const data = await ConfirmRegistrationSV.findDataUser(email, password)
        commit('setdatauserregister', data)
        const emailCrypt = CryptoJs.AES.encrypt(data.email.replace(/\s/g, ''), '123')
        const dniCrypt = CryptoJs.AES.encrypt(data.numero_documento.toString().replace(/\s/g, ''), '123')
        router.push({ name: 'registerUser', params: { email: emailCrypt, dni: dniCrypt } })
      }
      catch (error) {
        console.log(error)
        if (error.message === 'not-password-register-collaborator') {
          commit('renderError', 'Contraseña inicial incorrecta!')
        }
      }
    },

   

    async getDataRegister({ commit }, params) {
      try {
        const { email, password, microsoft } = params;
        let data;
        if(!microsoft){
          const emailDecrypt = CryptoJs.AES.decrypt(email, '123').toString(CryptoJs.enc.Utf8)
          const dniDecrypt = CryptoJs.AES.decrypt(password, '123').toString(CryptoJs.enc.Utf8)
          data = await ConfirmRegistrationSV.findDataUser(emailDecrypt, dniDecrypt)
        }
        else{
          data = await ConfirmRegistrationSV.findDataUser(email, password, true)
        }
        
        
        commit('setdatauserregister', data)
      }
      catch (error) {
        console.log(error)
        if (error.message === 'not-password-register-collaborator') {
          commit('renderError', 'Contraseña inicial incorrecta!')
        }
      }
    },

    async getSendEmailRestorepass(state)
    {
      sendResetPassword
    }
  }
};