<template>
  <v-card elevation="0" class="mt-3">
    <v-card-text class="text-center">
      <v-row>
        <v-col cols="12"  class="ml-auto">
          <v-img
            :src="require('@/assets/graciasCTM.webp')"
            width="200"
            class="mx-auto my-4"
          />
        </v-col>
        <v-col cols="12"  class="d-flex justify-center align-center mr-auto">
          <div>
            <div
              class="font-weight-medium text-subtitle-1 text-md-h4 text-center py-5"
            >
              🧑‍💻 Ticket Enviado con exito! 📨
            </div>
            <span class="pa-5">Un miembro de nuestro equipo se comunicará contigo en el menor tiempo posible.
            </span>
            <div class="d-flex justify-center pa-4">
              <v-btn
                :to="{ name: 'tickets' }"
                class="mr-2"
                variant="text"
                prepend-icon="fa:fa-solid fa-list-check"
                >Mis tickets</v-btn
              >
              <v-btn
                @click="switchAndClean('FormNuevoTicketComponent')"
                variant="tonal"
                color="green-accent-4"
                prepend-icon="fa:fa-solid fa-ticket"
                >crear nuevo</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>


</template>

<script>
import { mapMutations, mapState } from "vuex";
import Filters from "../../filters";
export default {
  mixins: [Filters],
  computed:{

    ...mapState('tickets/registroticket',['ticket'])

  },

  methods: {
    ...mapMutations("tickets/registroticket", ["switchAndClean"]),
  },
};
</script>
