<template>
  <div class="bg-pattern d-flex justify-center align-center " style="height:  101.5vh">

    <v-container fluid>
      <v-row dense>
        <v-col cols="12" md="7" class="mx-auto pa-2 mx-lg-none pa-md-0">
          <v-card elevation="0" class="pa-2 px-md-12 py-md-0 rounded-xl" width="100%"
            style="box-shadow: 0 2px 30px 15px #2553b91a !important;">
            <v-card-title class="pt-4 pt-md-8 pb-md-12 text-center text-md-left">
              <v-img :src="require('@/assets/logo.svg')" width="130px" cover></v-img>
            </v-card-title>
            <v-card-text>
              <component v-bind:is="currentComponent"></component>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import FormLoginComponent from "../components/FormLoginComponent.vue";
import FormResetPassComponent from "../components/FormResetPassComponent.vue";
import AlertUserNotFound from "../components/AlertUserNotFound.vue";
export default {
  components: {
    FormLoginComponent,
    FormResetPassComponent,
    AlertUserNotFound
  },

  computed: {
    ...mapState("login", ["currentComponent"]),

  },
  methods: {
    ...mapMutations('login', ['switchComponent']),
  },

  mounted() {
    if (this.$route.query.error) {
      this.switchComponent('AlertUserNotFound')
    }
  }
};
</script>
