<template>
    <v-card elevation="0" class="bg-white" variant="outlined" style="border: 1px solid #cfd7df !important;">
        <v-card-title class="text-subtitle-2 bgLight2Theme">Tiempo de atención</v-card-title>
        <v-card-text>
            <div class="pa-2">
                <span class="text-caption">Fecha y Hora de Inicio de la atención</span>
                <div class="pa-2 bg-green-accent-4 text-white rounded text-center ">
                    <div class="text-h6">{{ fechasmall('hh:mm:ss a', ticket.inicio_atencion) }}</div>
                    <div class="text-caption" style="line-height: 1rem;">{{ fechasmall('DD [de] MMMM [de] YYYY', ticket.inicio_atencion) }}</div>
                </div>
            </div>

            <div class="pa-2" v-if="ticket.fin_atencion">
                <span class="text-caption">Fecha y Hora de fin de la atención</span>
                <div class="pa-2 bg-blue-grey text-white rounded text-center ">
                    <div class="text-h6">{{ fechasmall('hh:mm:ss a', ticket.fin_atencion) }}</div>
                    <div class="text-caption" style="line-height: 1rem;">{{ fechasmall('DD [de] MMMM [de] YYYY', ticket.fin_atencion) }}</div> 
                </div>
            </div>

            <div class="pa-2">
                <div class="d-flex rounded" style="overflow: hidden;">
                    <div v-if="ticket.fin_atencion == null" style="width: 50%;" class="d-flex align-center justify-center pa-2 text-white"
                     :class="[contadorestante.fueraTiempo? 'bg-red-darken-3' : 'bg-light-blue-accent-4' ]">
                        <div class="text-center" >
                            <div class="text-center" style="font-size: 20px;">
                                {{ contadorestante.formatTime  }}
                            </div>
                            <div class="text-caption text-center">{{ contadorestante.fueraTiempo ? 'Fuera de T.' : 'T. transcurrido' }}</div>
                        </div>

                    </div>
                    <div v-else style="width: 50%;" class="d-flex align-center justify-center pa-2 text-white"
                     :class="[contadorestante.fueraTiempo? 'bg-red-darken-3' : 'bg-light-blue-accent-4' ]">
                    <div class="text-center" >
                            <div class="text-center" style="font-size: 20px;">
                                {{ contadorcierre.formatTime  }}
                            </div>
                            <div class="text-caption text-center">{{ contadorcierre.fueraTiempo ? 'Fuera de T.' : 'T. transcurrido' }}</div>
                        </div>
                    </div>
                    <div style="width: 50%;" class="d-flex align-center justify-center pa-2  bg-light-blue-accent-3 text-white ">
                        <div class="text-center">
                            <div class="text-center" style="font-size: 20px;">
                                {{ timeLimit }}
                            </div>
                            <div class="text-caption text-center">Hora limite</div>
                        </div>
                    </div>
                </div>
            </div>

        </v-card-text>

    </v-card>
</template>


<script>
import Filters from '@/modules/Shared/Filters';
export default {
    mixins: [Filters],
    props: {
        ticket: {
            type: Object
        }
    },

    data() {
        return {
            contadorestante: {
                formatTime: "",
                fueraTiempo:false,
                lastTiempo: false
            },

            contadorcierre: {
                formatTime: "",
                fueraTiempo:false,
            }
        }
    },

    computed: {

        timeLimit() {
            return moment(this.ticket.inicio_atencion).add(3, 'hours').format('hh:mm:ss')
        }

    },

    methods: {

        /*counter() {
            let timecurrent = moment();
            let timeend = moment(this.ticket.inicio_atencion).add(3, 'hours');
            let seconds_missing = timeend.diff(timecurrent, 'seconds');

            const duracion = moment.duration(seconds_missing, 'seconds');
            const horas = this.padLeft(Math.floor(duracion.asHours()), 2);
            const minutos = this.padLeft(duracion.minutes(), 2);
            const seconds = this.padLeft(duracion.seconds(), 2);

            this.contadorestante = `${horas}:${minutos}:${seconds}`;

            if (Math.floor(duracion.asHours()) < 0 || duracion.minutes() < 0 || duracion.seconds() < 0) {
                this.fueraTiempo = true
            }
        },*/

        calculateCierre()
        {
            if(!this.ticket.fin_atencion) {return }
            let timestart = moment(this.ticket.inicio_atencion);
            let timeclose = moment(this.ticket.fin_atencion);

            let timecurrent = moment();

            //Diferencias
            let seconds = timecurrent.diff(timestart, 'seconds');
            let minutes = timecurrent.diff(timestart, 'minutes');
            let hours = timecurrent.diff(timestart, 'hours');

            let time = this.calculeTime(seconds)
            this.contadorcierre.formatTime = `${time.horas}:${time.minutos}:${time.seconds}`;

            // calcula si fue fuera de tiempo
            let timeendestimado = moment(this.ticket.inicio_atencion).add(3, 'hours');

            if(timeclose.isAfter(timeendestimado))
            {
                this.contadorcierre.fueraTiempo = true
            }

            

        },

        calculeTime(secondsinput)
        {
            const duracion = moment.duration(secondsinput, 'seconds');
            const horas = this.padLeft(Math.floor(duracion.asHours()), 2);
            const minutos = this.padLeft(duracion.minutes(), 2);
            const seconds = this.padLeft(duracion.seconds(), 2);

            return { horas, minutos, seconds}
        },

        counter()
        {
            let timecurrent = moment();
            let timestart = moment(this.ticket.inicio_atencion);
            let timeend = moment(this.ticket.inicio_atencion).add(3, 'hours');

        
            //Diferencias
            let seconds = timecurrent.diff(timestart, 'seconds');
            let minutes = timecurrent.diff(timestart, 'minutes');
            let hours = timecurrent.diff(timestart, 'hours');
            
            //referencial
            let time_referencial = timeend.diff(timestart, 'minutes');


            //response
            let time = this.calculeTime(seconds)
            this.contadorestante.formatTime = `${time.horas}:${time.minutos}:${time.seconds}`;
            
            if(time_referencial > 50 && !timecurrent.isAfter(timeend))
            {
                this.contadorestante.lastTiempo = true
                this.contadorestante.fueraTiempo = false
            }

            if (timecurrent.isAfter(timeend)) {
                    this.contadorestante.fueraTiempo = true;
                }


        },

        padLeft(value, length) {
            return String(value).padStart(length, '0');
        }

    },

    mounted() {

        const intervalId = setInterval(() => {

            this.counter()


        }, 1000)

        this.calculateCierre()
    }
}
</script>