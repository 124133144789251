import utiles from "@/modules/Shared/utiles/utiles";
import axios from "axios";
import Swal from "sweetalert2";
import SubCategoriaService from "../services/SubCategoriaService";

const SubCategoriaSv = new SubCategoriaService();

export default {
    namespaced: true,
    modules: {
        utiles
    },
    state: {
        subcategoria: {
            nombre: "",
            descripcion: "",
            categoria_id: "",
            tipo_usuario: []
        },

        subcategoriaDefault: {
            nombre: "",
            descripcion: "",
            categoria_id: "",
            tipo_usuario: []
        },

        filterSubcategoria:{
            name: "",
            categoria_id: "",
            tipo_usuario: []
        },

        filterSubcategoriaDefault:{
            name: "",
            categoria_id: "",
            tipo_usuario: []
        },

        loading: false,
        loadingsubcategorias: false,

        listsubcategorias: []
    },

    mutations: {

        setSubCategorias(state, payload) {
            state.listsubcategorias = payload;
        },

        editSubCategoria(state, subcategoria) {
            state.subcategoria = Object.assign({}, subcategoria)
        },

        resetSubCategoria(state) {
            state.subcategoria = Object.assign({}, state.subcategoriaDefault)
        },

        resetFilterSubcategoria(state)
        {
            state.filterSubcategoria = Object.assign({}, state.filterSubcategoriaDefault)
        }
    },

    actions: {

        async getSubCategoriasAxios({ commit, state }) {
            state.loadingsubcategorias = true
            try {
                const data = await SubCategoriaSv.getAll(state.filterSubcategoria);
                commit('setSubCategorias', data)
                state.loadingsubcategorias = false
            } catch (error) {
                console.log(error)
                state.loadingsubcategorias = false
                commit('utiles/alertError')
            }

        },

        async createSubCategoriaAxios({ commit, dispatch, state }) {
            commit('utiles/alertLoading')
            try {
                const data = await SubCategoriaSv.createSubCategoria(state.subcategoria)
                commit('resetSubCategoria')
                commit('utiles/alertConfirm', "Se agregó una nueva subcategoria")
                dispatch('getSubCategoriasAxios')
            } catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },

        async updateSubCategoriaAxios({ commit, dispatch, state }) {
            commit('utiles/alertLoading')
            console.log(state.subcategoria)
            try {
                const data = await SubCategoriaSv.updateSubCategoria(state.subcategoria)
                
                commit('resetSubCategoria')
                commit('utiles/alertConfirm', "Se actualizó la subcategoria")
                dispatch('getSubCategoriasAxios')
            } catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },

        async deleteSubCategoriaAxios({ commit, dispatch, state }, idsubcategoria) {
            const confirm = await Swal.fire({
                title: "¿Desea eliminar este elemento?",
                icon: "question",
                cancelButtonText: "cancelar",
                confirmButtonText: "CONFIRMAR",
                showCancelButton: true,
            })
            if (!confirm.isConfirmed) { return }
            commit('utiles/alertLoading')
            try {
                await SubCategoriaSv.deleteSubCategoria(idsubcategoria)
                commit('resetSubCategoria')
                commit('utiles/alertConfirm', "Se eliminó la subcategoria")
                dispatch('getSubCategoriasAxios')
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }

        }
    }
}