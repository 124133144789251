import utiles from "@/modules/Shared/utiles/utiles";
import axios from "axios";
import Swal from "sweetalert2";
import CategoriaService from "../services/CategoriaService";

const CategoriaSv = new CategoriaService();

export default {
    namespaced: true,
    modules: {
        utiles
    },
    state: {
        categoria: {
            nombre: "",
            descripcion: "",
            tipo_id: "",
            tipo_usuario: []
        },

        categoriaDefault: {
            nombre: "",
            descripcion: "",
            tipo_id: "",
            tipo_usuario: []
        },

        filterCategoria:{
            tipo_id: "",
            nombre: "",
            tipo_usuario: []
        },

        filterCategoriaDefault:{
            tipo_id: "",
            nombre: "",
            tipo_usuario: []
        },

        loading: false,
        loadingcategorias: false,

        listCategorias: []
    },

    mutations: {

        setCategorias(state, payload) {
            state.listCategorias = payload;
        },

        editCategoria(state, categoria) {
            state.categoria = Object.assign({}, categoria)
        },

        resetCategoria(state) {
            state.categoria = Object.assign({}, state.categoriaDefault)
        },


        resetFilterCategoria(state)
        {
            state.filterCategoria = Object.assign({}, state.filterCategoriaDefault)
        }
    },

    actions: {

        async getCategoriasAxios({ commit, state }) {
            state.loadingcategorias = true
            try {
                const data = await CategoriaSv.getAll(state.filterCategoria);
                console.log(data)
                commit('setCategorias', data)
                state.loadingcategorias = false
            } catch (error) {
                console.log(error)
                state.loadingcategorias = false
                commit('utiles/alertError')
            }

        },

        async createCategoriaAxios({ commit, dispatch, state }) {
            commit('utiles/alertLoading')
            try {
                const data = await CategoriaSv.createCategoria(state.categoria)
                commit('resetCategoria')
                commit('utiles/alertConfirm', "Se agregó una nueva categoría")
                dispatch('getCategoriasAxios')
            } catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },

        async updateCategoriaAxios({ commit, dispatch, state }) {
            commit('utiles/alertLoading')
            console.log(state.categoria)
            try {
                const data = await CategoriaSv.updateCategoria(state.categoria)
                
                commit('resetCategoria')
                commit('utiles/alertConfirm', "Se actualizó la categoría")
                dispatch('getCategoriasAxios')
            } catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },

        async deleteCategoriaAxios({ commit, dispatch, state }, idcategoria) {
            const confirm = await Swal.fire({
                title: "¿Desea eliminar este elemento?",
                icon: "question",
                cancelButtonText: "cancelar",
                confirmButtonText: "CONFIRMAR",
                showCancelButton: true,
            })
            if (!confirm.isConfirmed) { return }
            commit('utiles/alertLoading')
            try {
                await CategoriaSv.deleteCategoria(idcategoria)
                commit('resetCategoria')
                commit('utiles/alertConfirm', "Se eliminó la categoría")
                dispatch('getCategoriasAxios')
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }

        }
    }
}