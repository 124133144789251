<template>
    <v-select hide-details="auto" density="compact" :items="listEstados" item-title="nombre" item-value="idestado"
        :style="{ width: width }" style="border-bottom: none !important;" bg-color="bg-transparent" hide-no-data
        class="select-template" v-model="setectedItem" :menu-icon="readonly ? '' : 'fa:fa-solid fa-chevron-down fa-2xs'" :readonly="readonly"
        
        :loading="loadingEstado" @update:modelValue="changeSelect({ idticket: idticket, ticket_estado_new: setectedItem })">
        <template v-slot:selection="{ item }">
            <small class="font-italic font-weight-bold" v-if="item.title == ''">{{readonly ? 'enviado' : 'Cambiar estado'}}</small>
            <div class="d-flex align-center">
                <v-avatar start size="25" rounded="sm" :style="'background-color:' + item.raw.color"
                    class="mr-2 text-white"><v-icon size="15">{{ item.raw.icon }}</v-icon></v-avatar>
                <div style="font-size: 12px" class="font-weight-bold">{{ item.raw.nombre }}</div>
            </div>
        </template>
        <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="''" :title="''">
                <template v-slot:prepend>
                    <v-avatar start size="25" rounded="sm" :style="'background-color:' + item.raw.color"
                        class="mr-2 text-white"><v-icon size="15">{{ item.raw.icon }}</v-icon></v-avatar>
                </template>
                <v-list-item-title style="font-size: 12px" class="font-weight-bold">
                    {{ item.raw.nombre }}
                </v-list-item-title>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    props: {
        estado: {
            type: Object,
        },
        idticket: {
            type: Number
        },
        width:
        {
            type: String,
            default: '180px'
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            setectedItem: null,
            loadingEstado: false
        }
    },

    computed: {
        ...mapState('configuracion/estado', ['listEstados']),

    },
 
    methods: {
        ...mapActions('tickets', ['changeStateAxios']),
        async changeSelect(data) {
            this.loadingEstado = true
            await this.changeStateAxios(data)
            this.loadingEstado = false

        }
    },
    mounted() {
        this.setectedItem = this.estado ? this.estado.idestado : ""
    },

}
</script>