<template>
  <v-card elevation="0" class="px-2 px-md-8 px-xl-12 d-flex justify-center align-center" style="height: 100vh">
    <v-form v-model="validateForm">
      <v-card-text style="overflow-y: hidden !important">

        <div class="text-subtitle-2 py-6">{{ edit ? "Editar datos del usuario" : "Registrar nuevo usuario" }} </div>

        <v-row class="pa-0">
          <v-col cols="12" md="6">
            <label for="">Tipo Usuario</label>
            <v-select variant="outlined" density="compact" hide-details="auto" :items="['colaborador', 'estudiante']" v-model="user.tipouser"
              menu-icon="fa:fa-solid fa-angle-down" bg-color="grey-lighten-5" class="compact-full-input" :rules="[rules.requiredSelect]"
               hide-no-data :loading="loading">
              <template v-slot:selection="{ item }">
                <span v-if="item.title == ''">Selecciona</span>
                <div class="text-truncate">{{ item.title }}</div>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6"></v-col>
          <v-col cols="12" md="6">
            <label for="">TIPO DOCUMENTO</label>
            <v-select variant="outlined" density="compact" hide-details="auto" v-model="user.tipo_documento"
              bg-color="grey-lighten-5" class="compact-full-input" :items="['DNI', 'Carnet de Extranjería']" :rules="[rules.required]"></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <label for="">NÚMERO DE DOCUMENTO</label>
            <v-text-field variant="outlined" density="compact" hide-details="auto" v-model="user.numero_documento"
              bg-color="grey-lighten-5" class="compact-full-input" @change="getDNIColaborador()" :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12">
            <label for="">Nombres</label>
            <v-text-field variant="outlined" density="compact" hide-details="auto" v-model="user.nombres"
              bg-color="grey-lighten-5" class="compact-full-input" :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12">
            <label for="">Apellidos</label>
            <v-text-field variant="outlined" density="compact" hide-details="auto" v-model="user.apellidos"
              bg-color="grey-lighten-5" class="compact-full-input" :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12">
            <label for="">Email</label>
            <v-text-field variant="outlined" density="compact" hide-details="auto" v-model="user.email"
              bg-color="grey-lighten-5" class="compact-full-input" :rules="[rules.required, rules.email]"></v-text-field>
          </v-col>


          <v-col cols="12" md="6" v-if="user.tipouser == 'colaborador'">
            <label for="">Cargo</label>
            <v-text-field variant="outlined" density="compact" hide-details="auto" v-model="user.cargo"
              bg-color="grey-lighten-5" class="compact-full-input" :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12" md="6" v-if="user.tipouser == 'colaborador'">
            <label for="">Área</label>
            <v-text-field variant="outlined" density="compact" hide-details="auto" v-model="user.area"
              bg-color="grey-lighten-5" class="compact-full-input" :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12" md="6" v-if="user.tipouser == 'estudiante'">
            <label for="">Carrera</label>
            <v-text-field variant="outlined" density="compact" hide-details="auto" v-model="user.carrera"
              bg-color="grey-lighten-5" class="compact-full-input" :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12" md="6" v-if="user.tipouser == 'estudiante'">
            <label for="">Admisión</label>
            <v-text-field variant="outlined" density="compact" hide-details="auto" v-model="user.admision"
              bg-color="grey-lighten-5" class="compact-full-input" :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <label for="">Contraseña</label>
            <v-text-field :type="hidepass ? 'password' : 'text'" variant="outlined" density="compact" hide-details="auto"
              v-model="user.password" bg-color="grey-lighten-5" class="compact-full-input">
              <template v-slot:append-inner="" :rules="[rules.required]">
                <v-icon size="x-small" @click="hidepass = !hidepass">{{ hidepass ? 'fa:fa-solid fa-eye-slash' : 'fa:fa-solid fa-eye' }}</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <label for="">Rol</label>
            <v-select variant="outlined" density="compact" hide-details="auto" :items="listrole" v-model="user.role_id"
              menu-icon="fa:fa-solid fa-angle-down" bg-color="grey-lighten-5" class="compact-full-input"
              item-value="idrole" item-title="name" hide-no-data :loading="loading" :rules="[rules.required]">
              <template v-slot:selection="{ item }">
                <span v-if="item.title == ''">Selecciona un rol</span>
                <div class="text-truncate">{{ item.title }}</div>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12">
          <v-btn v-if="user.iduser" :disabled="!validateForm" @click="updateUser()" class="btn-system" variant="flat" block
            elevation="0">ACTUALIZAR</v-btn>
          <v-btn v-else :disabled="!validateForm" @click="createUser()" class="btn-system" variant="flat" block
            elevation="0">GUARDAR CAMBIOS</v-btn>
        </v-col>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Filters from "@/modules/Shared/Filters";
export default {
  mixins: [Filters],
  data() {
    return {
      edit: true,
      validateForm: true,
      hidepass: true
    };
  },
  watch: {
    userEdit(val) {
      this.edit = val.nombres != "";
    },
  },
  computed: {
    ...mapState("usuarios", [ 
      "tiposUsuario",
      "rolUsuario",
      "dialogEdit",
      "user",
    ]),
    ...mapState('usuarios/roles', ['listrole', 'loading']),

  },

  methods: {
    ...mapActions("usuarios", ["createUser", 'getDNIColaborador', 'updateUser']),
  },
};
</script>
