import utiles from "@/modules/Shared/utiles/utiles";
import AuthService from "../services/AuthService"

const Auth = new AuthService();

export default
{
    namespaced:true,
    modules: {
        utiles
    },
    state:
    {

    },

    mutations:
    {

    },

    actions:
    {
       
    }
}