import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";

export default class SubCategoriaService {
    constructor() {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        });
        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }


    async getAll(params) {
        const response = await this.axios.get('subcategoria', {params: params});
        return response.data;
    } 

    async createSubCategoria(data) {
        const response = await this.axios.post('subcategoria', data, { headers: { 'Content-Type': "application/json" }});
        return response.data;
    }

    async updateSubCategoria(data) {
        const response = await this.axios.put('subcategoria', data);
        return response.data;
    }

    async deleteSubCategoria(idsubcategoria) {
        const response = await this.axios.delete('subcategoria?id_subcategoria=' + idsubcategoria)
        return response.data;
    }
}