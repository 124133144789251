import Swal from "sweetalert2";
import RolService from "../services/RolService";
import utiles from "@/modules/Shared/utiles/utiles";

const RolSV = new RolService()

export default {
    namespaced: true,
    modules: {
        utiles
    },
    state: {
        listrole: [],
        role: {
            name: "",
            sedes: [],
            permisos: []
        },
        roleDefault: {
            name: "",
            sedes: [],
            permisos: []
        },
        permisos: [],
        permiso: {
            name: "",
            suject: "",
            actions: [],
            object: ""
        },
        permisoDefault: {
            name: "",
            suject: "",
            actions: [],
            object: ""
        },

        sujects: [
            {
                title: "Tickets",
                value: "tickets-area"
            },
            {
                title: "Gestión de usuarios",
                value: "usuarios-area"
            },
            {
                title: "Configuración general",
                value: "configuracion-area"
            },
            {
                title: "Panel de control - metricas",
                value: "panel-metricas"
            }
        ],

        actions: [
            {
                title: "CREAR",
                value: "create"
            },
            {
                title: "LEER",
                value: "read"
            },
            {
                title: "ACTUALIZAR",
                value: "update"
            },
            {
                title: "ELIMINAR",
                value: "delete"
            }
        ],

        loadingRoles: false,
        dataPARSE: {}

    },

    mutations: {
        setRoles(state, payload) {
            state.listrole = payload;
        },

        addPermiso(state) {
            if (state.permiso.name === '') { return }

            let areaFind = state.permisos.find(permiso => permiso.name == state.permiso.name)

            if (areaFind) {
                Swal.fire({
                    text: "El permiso ya está registrado",
                    icon: "warning"
                })
                return
            }

            state.permisos.push(Object.assign({}, state.permiso))

            state.permiso = Object.assign({}, state.permisoDefault)
        },

        editPermiso(state, payload)
        {
            state.role = Object.assign({}, payload)
            state.permisos = Object.assign([], payload.permisos_user) 
            state.role.sedes = payload.sede.map(sede => sede.idsede)
        },

        deletePermiso(state, payload)
        {
            const index = state.permisos.indexOf(payload)


            state.role.permisos = state.permisos.splice(index, 1);
        },

        resetRole(state) {
            state.role = Object.assign({}, state.roleDefault)
            state.permisos= []
        },

        parseData(state) {
            const data = Object.assign({}, state.role)
            data['permisos'] = state.permisos
            let sedes = []
            data.sedes.forEach(s => {
                let sede = { sede_id: s}

                sedes.push(sede)
            })

            data.sedes = sedes
            console.log(data.permisos)
            let permisos = []
            data.permisos.forEach(p => {
                let permiso = {}
                let actionsString = p.actions.join(',')
                permiso['actions'] = actionsString
                
                permiso['suject'] = p.name.value || p.suject
                permiso['name'] = p.name.title  || p.name

                permisos.push(permiso)

            });
            
            data.permisos = permisos;

            state.dataPARSE = Object.assign({}, data);

        }
    },

    actions:
    {
        async getAllRoles({ commit, state }) {
            state.loadingRoles = true
            try {
                const data = await RolSV.getAll()
                state.loadingRoles = false
                commit('setRoles', data)

            } catch (error) {
                state.loadingRoles = false
                console.log(error)
            }
        },

        async createRole({ commit, dispatch, state }) {
            commit('utiles/alertLoading')
            try {
                commit('parseData')
                
                await RolSV.createRole(state.dataPARSE)
                commit('resetRole')
                dispatch('getAllRoles')
                commit('utiles/alertConfirm', 'Se creo un nuevo rol')

            } catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },


        async updateRole({ commit, dispatch, state }) {
            commit('utiles/alertLoading')
            try {
                commit('parseData')
                await RolSV.updateRole(state.dataPARSE)
                commit('resetRole')
                dispatch('getAllRoles')
                commit('utiles/alertConfirm', 'Se actualizó el rol')

            } catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },

        async deleteRole({commit, dispatch, state}, idrole)
        {
            const confirmar = await Swal.fire({
                title: "¿Deseas eliminar el rol?",
                icon: "question",
                showCancelButton:true,
                confirmButtonText: "CONFIRMAR"
            })
            if(!confirmar.isConfirmed){ return }
            commit('utiles/alertLoading')
            try {
                await RolSV.deleteRole(idrole)
                commit('resetRole')
                dispatch('getAllRoles')
                commit('utiles/alertConfirm', 'Se eliminó el rol')

            } catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },

        async roleDefault({commit, dispatch, state}, idrole)
        {
            commit('utiles/alertLoading')
            try {
                await RolSV.roleDefault(idrole)
                dispatch('getAllRoles')
                commit('utiles/alertConfirm', 'Cambio completado')

            } catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        }
    }
}