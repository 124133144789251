<template>
    <div :style="$vuetify.display.xs ? 'height:400px; overflow-y:auto;' : 'height:auto'" class="pa-5">
      <v-form v-model="validateform">
        <div class="text-h6 font-weight-bold mb-2">Confirma tus datos personales</div>
        <v-row>
            <v-col cols="12" md="6" xl="4">
                <label>DNI</label>
                <v-text-field v-model="datauserregister.numero_documento" variant="outlined" density="compact" hide-details="auto"
                    :rules="[rules.required]"></v-text-field>
            </v-col>

            <v-col cols="12" md="6" xl="4">
                <label>Apellidos</label>
                <v-text-field v-model="datauserregister.apellidos" variant="outlined" density="compact" hide-details="auto"
                    :rules="[rules.required]"></v-text-field>
            </v-col>

            <v-col cols="12" md="6" xl="4">
                <label>Nombres</label>
                <v-text-field v-model="datauserregister.nombres" variant="outlined" density="compact" hide-details="auto"
                    :rules="[rules.required]"></v-text-field>
            </v-col>

        </v-row>

        <v-row v-if="datauserregister.tipouser == 'estudiante'">
            <v-col cols="12" md="8" xl="9">
                <label>Carrera</label>
                <v-text-field v-model="datauserregister.carrera" variant="outlined" density="compact" hide-details="auto"
                    :rules="[rules.required]"></v-text-field>
            </v-col>

            <v-col cols="12" md="4" xl="3">
                <label>Admisión</label>
                <v-text-field v-model="datauserregister.admision" variant="outlined" density="compact" hide-details="auto"
                    :rules="[rules.required]"></v-text-field>
            </v-col>

        </v-row>


        <v-row v-if="datauserregister.tipouser == 'colaborador'">
            <v-col cols="12" md="6" xl="7">
                <label>Cargo</label>
                <v-text-field v-model="datauserregister.cargo" variant="outlined" density="compact" hide-details="auto"
                    :rules="[rules.required]"></v-text-field>
            </v-col>

            <v-col cols="12" md="6" xl="5">
                <label>Área</label>
                <v-text-field v-model="datauserregister.area" variant="outlined" density="compact" hide-details="auto"
                    :rules="[rules.required]"></v-text-field>
            </v-col>

        </v-row>


        <v-row>
            <v-col cols="12" md="6">
                <label>Número de contacto</label>
                <v-text-field v-model="datauserregister.celular" variant="outlined" density="compact" hide-details="auto"
                    :rules="[rules.required, rules.celular]"></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
                <label>Correo electrónico</label>
                <v-text-field v-model="datauserregister.email" variant="outlined" density="compact" hide-details="auto"
                    :rules="[rules.required, rules.email]"></v-text-field>
            </v-col>

        </v-row>
    </v-form>
    </div>
</template>



<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapState } from 'vuex';

export default {
    mixins: [Filters],
    data(){
        return{
            validateform: true
        }
    },
    computed:
    {
        ...mapState('login', ['datauserregister'])
    },
    watch:{
        validateform:{
            handler(newval, oldval){
                this.validateform = newval;
                this.$emit('validateFormEvent', this.validateform)
                
            }
        }
    },
    mounted()
    {
        this.$emit('validateFormEvent', this.validateform)
    }
}
</script>