 <template>
    <router-view  />
</template>

<script>

export default {
  name: "Module",


};
</script> 