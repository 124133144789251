<template>
  <v-card rounded="0" elevation="3" class="bgLight2Theme" outlined>
    <v-container fluid class="d-flex justify-space-between align-center">
      <div class="d-flex">
        <v-btn @click="openNewUser()" class="btn-system mr-3" size="small"
          elevation="0" v-if="$abillity.can('create', 'usuarios-area')">
          <v-icon start size="x-smal">fa:fa-solid fa-user-plus</v-icon>
          <small>Nuevo usuario</small></v-btn>

          <v-text-field
            variant="outlined"
            placeholder="buscar"
            hide-details="auto"
            density="compact"
            bg-color="grey-lighten-5"
            clearable
            clear-icon="fa:fa-solid fa-close"
            class="compact-full-input"
            :style="$vuetify.display.xs ? 'width: 200px;' : 'width: 250px;'"
            @keyup.enter="getAllUser()"
            v-model="filter.buscar"
            @click:clear="filter.buscar = ''; getAllUser()"
            :loading="loadingbuscar"
          ></v-text-field>
      </div>
    </v-container>
  </v-card>

  <v-container fluid>
    <v-row class="pa-0">
      <v-col cols="12"  class="mx-auto pa-1">
        <ListaUsuariosComponent />
      </v-col>




    </v-row>
  </v-container>
</template>

<script>
import { shallowRef } from 'vue';
import FiltersComponent from '../components/ListaUsuarios/FiltersComponent.vue';
import ListaUsuariosComponent from '../components/ListaUsuarios/ListaUsuariosComponent.vue'

import { mapState, mapMutations, mapActions } from "vuex";
import RegistrarUserComponent from '../components/ListaUsuarios/RegistrarUserComponent.vue';

export default {
  components: {
    ListaUsuariosComponent,
    FiltersComponent,

  },

  data() {
    return {
      hidefilters: true
    }
  },

  computed: {
    ...mapState('usuarios', ['filter', 'loadingbuscar'])
  },  

  methods:{
    ...mapMutations(["changeSilderMulti", "changeComponentDynamic"]),
    ...mapMutations('usuarios', ['resetUser']),
    ...mapActions('usuarios', ['getAllUser']),
    openFilters(){
      const component = shallowRef(FiltersComponent)
      this.changeSilderMulti()
      this.changeComponentDynamic({componentName: component, props: {class: "px-8  d-flex justify-center align-center", style: "height: 100vh; width: 100%;"}})
    },

    openNewUser(){
      this.resetUser()
      const component = shallowRef(RegistrarUserComponent)
      this.changeSilderMulti()
      this.changeComponentDynamic({componentName: component})
    }
  },

  created()
  {
    if(!this.$abillity.can('read', 'usuarios-area'))
    {
      this.$router.push({name: 'tickets'})
    }
  }
}
</script>