<template>
    <v-select hide-details="auto" density="compact" :items="listPrioridad" item-title="nombre" item-value="idprioridad"
    :style="{width: width}" :readonly="readonly"
    style="border-bottom: none !important;" bg-color="bg-transparent" hide-no-data class="select-template" v-model="setectedItem"
    :loading="loadingPriority" @update:modelValue="changeSelect({idticket: idticket, ticket_priority_new: setectedItem})"
    :menu-icon="readonly ? '' : 'fa:fa-solid fa-chevron-down fa-2xs'">
    <template v-slot:selection="{ item }">
      <div class="d-flex align-center">
        <v-avatar start size="10" class="mr-3" rounded :style="prioridad ? `background-color: ${item.raw.color};` : 'background-color: white;'"></v-avatar>
        <div style="font-size: 12px" class="font-weight-bold">{{ item.raw.nombre }}</div>
      </div>
    </template>
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :subtitle="''" :title="''">
        <template v-slot:prepend>
          <v-avatar :style="`background-color: ${item.raw.color};`" size="10" >
          </v-avatar>
        </template>
        <v-list-item-title style="font-size: 12px" class="font-weight-bold">
          {{ item.raw.nombre }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: {
    prioridad: {
      type: Object,
    },
    idticket:{
      type: Number
    },
    width:
    {
      type: String,
      default: '110px'
    },
    readonly:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      setectedItem:null,
      loadingPriority: false
    }
  },
  computed: {
    ...mapState("configuracion/prioridad", ["listPrioridad"]),
  },

  methods: {
   ...mapActions("tickets", ["changePriorityAxios"]),
   async changeSelect(data){
      this.loadingPriority = true
      await this.changePriorityAxios(data)
      this.loadingPriority = false
    }
  },

  mounted() {
    this.setectedItem = this.prioridad.idprioridad;
  },


};
</script>
