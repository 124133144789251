import agente from "./agente"
import categoria from "./categoria"
import estado from "./estado"
import notificacion from "./notificacion"
import prioridad from "./prioridad"
import sede from "./sede"
import subcategoria from "./subcategoria"
import tipo from "./tipo"


export default {
    namespaced: true,
    state:{

    },

    modules:{
        tipo,
        categoria,
        subcategoria,
        estado,
        prioridad,
        sede,
        agente,
        notificacion
    }
}