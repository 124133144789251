<template>
    <div>Redirecting...</div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    methods: {
        ...mapActions('login', ['loginMicrosoft'])
    },
    mounted() {
        this.loginMicrosoft(this.$route.params.token)
    }
}
</script>