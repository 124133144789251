import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";

export default class PrioridadService {
    constructor() {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers: {
                'content-type': "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        });
        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }


    async getAll() {
        const response = await this.axios.get('prioridad');
        return response.data;
    }

    async createPrioridad(data) {
        const response = await this.axios.post('prioridad', data);
        return response.data;
    }

    async updatePrioridad(data) {
        const response = await this.axios.put('prioridad', data);
        return response.data;
    }

    async deletePrioridad(idprioridad) {
        const response = await this.axios.delete('prioridad?idprioridad=' + idprioridad)
        return response.data;
    }
}