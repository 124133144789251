<template>
  <v-card elevation="0" class="bg-white" variant="outlined" style="border: 1px solid #cfd7df !important;">
    <v-card-title class="text-subtitle-2 bgLight2Theme pb-0">
      Notas
    </v-card-title>

    <v-card-text class="pa-2 pt-3">
      <v-textarea variant="underlined" density="compact" rows="1" hide-details="auto">
        <template v-slot:prepend-inner="">
          <v-icon size="18" color="green-accent-4" class="bg-green-accent-1 rounded-circle" style="cursor: pointer;">mdi:mdi-plus-circle-outline</v-icon>
        </template>
      </v-textarea>
    </v-card-text>
  </v-card>
</template>
