<template>
    <div class="pa-2 d-flex flex-column justify-center">
        <v-avatar size="90" class="bg-blue-grey-lighten-5 mx-auto mt-4 text-blue-grey-darken-1">
            <v-icon size="30" >fa:fa-solid fa-user</v-icon>
        </v-avatar>
        <div class="text-center pt-5">
            <span class="font-weight-bold d-block">{{ capitalizeWords(datauserregister.nombres + ' ' + datauserregister.apellidos) }}</span>
            <span>{{ datauserregister.email  }}</span>
        </div>
        <v-col cols="12" md="6" xl="4" class="mx-auto">
            <v-form v-model="formvalidate">
            <v-col cols="12">
                <small class="font-weight-bold">Nueva contraseña</small>
                <v-text-field :type="hidepass1?'password':'text'"  variant="outlined" density="compact"
                hide-details="auto" :rules="[rules.required]" v-model="updatePassword.newpass">
                    <template v-slot:append-inner>
                        <v-icon size="20" @click="hidepass1 = !hidepass1">{{ hidepass1 ? 'fa:fa-solid fa-eye' :'fa:fa-solid fa-eye-slash' }}</v-icon>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" >
                <small class="font-weight-bold">Vuelve a ingresar la contraseña</small>
                <v-text-field :type="hidepass2?'password':'text'"  variant="outlined" density="compact"
                hide-details="auto" :rules="[rules.required, validatePassword(updatePassword.newpass,updatePassword.confirmpass)]" v-model="updatePassword.confirmpass">
                    <template v-slot:append-inner>
                        <v-icon size="20" @click="hidepass2 = !hidepass2">{{ hidepass2 ? 'fa:fa-solid fa-eye' :'fa:fa-solid fa-eye-slash' }}</v-icon>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12">
                <v-btn @click="updatePassInvitado()" :loading="loadingUpdatePass" :disabled="!formvalidate" class="btn-system" block elevation="0"> INICIAR SESIÓN</v-btn>
            </v-col>
            </v-form>
        </v-col>
    </div>
</template>


<script>
import Filters from '@/modules/Shared/Filters';

import { mapState, mapActions } from 'vuex';

export default {
    mixins:[Filters],
    data()
    {
        return{
            hidepass1:true,
            hidepass2:true,
            formvalidate: true
        }
    },
    computed:
    {
        ...mapState('login', ['datauserregister', 'updatePassword', 'loadingUpdatePass'])
    },

    methods:{
        ...mapActions('login', ['updatePassInvitado'])
    }
}
</script>