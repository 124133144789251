<template>
<v-row class="bg-pattern">
    <v-col cols="12" md="4" class="mx-auto d-flex align-center justify-center" style="height: 100vh;">
        <v-card class="pa-2 d-flex flex-column justify-center bg-white" style="width: 100%;" v-if="datauserPass.email != '' && !loadingvalidatetoken">
            <v-card-title>
                Restaurar Contraseña
            </v-card-title>
        <v-avatar size="90" class="bg-blue-grey-lighten-5 mx-auto mt-4 text-blue-grey-darken-1">
            <v-icon size="30" >fa:fa-solid fa-user</v-icon>
        </v-avatar>
        <div class="text-center pt-5">
            <span class="font-weight-bold d-block">{{ capitalizeWords(datauserPass.nombres + ' ' + datauserPass.apellidos) }}</span>
            <span>{{ datauserPass.email  }}</span>
        </div>
        <v-card-text>
            <v-form v-model="formvalidate">
            <v-col cols="12">
                <small class="font-weight-bold">Nueva contraseña</small>
                <v-text-field :type="hidepass1?'password':'text'"  variant="outlined" density="compact"
                hide-details="auto" :rules="[rules.required]" v-model="updatePassword.newpass">
                    <template v-slot:append-inner>
                        <v-icon size="20" @click="hidepass1 = !hidepass1">{{ hidepass1 ? 'fa:fa-solid fa-eye' :'fa:fa-solid fa-eye-slash' }}</v-icon>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" >
                <small class="font-weight-bold">Vuelve a ingresar la contraseña</small>
                <v-text-field :type="hidepass2?'password':'text'"  variant="outlined" density="compact"
                hide-details="auto" :rules="[rules.required, validatePassword(updatePassword.newpass,updatePassword.confirmpass)]" v-model="updatePassword.confirmpass">
                    <template v-slot:append-inner>
                        <v-icon size="20" @click="hidepass2 = !hidepass2">{{ hidepass2 ? 'fa:fa-solid fa-eye' :'fa:fa-solid fa-eye-slash' }}</v-icon>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12">
                <v-btn @click="updatePassConfirm()" :loading="loadingUpdatePass" :disabled="!formvalidate" class="btn-system" block elevation="0"> INICIAR SESIÓN</v-btn>
            </v-col>
            </v-form>
        </v-card-text>
    </v-card >

    <v-card v-else width="100%">
        <v-card-title>Validando token</v-card-title>
        <v-card-text>
            <v-skeleton-loader
          class="mx-auto border"
          max-width="100%"
          type="card-avatar, actions"
        ></v-skeleton-loader>
        </v-card-text>
    </v-card>


    </v-col>
</v-row>
</template>


<script>
import Filters from '@/modules/Shared/Filters';

import { mapState, mapActions } from 'vuex';

export default {
    mixins:[Filters],
    data()
    {
        return{
            hidepass1:true,
            hidepass2:true,
            formvalidate: true
        }
    },
    computed:
    {
        ...mapState('login', ['datauserPass', 'updatePassword', 'loadingUpdatePass', 'loadingvalidatetoken'])
    },

    methods:{
        ...mapActions('login', ['updatePassConfirm', 'getUserEmail'])
    },

    mounted()
    {
        this.getUserEmail(this.$route.params.email)
        console.log(this.$route.params.email)
    }
}
</script>