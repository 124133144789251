
import MetricaService from '../services/MetricaService'

const MetricaSv = new MetricaService();

export default{
    namespaced: true,

    state:{
        mensualTicketsCount : {},
        tipoTicketsCount : {},
        categoriaTicketsCount : {},
        estadoTicketsCount : {},
        agenteTicketCount: {},
        agenteEstadoTicketCount: {},
        AssignedCount: {},
        filtersGrafico:{
            estadomes: null,
            tipomes: null,
            categoriames: null,
            agentemes: null,
            agenteestadomes: null
        },
        filtersGraficoDefault:{
            estadomes: null,
            tipomes: null,
            categoriames: null,
            agentemes: null,
            agenteestadomes: null
        },
        loadingTipo: false,
        loadingCategoria: false,
        loadingEstado: false,
        loadingMensual: false,
        loadingAgente: false,
        loadingAgenteEstado: false,
        loadingAssigned: false
    },

    mutations:{
        setMensualTicketCount(state, payload)
        {
            state.mensualTicketsCount = payload
        },
        setTipoCount(state, payload)
        {
            state.tipoTicketsCount = payload;
        },

        setCategoriaCount(state, payload)
        {
            state.categoriaTicketsCount = payload;
        },

        setEstadoCount(state, payload)
        {
            state.estadoTicketsCount = payload;
        },

        setAgenteCount(state, payload)
        {
            state.agenteTicketCount = payload
        },

        setAgenteEstadoCount(state, payload)
        {
            state.agenteEstadoTicketCount = payload
        },

        setAssignedCount(state, payload)
        {
            state.AssignedCount = payload
        },

        resetFilters(state)
        {
            state.filtersGrafico = Object.assign({}, state.filtersGraficoDefault)
        }
    },

    actions:{
        async getCountTicketPorMes({state, commit})
        {
            state.loadingMensual = true
            try{

                const data = await MetricaSv.getCountTicketPorMes()
                commit('setMensualTicketCount', data)
                state.loadingMensual = false
            }
            catch(error){
                state.loadingMensual = false
                console.log(error)
            } 
        },

        async getCountTicketTipo({state, commit})
        {
            state.loadingTipo = true
            try{

                const data = await MetricaSv.getCountTipoTicket({mes: state.filtersGrafico.tipomes})
                commit('setTipoCount', data)
                state.loadingTipo = false
            }
            catch(error){
                state.loadingTipo = false
                console.log(error)
            } 
        },

        async getCountTicketCategoria({state, commit})
        {
            state.loadingCategoria = true
            try{
                const data = await MetricaSv.getCountCategoriaTicket({mes: state.filtersGrafico.categoriames})
                commit('setCategoriaCount', data)
                state.loadingCategoria = false
            }
            catch(error){
                console.log(error)
                state.loadingCategoria = false
            }
        },


        async getCountTicketEstado({state, commit})
        {
            state.loadingEstado = true
            try{
                const data = await MetricaSv.getCountTicketEstado({mes: state.filtersGrafico.estadomes})
                commit('setEstadoCount', data)
                state.loadingEstado = false
            }
            catch(error){
                console.log(error)
                state.loadingEstado = false
            }
        },


        async getCountTicketAgente({state, commit})
        {
            state.loadingAgente = true
            try{
                const data = await MetricaSv.getCountTicketAgente({mes: state.filtersGrafico.agentemes})
                commit('setAgenteCount', data)
                state.loadingAgente = false
            }
            catch(error){
                console.log(error)
                state.loadingAgente = false
            }
        },


        async getCountTicketAgenteEstado({state, commit})
        {
            state.loadingAgenteEstado = true
            try{
                const data = await MetricaSv.getCountTicketAgenteEstado({mes: state.filtersGrafico.agenteestadomes})
                commit('setAgenteEstadoCount', data)
                state.loadingAgenteEstado = false
            }
            catch(error){
                console.log(error)
                state.loadingAgenteEstado = false
            }
        },


        async getCountTicketAssigned({state, commit})
        {
            state.loadingAssigned = true
            try{
                const data = await MetricaSv.getCountTicketAssigned({mes: state.filtersGrafico.agenteestadomes})
                commit('setAssignedCount', data)
                state.loadingAssigned = false
            }
            catch(error){
                console.log(error)
                state.loadingAssigned = false
            }
        }
    }
}