<template>

  <v-row dense>
    <v-col cols="12" md="6">
      <v-card elevation="0">
        <v-card-text class="pa-0 pa-md-2">
          <div class="mb-2 mt-2">
            <div class="text-md-h5 text-h6 font-weight-medium mb-2">Bienvenido al sistema de solicitudes</div>
            <span class="text-body-1">Por favor inicia sesión para empezar</span>
          </div>
          <div class="pa-0 mb-3 text-center">
            <v-btn @click="redirectToMicrosoft()" variant="outlined" style="border-color: #ccc;" size="large" block
              color="indigo-900">
              <v-avatar start size="25" class="mr-2"><v-img :src="require('@/assets/microsoft.svg')" width="25px"
                  class="mx-2" cover></v-img></v-avatar>
              <span class="text-body-2 font-weight-medium">INICIAR SESION CON MICROSOFT</span>
            </v-btn>
          </div>
          <div class="text-caption font-weight-medium text-center mb-2">O Inicia sesión con tu correo electrónico</div>

          <label>Email</label>
          <v-text-field type="email" placeholder="correo@cetemin.edu.pe" density="compact" variant="outlined"
            class="mb-3" v-model="user.email"></v-text-field>
          <label>Contraseña</label>
          <v-text-field type="password" placeholder="********" density="compact" variant="outlined" class="mb-3"
            v-model="user.password" @keyup.enter="login()"></v-text-field>

          <v-btn variant="flat" size="small" class="mb-2" @click="switchComponent('FormResetPassComponent')"> Olvidé
            Contraseña</v-btn>
          <v-btn elevation="0" rounded x-large @click="login()" color="indigo 900" block
            class="font-weight-medium py-5"> Iniciar sesión
          </v-btn>
          <v-alert v-if="errorLogin.type" :type="errorLogin.type" variant="tonal" closable density="compact"
            class="my-2 pa-1">
            <small>{{ errorLogin.message }}</small>
          </v-alert>

          <v-img :src="require('@/assets/logo-tic.svg')" width="70px" class="mx-auto my-5" cover></v-img>
        </v-card-text>
      </v-card>

    </v-col>
    <v-col cols="12" md="6" class="d-none d-md-flex">
      <v-img :src="require('@/assets/support-new-login.svg')" class="mx-auto" width="100%"></v-img>
    </v-col>
  </v-row>

</template>

<script>
import utiles from '@/modules/Shared/utiles/utiles';
import { mapMutations, mapActions, mapState } from 'vuex'

export default {
  computed:
  {
    ...mapState(['user']),
    ...mapState('login', ['errorLogin'])
  },
  methods: {
    ...mapMutations('login', ['switchComponent']),
    ...mapActions('login', ['login']),
    redirectToMicrosoft() {
      window.location.href = utiles.state.api + 'auth/microsoft'
    }
  }
}
</script>