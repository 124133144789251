import Module from './Module.vue';
const moduleRoute = {
    path: "/configuracion",
    component: Module,
    children: [
      {
        path: "", 
        component: () => import('./views/ConfiguracionView.vue'),
        name: "configuracion",
        meta: {
          requiresAuth: true,
          title: "Configuración",
        },
      }, 
      {
        path: "agentes",
        component: () => import('./views/ConfiguracionView.vue'),
        children: [
          {
            path: "",
            components: {
              centerView: () => import('./components/configAgentes.vue'),
            },
            name: "configAgentes",
            meta: {
              requiresAuth: true,
              title: "Configuración - Agentes",
            },
          }
        ],
      },
      {
        path: "roles",
        component: () => import('./views/ConfiguracionView.vue'),
        children: [
          {
            path: "",
            components: {
              centerView: () => import('@/modules/Usuarios/components/Roles/ListaRoles.vue'),
            },
            name: "configRoles",
            meta: {
              requiresAuth: true,
              title: "Configuración - Roles",
            },
          }
        ],
      },
      {
        path: "tipos",
        component: () => import('./views/ConfiguracionView.vue'),
        children: [
          {
            path: "",
            components: {
              centerView: () => import('./components/configTipos.vue'),
            },
            name: "configTipos",
            meta: {
              requiresAuth: true,
              title: "Configuración - Tipos",
            },
          }
        ],
      },
      {
        path: "categorias",
        component: () => import('./views/ConfiguracionView.vue'),
        children: [
          {
            path: "",
            components: {
              centerView: () => import('./components/configCategorias.vue'),
            },
            name: "configCategorias",
            meta: {
              requiresAuth: true,
              title: "Configuración - Categorias",
            },
          }
        ],
      },
      {
        path: "subcategorias",
        component: () => import('./views/ConfiguracionView.vue'),
        children: [
          {
            path: "",
            components: {
              centerView: () => import('./components/configSubCategoria.vue'),
            },
            name: "configSubCategorias",
            meta: {
              requiresAuth: true,
              title: "Configuración - SubCategorias",
            },
          }
        ],
      },
      {
        path: "estados",
        component: () => import('./views/ConfiguracionView.vue'),
        children: [
          {
            path: "",
            components: {
              centerView: () => import('./components/configEstados.vue'),
            },
            name: "configEstados",
            meta: {
              requiresAuth: true,
              title: "Configuración - Estados",
            },
          }
        ],
      },
      {
        path: "prioridades",
        component: () => import('./views/ConfiguracionView.vue'),
        children: [
          {
            path: "",
            components: {
              centerView: () => import('./components/configPrioridades.vue'),
            },
            name: "configPrioridades",
            meta: {
              requiresAuth: true,
              title: "Configuración - Prioridades",
            },
          }
        ],
      },

      {
        path: "sedes",
        component: () => import('./views/ConfiguracionView.vue'),
        children: [
          {
            path: "",
            components: {
              centerView: () => import('./components/configSedes.vue'),
            },
            name: "configSedes",
            meta: {
              requiresAuth: true,
              title: "Configuración - Sedes",
            },
          }
        ],
      },


      {
        path: "notificaciones",
        component: () => import('./views/ConfiguracionView.vue'),
        children: [
          {
            path: "",
            components: {
              centerView: () => import('./components/configNotificaciones.vue'),
            },
            name: "configNotificaciones",
            meta: {
              requiresAuth: true,
              title: "Configuración - Notificaciones",
            },
          }
        ],
      },
      
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };