<template>
    <v-card elevation="0" class="bg-transparent" width="100%">
        <small> Filtros: </small>
        <div class="mt-2 ">
          <small >Buscar</small>
          <v-text-field
            variant="outlined"
            placeholder="juan, juan@correo.com"
            hide-details="auto"
            density="compact"
            bg-color="grey-lighten-5"
            clearable
            clear-icon="fa:fa-solid fa-close"
            class="compact-full-input"
          ></v-text-field>
        </div>


        <div class="my-2">
          <v-btn class="btn-system" block elevation="0">Filtrar</v-btn>

        </div>
      </v-card>


</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState("usuarios", ["tiposUsuario", "rolUsuario"]),
  },
};
</script>
