import swal from "sweetalert";
import TicketService from "../service/TicketService";

import utiles from "@/modules/Shared/utiles/utiles";
import ApiColaboradorService from "@/modules/Shared/Services/ApiColaboradorService";
import ApiEstudianteService from "@/modules/Shared/Services/ApiEstudianteService";
import store from "@/store";
import router from "@/router";
import SolicitanteService from "../service/SolicitanteService";

const TicketSV = new TicketService();
const ApiColaborador = new ApiColaboradorService();
const ApiEstudiante = new ApiEstudianteService();
const SolicitanteSV = new SolicitanteService();

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export default {
  namespaced: true,
  modules: {
    utiles,
  },
  state: {
    component: "FormNuevoTicketComponent",
    ticket: {
      otro_usuario: false,
      tipo_ticket: "",
      categoria_ticket: "",
      solicitante: {
        nombrecompleto: "",
      },
      subcategoria_id: "",
      prioridad_ticket: "",
      asunto: "",
      body: "",
      fileadjunto: [],
      celular: "",
      whatsapp: "",
      correo: "",
      sede: "",
      cc: [],
      agente: null,
      ticket_referencia: "",
      copy_ticket_users: [],
    },
    ticketDefault: {
      otro_usuario: false,
      tipo_ticket: "",
      categoria_ticket: "",
      solicitante: {
        nombrecompleto: "",
      },
      subcategoria_id: "",
      prioridad_ticket: "",
      asunto: "",
      body: "",
      fileadjunto: [],
      celular: "",
      whatsapp: "",
      correo: "",
      sede: "",
      cc: [],
      agente: null,
      ticket_referencia: "",
      copy_ticket_users: [],
    },

    ticket_referencia: null,
    notfoundreferencia: false,

    copiaCorreo: {
      email: "",
    },

    overlay: false,

    listTickets: [],
    loading: false,
    overlayConfirmTicket: false,
    loadingSolicitante: false,
    solicitantenofound: false,
    dialogCambiarCategoria: false,
    loadingSearchTicketReferencia: false,
  },

  mutations: {
    switchComponent(state, payload) {
      state.component = payload;
    },

    setTickets(state, payload) {
      state.listTickets = Object.assign({}, payload);
    },

    setTicket(state, payload) {
      state.ticket = Object.assign({}, payload);
    },

    //PARA INICIALIZAR FORMULARIO PARA TICKET CON REFERENCIA A OTRO
    setTicketWithReferencia(state, payload) {
      // Copia el estado actual del ticket
      const ticketActual = { ...state.ticket };

        // Claves a excluir
        let excludedKeys = ["updated_at", "created_at", "fin_atencion", "inicio_atencion", "cierre_confirmado", "idticket"];

        if(payload.subcategoria_id == null)
            {
                excludedKeys.push("subcategoria_id")
            }


    
      // Agrega los valores del payload al ticket
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
           if (!excludedKeys.includes(key)) {
            ticketActual[key] = payload[key];
        }
        }
      }

      //ASUNTO FORMATO
     

      // Reemplazar la segunda instancia de "REF:" por una cadena vacía
      let cadena = payload.asunto.replace(/REF:/, '');

      console.log(payload)
      // Actualiza el ticket en el estado con los valores agregados y el body limpio
      state.ticket = {
        ...ticketActual,
        body: "",
        asunto: "REF: " + cadena,
        fileadjunto: [],
        ticket_referencia: payload.codigo_ticket,
        codigo_ticket: "",
        agente: payload.agente ? payload.agente.idagente : '',
        cc: [],
        ticket_inicial_referencia : payload.ticket_inicial_referencia == null ? payload.idticket : payload.ticket_inicial_referencia,
        user_id: store.state.user.iduser
      };
      console.log(state.ticket);
    },

    initTicket(state) {
      state.ticket.celular = store.state.user.celular
        ? store.state.user.celular.toString()
        : "";
      state.ticket.whatsapp = store.state.user.whatsapp
        ? store.state.user.whatsapp.toString()
        : "";
      state.ticket.correo = store.state.user.email;
      state.ticket.user_id = store.state.user.iduser;
    },

    resetInitTicket(state) {
      state.ticket.celular = "";
      state.ticket.whatsapp = "";
      state.ticket.correo = "";
      state.ticket.solicitante["nombrecompleto"] = "";
      state.ticket.user_id = store.state.user.iduser;
      state.ticket.cc = [];
    },

    resetTicket(state) {
      state.ticket = Object.assign({}, state.ticketDefault);
      state.copiaCorreo.email = "";
    },

    changeFileInput(state, event) {
      //state.ticket.fileadjunto = event.target.files[0]
      state.ticket.fileadjunto.push(event.target.files[0]);
    },

    deleteFileUpload(state, file) {
      const index = state.ticket.fileadjunto.indexOf(file);

      state.ticket.fileadjunto.splice(index, 1);
    },

    setApiNombreCompleto(state, data) {
      let separar = data.split(" ");
      //si el nombrecompleto tiene 4 elementos: son dos apellidos y dos nombres
      if (separar.length == 4) {
        state.ticket.apellidos = separar[0] + " " + separar[1];
        state.ticket.nombres = separar[2] + " " + separar[3];
      }
      //si el nombrecompleto tiene 3 elementos: son dos apellidos y un nombre
      if (separar.length == 3) {
        state.ticket.apellidos = separar[0] + " " + separar[1];
        state.ticket.nombres = separar[2];
      }
      //si el nombrecompleto tiene mas de 4 elementos: son dos apellidos y mas de un nombre
      // solo se usara el primer nombre
      if (separar.length > 4) {
        state.ticket.apellidos = separar[0] + " " + separar[1];
        state.ticket.nombres = separar[2];
      }
    },

    setSolicitanteData(state, data) {
      state.ticket.solicitante.solicitante_id = data.iduser;
      state.ticket.solicitante.nombrecompleto =
        data.apellidos + " " + data.nombres;
      state.ticket.celular = data.celular ? data.celular.toString() : "";
      state.ticket.whatsapp = data.celular ? data.celular.toString() : "";
    },

    closeOverlayConfirmTicket(state) {
      state.overlayConfirmTicket = false;
    },

    addEmailCC(state) {
      // Verificar si la última tecla presionada fue una coma
      const lastChar = state.copiaCorreo.email.slice(-1);
      if (lastChar === ",") {
        // Eliminar la coma y agregar el correo al array ticket.cc
        const trimmedEmail = state.copiaCorreo.email.slice(0, -1).trim();
        if (trimmedEmail !== "" && isValidEmail(trimmedEmail)) {
          state.ticket.cc.push(trimmedEmail);

          // Limpiar el campo de correo electrónico después de agregarlo al array
          state.copiaCorreo.email = null;
        }
      }
    },

    removeCC(state, index) {
      if (index !== -1) {
        // Verificar que el índice es válido (-1 indica que el elemento no se encontró)
        state.ticket.cc.splice(index, 1);
      }
    },

    setTicketReferencia(state, payload) {
      state.ticket_referencia = Object.assign({}, payload);
      delete state.ticket_referencia['estado_ticket']
    },

    resetTicketReferencia(state, payload)
    {
        state.ticket_referencia = null
    }
  },

  actions: {
    async getTicketsAxios({ commit, state }) {
      state.loading = true;
      try {
        const data = await TicketSV.getAll();
        commit("setTickets", data);
        state.loading = false;
      } catch (error) {
        console.log(error);
        commit("utiles/alerError");
        state.loading = false;
      }
    },

    async createTicketAxios({ commit, dispatch, state }) {
      //commit('utiles/alertLoading')
      state.overlay = true;
      try {
        //Eliminar key agente si no se ha seleccionado uno al inicio
        if (state.ticket.agente === null) {
          delete state.ticket.agente;
        }


        const formdata = new FormData();
        for (let key in state.ticket) {
          if (key === "fileadjunto") {
            const files = state.ticket[key];
            if (!files) {
              continue;
            }
            for (let i = 0; i < files.length; i++) {
              formdata.append("fileadjunto[]", files[i]);
            }
            continue;
          }

          if (key == "solicitante") {
            formdata.append(key, JSON.stringify(state.ticket[key]));
            continue;
          }

          if (key == "copy_ticket_users") {
            state.ticket[key].forEach(email => {
              formdata.append("copy_ticket_users[]", email);
            });
            continue;
          }

          formdata.append(key, state.ticket[key]);
        }

        const data = await TicketSV.createTicket(formdata);
        commit("resetTicket");
        state.overlayConfirmTicket = true;
        router.push({ name: "tickets" });
        state.overlay = false;
      } catch (error) {
        console.log(error);
        commit("utiles/alertError");
        state.overlay = false;
      }
    },

    async getColaborador({ commit, state }) {
      try {
        const data = await ApiColaborador.get(state.ticket.num_doc);
        commit("setApiNombreCompleto", data.nombrecompleto);
        state.ticket.cargo = data.cargo;
        state.ticket.correo = data.correo;
        state.ticket.telefono = data.telefono;
      } catch (error) {
        console.log(error);
      }
    },

    async getEstudiante({ commit, state }) {
      try {
        const data = await ApiEstudiante.get(state.ticket.num_doc);
        commit("setApiNombreCompleto", data.result.nomalu);
        state.ticket.carrera = data.result.specialtyName;
        state.ticket.correo = data.result.cDni + "@estudiante.cetemin.edu.pe";
      } catch (error) {
        console.log(error);
      }
    },

    async getUsuarioSolicitante({ commit, state }) {
      state.loadingSolicitante = true;

      if (state.ticket.correo == "") {
        state.loadingSolicitante = false;
        return;
      }
      try {
        const data = await SolicitanteSV.get(state.ticket.correo);
        commit("setSolicitanteData", data);
        state.loadingSolicitante = false;
        state.solicitantenofound = false;
      } catch (error) {
        console.log(error);
        state.loadingSolicitante = false;
        state.solicitantenofound = true;
        commit("resetInitTicket");
      }
    },

    async getTicketReferencia({ state, commit, dispatch }, ref) {
      state.loadingSearchTicketReferencia = true;
      state.notfoundreferencia = false;
      try {
        const data = await TicketSV.showTicket(ref);
        commit("setTicketReferencia", data);
        state.loadingSearchTicketReferencia = false;
        state.notfoundreferencia = false;
      } catch (error) {
        console.log(error);
        state.loadingSearchTicketReferencia = false;
        state.ticket_referencia = null;
        state.notfoundreferencia = true;
      }
    },
  },
};
