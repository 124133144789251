import axios from "axios";

export default class ConfirmRegistrationService {
  constructor() {
    this.apiColaboradores = axios.create({
      baseURL: "https://ctm-colaboradores.cetemin.edu.pe/api/v1/colaborador/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer 3|G7fWXbLjroLuJdQCaa7IP1DJewgnCHNLSb4tIs9a",
      },
    });

    this.apiEstudiantes = axios.create({
      baseURL:
        "https://api-estudiantes.cetemin.edu.pe/api/",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer 1|FHwV5zZDcRuaGoUmWUuwWTmrdDgh4b05X6Cep7Kq5a64d070",
      },
    });

    this.domainsAccepts = [
      "estudiante.cetemin.edu.pe",
      "cetemin.edu.pe",
      "ciemam.com",
      "enter.edu.pe",
      "estudiantes.cetemin.edu.pe",
    ];
  }

  validateDomain(email) {
    const containDomain = this.domainsAccepts.some((domain) =>
      email.includes("@" + domain)
    );
    return containDomain;
  }

  getDomainEmail(email) {
    const match = email.match(/@([^.]+)\./);

    if (match) {
      return match[1];
    }

    return null;
  }

  getDni(email) {
    const partes = email.split("@");
    if (partes.length === 2) {
      return partes[0];
    } else {
      return null; // Si el formato no coincide, puedes manejarlo de esta manera
    }
  }

  async findDataUser(email, password) {
    if (!this.validateDomain(email)) {
      throw new Error("not-found");
    }

    const domainEmail = this.getDomainEmail(email);

    if (
      (domainEmail && (domainEmail.includes("estudiante")) ||
      domainEmail.includes("estudiantes"))
    ) {
      let dni;
      let caracter = email.charAt(0)

      if (domainEmail === 'estudiante' && caracter != 'e') {
        dni = this.getDni(email);
      } else {
        dni = password;
      }

      const student = await this.getDataStudent(dni);
      if (student && parseInt(student.numero_documento) === parseInt(password)) {
        return this.parsingDataFull(student);
      }

      throw new Error("not-password-register-student");
    }

    if (domainEmail && !domainEmail.includes("estudiante")) {
      const collaborator = await this.getDataColaboradorDni(password);
      if (collaborator && collaborator.documento.toString() === password) {
        return this.parsingDataFull(collaborator);
      }

      return this.parsingDataFull(collaborator);
    }

    throw new Error("not-password-register-collaborator");
  }

  async getDataStudent(dni) {
    const response = await this.apiEstudiantes.get("/get-api-for-dni?dni=" + dni);
    console.log(response.data)
    return response.data;
  }

  async getDataColaboradorEmail(email) {
    const response = await this.apiColaboradores.get("/email/" + email);
    return response.data;
  }

  async getDataColaboradorDni(dni) {
    const response = await this.apiColaboradores.get(dni);
    return response.data;
  }

  parsingDataFull(data) {
    let response = {
      numero_documento: "",
      apellidos: "",
      nombres: "",
      cargo: "",
      area: "",
      carrera: "",
      admision: "",
      celular: "",
      email: "",
      tipouser: "",
      password: "",
    };
    response = Object.assign({}, data);
    const namefull = this.separarNameFull(
      data.nombrecompleto ||
        data.apellido_paterno + " " + data.apellido_materno + " " + data.nombres
    );
    response.apellidos = namefull.apellidos;
    response.nombres = namefull.nombres;
    response.numero_documento = data.documento || data.numero_documento || "";
    response.carrera = data.carrera ? data.carrera : "";
    response.tipouser = data.carrera ? "estudiante" : "colaborador";
    response.email = (
      data.correo ||
      data.correo_institucional ||
      data.numero_documento + "@estudiante.cetemin.edu.pe"
    ).toLowerCase();
    response.celular = data.telefono || data.celular_personal || "";
    response.admision = data.carrera
      ? data.ano_admision + "-" + data.periodo_acedemico
      : "";
    response.password = data.documento || data.numero_documento || "";
    return response;
  }

  separarNameFull(namefull) {
    let apellidos, nombres;
    let separar = namefull.split(" ");
    //si el nombrecompleto tiene 4 elementos: son dos apellidos y dos nombres
    if (separar.length == 4) {
      apellidos = separar[0] + " " + separar[1];
      nombres = separar[2] + " " + separar[3];
    }
    //si el nombrecompleto tiene 3 elementos: son dos apellidos y un nombre
    if (separar.length == 3) {
      apellidos = separar[0] + " " + separar[1];
      nombres = separar[2];
    }
    //si el nombrecompleto tiene mas de 4 elementos: son dos apellidos y mas de un nombre
    // solo se usara el primer nombre
    if (separar.length > 4) {
      apellidos = separar[0] + " " + separar[1];
      nombres = separar[2];
    }

    return { apellidos, nombres };
  }
}
