import Swal from "sweetalert2"
import utiles from "@/modules/Shared/utiles/utiles";
import EstadoService from "../services/EstadoService";

const EstadoSV = new EstadoService();

export default {
    namespaced: true,
    modules:{
        utiles
    },
    state:{
        estado:{
            nombre:"",
            descripcion:"",
            color: "",
            icon: "",
            isCierre: false,
            isAnulado:false
        },
        estadoDefault:{
            nombre:"",
            descripcion:"",
            color: "",
            icon: "",
            isCierre: false,
            isAnulado:false
        },

        loading: false,
        listEstados: []
    },
    mutations:{
        setEstados(state, payload) {
            state.listEstados = payload
        },

        editEstado(state, estado) {
            state.estado = Object.assign({}, estado)
        },

        resetEstado(state) {
            state.estado = Object.assign({}, state.estadoDefault)
        }
    },
    actions:{
        async getEstadosAxios({ commit, state }) {
            state.loading = true
            try {
                const data = await EstadoSV.getAll()
                commit('setEstados', data)
                state.loading = false
            } catch (error) {
                commit('utiles/alertError')
                state.loading = false
            }
        },


        async createEstadosAxios({ commit, dispatch, state }) {
            commit('utiles/alertLoading')
            try {
                const data = await EstadoSV.createEstado(state.estado)
                commit('utiles/alertConfirm', "Se creó un nuevo estado")
                dispatch('getEstadosAxios')
                commit('resetEstado')
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },


        async updateEstadoAxios({ commit, dispatch, state }) {
            commit('utiles/alertLoading')
            try {
                const data = await EstadoSV.updateEstado(state.estado)
                commit('utiles/alertConfirm', "Se actualizó el estado")
                dispatch('getEstadosAxios')
                commit('resetEstado')
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        },


        async deleteEstadoAxios({ commit, dispatch, state }, idestado) {

            const confirm = await Swal.fire({
                title: "¿Desea eliminar este elemento?",
                icon: "question",
                cancelButtonText: "cancelar",
                confirmButtonText: "CONFIRMAR",
                showCancelButton: true,
            })
            if (!confirm.isConfirmed) { return }
            commit('utiles/alertLoading')
            try {
                await EstadoSV.deleteEstado(idestado);
                commit('utiles/alertConfirm', "Se eliminó el estado")
                dispatch('getEstadosAxios')
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        }
    }
}