import store from '@/store';
import { createMongoAbility, subject } from '@casl/ability';

const ability = createMongoAbility();

store.subscribe((mutation) => {
    switch (mutation.type) {
        case "login/setUser":
            try {
                
                const permisos = mutation.payload.role_user.permisos_user.map(perm =>({
                    subject: perm.suject,
                    action: perm.actions.split(',')
                }));
                ability.update(permisos)

            } catch (error) {
                console.error("Error al actualizar permisos: ", error)
            }

            break;

        case "login/logout":
            ability.update([{ action: "read", subject: "login" }]);
            break;
    }
})

export default ability;

