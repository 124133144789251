<template>
  <div class="font-weight-bold" >
    Filtros
  </div>
  <div class="py-2" v-if="!visitor">
    <small for="">Agentes</small>
    <v-select density="compact" hide-details="auto" variant="outlined" class="compact-full-input bg-white" placeholder="Todos los agentes" clearable
      :items="listAgentes" item-title="user.nombres" item-value="idagente" clear-icon="fa:fa-solid fa-close" v-model="filters.agentes" multiple hide-no-data>
      <template v-slot:item="{ props, item }" chips>
        <v-list-item prepend-icon="mdi:mdi-account-tie" class="text-caption font-weight-bold" density="compact" v-bind="props" title="">{{ item.title
        }}</v-list-item>
      </template>
    </v-select>
  </div>

  <div class="py-2">
    <small for="">Tipo de ticket</small>
    <v-select density="compact" hide-details="auto" variant="outlined" class="compact-full-input bg-white" placeholder="Todos los tickets" clearable
      :items="listTipo" item-title="nombre" item-value="idtipo" clear-icon="fa:fa-solid fa-close" hide-no-data v-model="filters.tipo" >
      <template v-slot:item="{ props, item }">
        <v-list-item class="text-caption font-weight-bold" density="compact" v-bind="props" title="">{{ item.title
        }}</v-list-item>
      </template>
    </v-select>
  </div>

  <div class="py-2">
    <small for="">Prioridad</small>
    <v-select density="compact" hide-details="auto" variant="outlined" class="compact-full-input bg-white" placeholder="Todos las prioridades" clearable
      :items="listPrioridad" item-title="nombre" item-value="idprioridad" clear-icon="fa:fa-solid fa-close" v-model="filters.prioridad" hide-no-data>
      <template v-slot:item="{ props, item }">
        <v-list-item class="text-caption font-weight-bold" density="compact" v-bind="props" title="">{{ item.title
        }}</v-list-item>
      </template>
    </v-select>
  </div>

  <div class="py-2">
    <small for="">Categoria</small>
    <v-select density="compact" hide-details="auto" variant="outlined" class="compact-full-input bg-white" placeholder="Todos las categorias" clearable
      :items="listCategorias" item-title="nombre" item-value="idcategoria" clear-icon="fa:fa-solid fa-close" v-model="filters.categoria " hide-no-data>
      <template v-slot:item="{ props, item }">
        <v-list-item class="text-caption font-weight-bold" density="compact" v-bind="props" title="">{{ item.title
        }}</v-list-item>
      </template>
    </v-select>
  </div>


  
  <div class="py-2">
    <small for="">Estado</small>
    <v-select density="compact" hide-details="auto" variant="outlined" class="compact-full-input bg-white" placeholder="Todos las categorias" clearable
      :items="listEstados" item-title="nombre" item-value="idestado" clear-icon="fa:fa-solid fa-close" v-model="filters.estado " hide-no-data>
      <template v-slot:item="{ props, item }">
        <v-list-item class="text-caption font-weight-bold" density="compact" v-bind="props" title="">{{ item.title
        }}</v-list-item>
      </template>
    </v-select>
  </div>


  <div class="py-2">
    <small for="">Fecha</small>
    <v-text-field type="date" density="compact" hide-details="auto" variant="outlined" class="compact-full-input bg-white" placeholder="Hoy" 
    clearable clear-icon="fa:fa-solid fa-close" v-model="filters.fecha">
    </v-text-field >
  </div>


  <div class="py-2" v-if="!visitor">
    <small for="">Contacto</small>
    <v-text-field type="text" density="compact" hide-details="auto" variant="outlined" class="compact-full-input bg-white" 
    placeholder="DNI - nombre o apellidos" clearable clear-icon="fa:fa-solid fa-close" v-model="filters.dninombre">
    </v-text-field >
  </div>

  <div class="py-2" v-if="!visitor">
    <small for="">Tipo persona</small>
    <v-select density="compact" hide-details="auto" variant="outlined" class="compact-full-input bg-white" placeholder="Todas" clearable
      :items="['COLABORADOR', 'ESTUDIANTE']" clear-icon="fa:fa-solid fa-close" v-model="filters.tiposolicitante" hide-no-data>
      <template v-slot:item="{ props, item }">
        <v-list-item class="text-caption font-weight-bold" density="compact" v-bind="props" title="">{{ item.title
        }}</v-list-item>
      </template>
    </v-select>
  </div>

  <v-btn class="btn-system mt-2" block elevation="0" @click="getTicketsFilter()"> 
      FILTRAR
  </v-btn>

</template>


<script>
import { mapState, mapActions } from 'vuex';


export default {

  props:
  {
    visitor:{
      type: Boolean,
      default: false
    }
  },
  computed:{
    ...mapState(['user']),
    ...mapState("tickets", ["filters"]),
    ...mapState("configuracion/tipo", ["listTipo"]),
    ...mapState("configuracion/categoria", ["listCategorias", "loading"]),
    ...mapState("configuracion/prioridad", ["listPrioridad"]),
    ...mapState("configuracion/estado", ["listEstados"]), 
    ...mapState("configuracion/agente", ['listAgentes'])
  },
  methods:{
    ...mapActions("tickets", ["getTicketsAxios"]),

    getTicketsFilter()
    {
      if(this.visitor)
      {
        this.getTicketsAxios({loading: true, iduser: this.user.iduser, page: 1})
      }else
      {
        this.getTicketsAxios({loading: true, page:1}); 
      }
    }
  }
}
</script>