<template>
  <v-card elevation="0">
    <v-card-text>
      <v-data-table :headers="headers" :items="listaUsuarios" density="comfortable" :items-per-page="100">
        <template v-slot:top>
            <div v-if="loadingbuscar">
                <v-progress-linear indeterminate />
            </div>
        </template>
        <template v-slot:item="{ item, index }">
          <tr :class="!item.isActive? 'disableUser' : ''" >
            <td>
              <div class="text-body-2">{{ index + 1 }}</div>
            </td>
            <td>
              <div class="text-body-2">{{ item.numero_documento }}</div>
            </td>
            <td>
              <div class="text-body-2">{{ capitalizeWords(item.nombres) }}</div>
            </td>
            <td>
              <div class="text-body-2">{{ capitalizeWords(item.apellidos) }}</div>
            </td>
            <td>
              <div class="text-body-2">{{ item.email }}</div>
            </td>
            <td> 
              <v-chip v-if="item.tipouser == 'colaborador'" label size="small" class="bg-blue-grey-darken-4 text-white" style="font-size: 12px !important;">{{ item.tipouser.toUpperCase() }}</v-chip>
              <v-chip v-if="item.tipouser == 'estudiante'" label size="small" class="bg-indigo text-white" style="font-size: 12px !important;">{{ item.tipouser.toUpperCase() }}</v-chip>
            </td>
            <td> <small class=" d-inline-block text-truncate" style="max-width: 250px;">{{ item.cargo || item.carrera }}</small></td>
            <td> <small class=" d-inline-block text-truncate" style="max-width: 150px;">{{ item.area }}</small></td>


              <td><v-chip label class="bg-teal-accent-4 text-white"><small style="line-height: 1;"
                  class="font-weight-bold">{{ item.role_user.name }}</small></v-chip></td>
            <td>
              <v-btn :disabled="!item.isActive" @click="openEditUser(item)" class="mr-2" size="x-small" variant="tonal" icon rounded="lg"
                color="green accent-4"><v-icon icon="fa:fa-solid fa-pen-to-square"></v-icon></v-btn>

              <v-btn v-if="item.isActive" @click="switchActiveUser(item.iduser)" size="x-small" variant="tonal" icon
                rounded="lg" color="blue-grey darken-4"><v-icon icon="fa:fa-solid fa-toggle-on"></v-icon></v-btn>

              <v-btn v-else @click="switchActiveUser(item.iduser)" size="x-small" variant="tonal" icon rounded="lg"
                color="blue-grey darken-4">
                <v-icon icon="fa:fa-solid fa-toggle-off"></v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { shallowRef } from "vue";

import { mapActions, mapMutations, mapState } from "vuex";
import RegistrarUserComponent from "./RegistrarUserComponent.vue";
import Filters from "@/modules/Shared/Filters";

export default {
  components: {
    
  },
  mixins: [Filters],
  props: {

  },
  data() {
    return {
      page: 0,
      headers: [
        { title: "N°", value: "index", width: "90px" },
        { title: "DNI", value: "numero_documento",width: "100px" },
        { title: "Nombres", value: "nombres", width: 150 },
        { title: "Apellidos", value: "apellidos", width: 200 },
        { title: "Email", value: "email", width: 300 },
        { title: "Tipo", value: "Tipo", width: "200px" },
        { title: "Cargo/Carrera", value: "cargo", width: "300px"  },
        { title: "Área", value: "area", width: "200px"  },
        { title: "Rol", value: "role_user", width: "200px" },
        { title: "", value: "actions", width: "200px" },
      ],
    };
  },

  computed: {
    ...mapState(["silderMultipurpose"]),
    ...mapState('usuarios', ["listaUsuarios", 'loadingbuscar'])
  },

  methods: {
    ...mapMutations("usuarios", ["editUser", "resetUser"]),
    ...mapMutations(["changeSilderMulti", "changeComponentDynamic"]),
    ...mapActions("usuarios", ['getAllUser', 'switchActiveUser']),

    openEditUser(item) { 
      this.resetUser()
      const component = shallowRef(RegistrarUserComponent)
      this.changeComponentDynamic({ componentName: component })
      this.changeSilderMulti()
      this.editUser(item)
      
    },





  },

  mounted() {
    this.getAllUser();
  }
};
</script>


<style scoped>
.disableUser{
  opacity: 0.60 !important;
  filter: grayscale(100%) !important;
}
</style>