<template>
    <v-card v-if="!ticket.cierre_confirmado" elevation="6" class="bg-white" variant="outlined"
        style="border: 1px solid #cfd7df !important; opacity: 1 !important;">
        <v-card-title class="text-subtitle-2 bgLight2Theme">Confirmación de Solución </v-card-title>
        <v-card-text>
            <div class="py-2">Haga clic aquí para indicar que su problema ha sido resuelto. Su feedback es importante para nosotros</div>
            <v-btn  @click="confirmarTicket(ticket.idticket)" :loading="loadingConfirmResult" class="bg-green-accent-3 text-white" block elevation="0">CONFIRMAR SOLUCIÓN</v-btn>

            <!--<div class="mt-3">
                <small class="d-block text-center font-weight-medium">¿No considera que el ticket ha sido resuelto? </small>
                <small>Si necesita más ayuda o tiene otro problema, por favor</small>
                <v-btn :to="{ name: 'nuevoticket' }"  variant="text" density="compact" block color="blue-accent-4" class="mt-3"><small>Crear nuevo ticket</small></v-btn>
            </div>-->
        </v-card-text>
        <v-divider></v-divider>
    </v-card>

    <v-card v-else elevation="6" class="bg-white" variant="outlined"
        style="border: 1px solid #cfd7df !important; opacity: 1 !important;">
        <v-card-title class="text-subtitle-2 bg-green-accent-4 text-white">¡Ticket Resuelto! </v-card-title>
        <v-card-text>
            <div v-if="isRoleVisitor()" class="py-2">Gracias por confirmar que el ticket ha sido resuelto. Si tienes más preguntas o necesitas ayuda, no dudes en crear un nuevo ticket.</div>
            <div v-else class="py-2">Se confirmó la solución del ticket</div>
           <!--<v-btn :to="{ name: 'nuevoticket' }"  variant="text" density="compact" block color="blue-accent-4" class="mt-3"><small>Crear nuevo ticket</small></v-btn>--> 
        </v-card-text>
        <v-divider></v-divider>
    </v-card>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapActions, mapState } from 'vuex';

export default{
    mixins: [Filters],
    props:
    {
      ticket:{
        type: Object
      }
    },
    computed:
    {
        ...mapState('tickets', ['loadingConfirmResult'])
    },
    methods:{
        ...mapActions('tickets', ['confirmarTicket'])
    }
}
</script>