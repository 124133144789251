import utiles from "@/modules/Shared/utiles/utiles";
import notificacionService from "../services/NotificacionService"
import store from "@/store";

const notificacionSv = new notificacionService;

export default
{
    namespaced: true,
    modules:{
        utiles
    },
    state:{
        notificaciones:
        {
            user_confirma_envio:true,
            user_notifica_cierre_anula:true
        },

        notificacionesPersonales:
        {
            notificacionNewTicket: true,
            notificacionRespuestaTicket: true,
            notificacionCierreTicket: true,
            iduser: 0
        },

        loading: false,
        loadingoverlay:false
    },


    mutations:
    {
        setConfigNotification(state, payload)
        {
            state.notificaciones = Object.assign({}, payload.configuracionGeneral)
            state.notificacionesPersonales = Object.assign({}, payload.configuracionPersonal)

        },


    },

    actions:
    {
        async getConfigNotify({commit, state})
        {
            state.loadingoverlay = true
            try
            {
                const data = await notificacionSv.getAll();
                commit('setConfigNotification', data);
                state.loadingoverlay = false

            }
            catch(error)
            {
                console.log(error)
                state.loadingoverlay = false

            }
        },

        async updateConfigNotify({commit, state})
        {
            state.loading = true;
            try
            {
                await notificacionSv.updateConfigNotification(state.notificaciones);
                state.loading = false
            }
            catch(error)
            {
                console.log(error)
                state.loading = false
                commit('utiles/alertError')
            }
        },

        async updateConfigNotificationPersonal({commit, state})
        {
            state.loading = true;
            try
            {
                state.notificacionesPersonales.iduser = store.state.user.iduser;
                await notificacionSv.updateConfigNotificationPersonal(state.notificacionesPersonales);
                state.loading = false
            }
            catch(error)
            {
                console.log(error)
                state.loading = false
                commit('utiles/alertError')
            }
        }

    }
}