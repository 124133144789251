<template>
    <v-card rounded="0" elevation="3" class="bgLight2Theme" outlined>
        <v-col cols="12" md="10" lg="9" class="mx-auto">

            <v-btn :to="{ name: 'nuevoticket' }" class="btn-system mr-2" size="small" elevation="0">
                <v-icon start size="x-small">fa:fa-solid fa-plus</v-icon>
                <small>NUEVO TICKET</small></v-btn>

        </v-col>
    </v-card>
    <v-col cols="12" md="8" xl="7" class="mx-auto bg-white rounded-lg">
        <v-card class="bg-transparent" elevation="0">
            <v-card-text>
                <v-img width="100" class="mx-auto" :src="require('@/modules/Tickets/assets/buscar-documento.png')"></v-img>
                <div class="text-h5 text-center pa-4 mt-3">Buscar ticket</div>
                <div class="text-center">Para buscar un ticket, ingrese su DNI y código de ticket.</div>
            </v-card-text>
            <v-col cols="12" md="8" lg="6" class="mx-auto pb-12">
                <v-row>
                    <v-col cols="12" md="6">
                        <div>
                            <label for="" class="font-weight-medium text-subtitle-2">N. de documento de indentidad</label>
                            <v-text-field variant="outlined" hide-details="auto" density="compact" bg-color="grey-lighten-5"
                                class="mt-2" v-model="buscarticket.dni"></v-text-field>
                        </div>
                    </v-col>

                    <v-col cols="12" md="6">
                        <div>
                            <label for="" class="font-weight-medium text-subtitle-2">Código de ticket</label>
                            <v-text-field variant="outlined" hide-details="auto" density="compact" bg-color="grey-lighten-5"
                                class="mt-2" v-model="buscarticket.codeticket"></v-text-field>
                        </div>
                    </v-col>

                    <v-col cols="12">
                        <v-btn @click="findTicket()" class="btn-system" elevation="0" block>BUSCAR</v-btn>
                    </v-col>


                    <v-col cols="12">
                        <v-skeleton-loader v-if="loadingbuscar" type="article"></v-skeleton-loader>
                        <div v-if="notfind" class="text-subtitle-2 text-center">
                            No se encontró el ticket
                        </div>
                        <v-card v-if="ticket" style="border: 1px solid #cfd7df;" elevation="0">
                            <v-list-item class="py-3">
                                <v-list-item-title v-text="ticket.asunto" class="text-caption font-weight-bold pb-2"></v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-chip label size="small" class="font-weight-bold mr-2">
                                        {{  oneName(ticket.solicitante.nombres) + ' ' + oneName(ticket.solicitante.apellidos) }}
                                    </v-chip>
                                    <v-chip label size="small" class="font-weight-bold">
                                        {{  fechasmall("DD [de] MMMM [de] YYYY", ticket.created_at ) }}
                                    </v-chip>
                                </v-list-item-subtitle>
                                <template v-slot:append>
                                    <v-btn @click="openTicket(ticket)" color="grey-lighten-1" icon="fa:fa-solid fa-circle-right" variant="text"></v-btn>
                                </template>
                            </v-list-item>
                        </v-card>
                    </v-col>

                </v-row>
            </v-col>
        </v-card>
    </v-col>
</template>


<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Filters from '@/modules/Shared/Filters';

export default {
    mixins: [Filters],
    components: {
        
    },
    computed: {
        ...mapState('tickets', ['buscarticket', 'notfind', 'loadingbuscar', 'ticket'])
    },
    methods: {
        ...mapActions('tickets', ['findTicket']),
        ...mapMutations('tickets', ['resetBusquedaTicket', 'resetTicket']),
        openTicket(ticket)
        {
            this.$router.push({name: 'visualizeTicket', params:{idticket: ticket.codigo_ticket}})
        }
    },

    mounted()
    {
        this.resetBusquedaTicket();
        this.resetTicket();
    }
}
</script>