<template>
  <v-card elevation="0">
    <v-card-text>
      <div>
        <div class="font-weight-medium text-subtitle-1 text-md-h6 text-left font-weight-bold">
          Registrar un nuevo Ticket 📝
        </div>
        <div class="text-caption text-md-body-2 text-left">
          Completa y envía el siguiente formulario, para poder recibir
          atención de parte de nuestro equipo.
        </div>
      </div>

    </v-card-text>
  </v-card>

  <v-row>
    <v-col cols="12" v-if="loadingSearchTicketReferencia" class="py-8">
      <v-row>
        <v-col class="text-subtitle-1 text-center text-blue-grey-lighten-1 text-center" cols="12">
          Cargando ticket de referencia
        </v-col>
        <v-col cols="6" class="mx-auto py-1">
          <v-progress-linear color="deep-purple-accent-4" height="6" indeterminate rounded></v-progress-linear>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" v-if="notfoundreferencia">
      <div style="border-top: 3px dotted #C62828; border-bottom: 3px dotted #C62828;"
        class="pa-2 py-4 text-subtitle-2 bg-red-lighten-4 text-red-darken-3">Ticket de referencia no encontrado</div>
    </v-col>

    <v-col cols="12" v-if="ticket_referencia"
      class="bg-grey-lighten-3 d-flex flex-column align-center justify-center mt-3">
      <div class="pa-3 text-caption text-center text-blue-grey-lighten-1">
        Ticket al que hace referencia
      </div>
      <div class="stamp">
        <v-card elevation="0">
          <v-card-text class="d-flex align-center px-1">
            <div :style="$vuetify.display.lgAndDown ? 'width: 20%;' : 'width: 90px'"
              class="d-flex align-center justify-center">
              <div>
                <div class="font-weight-bold text-h6 text-center">{{ formatFechaCustom(ticket_referencia.created_at,
                  'DD') }}</div>
                <div class="text-caption">{{ formatFechaCustom(ticket_referencia.created_at, 'MMMM / YY') }}</div>
              </div>
            </div>
            <div :style="$vuetify.display.lgAndDown ? 'width: 80%;' : 'width:710px'">
              <div class="text-subtitle-2">{{ ticket_referencia.asunto }}</div>
              <v-chip label size="x-small" class="mr-1">{{ ticket_referencia.categoria.nombre }}</v-chip>
              <v-chip label size="x-small">{{ ticket_referencia.prioridad.nombre }}</v-chip>
              <div class="text-caption text-blue-grey-darken-1 pt-2">Creado por: {{
                capitalizeWords(ticket_referencia.user.nombres) + ' ' +
                capitalizeWords(ticket_referencia.user.apellidos) }}</div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>

  <v-card elevation="0" class="mt-3">
    <v-form v-model="formValidate" ref="formTicket">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between">

            <div class="font-weight-medium text-subtitle-2">
              DATOS DE CONTACTO
            </div>

            <div class="d-flex align-center px-4" v-if="!this.isRoleVisitor()">
              <div class="text-black font-weight-bold mr-4">Mi ticket</div>
              <v-switch density="compact" class="ma-0" :hide-details="true" color="indigo" v-model="ticket.otro_usuario">
                <template v-slot:label="">
                </template>
              </v-switch>
              <div class="text-black font-weight-bold ml-2">Otro usuario</div>
            </div>

          </v-col>

          <v-col cols="12" class="py-0" v-if="solicitantenofound">
            <v-alert text="Usuario no encontrado" type="error" variant="tonal" closable density="compact"></v-alert>
          </v-col>
          <v-col cols="12" md="6" v-if="!ticket.otro_usuario">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Correo</label>
              <small class="d-block">Correo personal o institucional</small>
              <v-text-field type="email"  variant="outlined" :hide-details="true" density="compact"
                bg-color="grey-lighten-5" class="mt-2" :rules="[rules.required, rules.email]"
                v-model="ticket.correo" ref="correoOtroUsuario" name="correoOtroUsuario"></v-text-field>
                <b v-if="!ticket.correo" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
                <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>

          <v-col cols="12" md="6" v-else>
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Buscar usuario</label>
              <small class="d-block">busca el usuario solicitante por correo</small>
              <v-autocomplete type="email" variant="outlined" :hide-details="true" density="compact"
                bg-color="grey-lighten-5" ref="correo" name="correo" class="mt-2" :rules="[rules.required, rules.email]" v-model="ticket.correo"
                :items="listaUsuarios" chips item-title="email" item-value="email" auto-select-first>
                <template v-slot:append>
                  <v-icon style="cursor: pointer;" @click="getUsuarioSolicitante()">{{ loadingSolicitante ?
                    'fa:fa-solid fa-spinner fa-spin' : 'mdi: mdi-account-search' }}</v-icon>
                </template>
              </v-autocomplete>
                <b v-if="!ticket.correo" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
                <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>

          <v-col cols="12" md="6" v-if="ticket.otro_usuario">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Nombre completo</label>
              <small class="d-block">Datos del solicitante</small>
              <v-text-field readonly ref="nombreCompleto" name="nombreCompleto" variant="outlined" :hide-details="true" density="compact" bg-color="grey-lighten-5"
                class="mt-2" :rules="[rules.required]" v-model="ticket.solicitante.nombrecompleto"></v-text-field>
                <b v-if="!ticket.solicitante.nombrecompleto" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
                <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>


          <v-col cols="12" md="6">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Telefono de contacto</label>
              <small class="d-block">Número activo para llamada</small>
              <v-text-field variant="outlined" ref="telefono" name="telefono" :hide-details="true" density="compact" bg-color="grey-lighten-5"
                class="mt-2" :rules="[rules.required, rules.celular]" v-model="ticket.celular"
                prepend-inner-icon="fa:fa-solid fa-mobile-screen"></v-text-field>
                <b v-if="!ticket.celular" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
                <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Whatsapp </label>
              <small class="d-block">Número para mensajes de whatsapp</small>
              <v-text-field variant="outlined"  ref="whatsapp" name="whatsapp" :hide-details="true" density="compact" bg-color="grey-lighten-5"
                class="mt-2"  v-model="ticket.whatsapp"
                prepend-inner-icon="fa:fa-brands fa-whatsapp"></v-text-field>
                <b v-if="!ticket.whatsapp" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
                <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Sede </label>
              <small class="d-block">Selecciona una sede si estás de manera presencial</small>
              <v-select variant="outlined" ref="sede" name="sede" :hide-details="true" density="compact" bg-color="grey-lighten-5" class="mt-2"
                v-model="ticket.sede" :items="listSedes" item-value="abreviatura" item-title="abreviatura" hide-no-data
                :rules="[rules.required]">
                <template v-slot:selection="{ item }">
                  <span v-if="item.title == ''">Selecciona</span>
                  <div class="text-truncate">{{ item.title }}</div>
                </template>
              </v-select>
              <b v-if="!ticket.sede" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
              <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>



          <v-col cols="12" md="6" v-if="ticket.otro_usuario">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Asignar agente (Opcional)</label>
              <small class="d-block">Inicie el ticket con un agente</small>
              <v-select variant="outlined" :hide-details="true" density="compact" bg-color="grey-lighten-5" class="mt-2"
                v-model="ticket.agente" :items="listAgentes" item-value="idagente" item-title="user.nombres"
                hide-no-data>
                <template v-slot:selection="{ item }">
                  <span v-if="item.title == ''">Selecciona</span>
                  <div class="text-truncate">{{ item.title }}</div>
                </template>

              </v-select>
             
            </div>
          </v-col>


          <v-col cols="12" >
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Compartir el ticket a otros usuarios (Opcional)</label>
              <small class="d-block">Envia una copia del ticket a otros usuarios.</small>
              <v-autocomplete variant="outlined" :hide-details="true" density="compact" bg-color="grey-lighten-5" class="mt-2"
                v-model="ticket.copy_ticket_users" :items="listaUsuariosFilter" item-value="email" :item-title="(val) => oneName(val.nombres) + ' ' + oneName(val.apellidos) + ' - ' + val.email "
                hide-no-data multiple>
                <template v-slot:selection="{ item }">
                  <span v-if="item.title == ''">Selecciona</span>
                  <v-chip color="white" class="text-truncate modern-elevated text-black">
                    <v-avatar start size="20" class="bg-deep-purple-accent-3 ml-1">
                      <v-icon size="14">fa:fa-solid fa-user</v-icon>
                    </v-avatar>
                    <span class="text-black font-weight-medium" style="font-size: 12px;">{{ item.title }}</span>
                  </v-chip>
                </template>

              </v-autocomplete>
             
            </div>
          </v-col>


          <v-divider></v-divider>


          <v-col cols="12">
            <div>
              <div class="font-weight-medium text-subtitle-2">
                DETALLES DEL TICKET
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Tipo de ticket</label>
              <small class="d-block">Seleccione entre INCIDENTE o REQUERIMIENTO</small>
              <v-select variant="outlined" ref="tipo" name="tipo" :hide-details="true" density="compact" bg-color="grey-lighten-5" class="mt-2"
                :rules="[rules.required]" v-model="ticket.tipo_ticket" :items="listTipo" item-value="idtipo"
                item-title="nombre" hide-no-data>
                <template v-slot:item="{ props, item }">

                  <div>
                    <v-list-item v-bind="props"></v-list-item>
                    <v-tooltip activator="parent" location="top">
                      <div style="width: 200px;">{{ item.raw.descripcion }}</div>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-if="item.title == ''">Selecciona</span>
                  <div class="text-truncate">{{ item.title }}</div>
                </template>
              </v-select>
              <b v-if="!ticket.tipo_ticket" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
              <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>


          <v-col cols="12" md="6">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Categoria de solicitud</label>
              <small class="d-block">Seleccione una categoria</small>
              <v-select variant="outlined" ref="categoria" name="categoria" :hide-details="true" density="compact" bg-color="grey-lighten-5" class="mt-2"
                :loading="loadingcategorias" hide-no-data :rules="[rules.required]" v-model="ticket.categoria_ticket"
                :items="ticket.tipo_ticket != '' && !loadingcategorias ? listCategorias : []" item-value="idcategoria"
                item-title="nombre">
                <template v-slot:item="{ props, item }">

                  <div>
                    <v-list-item v-bind="props"></v-list-item>
                    <v-tooltip activator="parent" location="top">
                      <div style="width: 200px;">{{ item.raw.descripcion }}</div>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-if="item.title == ''">{{ loadingcategorias ? 'Cargando...' : 'Selecciona' }}</span>
                  <div class="text-truncate">{{ item.title }}</div>
                </template>
              </v-select>
              <b v-if="!ticket.categoria_ticket" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
              <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>

          <v-col cols="12" md="6" :style="listsubcategorias.length  == 0 ? 'opacity: 0.5;' : 'opacity: 1;'">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">SubCategoria de solicitud (OPCIONAL)</label>
              <small class="d-block">Seleccione una subcategoria</small>
              <v-select :disabled="listsubcategorias.length  == 0"  ref="subcategoria" name="subcategoria" variant="outlined" :hide-details="true" density="compact" bg-color="grey-lighten-5" class="mt-2"
                :loading="loadingsubcategorias" hide-no-data v-model="ticket.subcategoria_id"
                :items="ticket.categoria_ticket != '' && !loadingsubcategorias ? listsubcategorias : []"
                item-value="id_subcategoria" item-title="nombre">
                <template v-slot:item="{ props, item }">
                  <div>
                    <v-list-item v-bind="props"></v-list-item>
                    <v-tooltip activator="parent" location="top">
                      <div style="width: 200px;">{{ item.raw.descripcion }}</div>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-if="item.title == ''">{{ loadingsubcategorias ? 'Cargando...' : 'Selecciona' }}</span>
                  <div class="text-truncate">{{ item.title }}</div>
                </template>
              </v-select>
      
            </div>
          </v-col>

          <v-col cols="12" md="6" >

            <div>
              <label for="" class="font-weight-medium text-subtitle-2">Prioridad </label>
              <small class="d-block">Seleccione el nivel de prioridad </small>
              <v-select variant="outlined" ref="prioridad" name="prioridad" :hide-details="true" density="compact" bg-color="grey-lighten-5" class="mt-2"
                :rules="[rules.required]" v-model="ticket.prioridad_ticket" :items="prioridadesWithFilter"
                item-value="idprioridad" item-title="nombre" hide-no-data>
                <template v-slot:item="{ props, item }">

                  <div>
                    <v-list-item v-bind="props"></v-list-item>
                    <v-tooltip activator="parent" location="top">
                      <div style="width: 200px;">{{ item.raw.descripcion }}</div>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-if="item.title == ''">Selecciona</span>
                  <div class="text-truncate">{{ item.title }}</div>
                </template>
              </v-select>
              <b v-if="!ticket.prioridad_ticket" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
              <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>

          <v-col cols="12" v-if="prioridadObject">
            <div>
              <v-alert border="top" :border-color="prioridadObject.color" elevation="5" class="bg-white">
               {{ prioridadObject.descripcion }}
              </v-alert>
            </div>
          </v-col>

          <v-col cols="12" v-if="$route.query.ref">
            <div>
              <label for=""  class="font-weight-medium text-subtitle-2">ASUNTO</label>
              <div class="text-h6 font-weight-medium bg-grey-lighten-5 pa-1 px-3 rounded-lg"
                style="border: 1px solid #424242;">{{
                  ticket.asunto }}</div>
            </div>
          </v-col>
          <v-col cols="12" v-else>
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">ASUNTO</label>
              <v-text-field variant="outlined"  ref="asunto" name="asunto" :hide-details="true" density="compact" bg-color="grey-lighten-5"
                class="mt-2 text-h6 font-weight-medium" :rules="[rules.required]"
                v-model="ticket.asunto"></v-text-field>
                <b v-if="!ticket.asunto" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
                <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>


        </v-row>
        <v-row>

          <v-col cols="12" md="6">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">ADJUNTAR ARCHIVOS: (Opcional-solo si es
                necesario)</label>
              <small class="d-block">Puedes adjuntar uno o varios archivos: PDF, jpg, jpge, png</small>
              <v-file-input variant="outlined" :hide-details="true" density="compact" bg-color="grey-lighten-5"
                label="pdf, jpg, jpge, png" prepend-icon="fa:fa-solid fa-circle-up" class="mt-2"
                @change="changeFileInput">
                <template v-slot:selection></template>
              </v-file-input>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div v-if="ticket.fileadjunto.length > 0">Archivos cargados</div>
            <div class="pa-2">
              <div v-for="file in ticket.fileadjunto" class="ma-2">
                <v-chip label color="primary">
                  <v-icon start @click="deleteFileUpload(file)" size="x-small">fa:fa-solid fa-circle-xmark</v-icon>
                  <small class="font-weight-medium">{{ file['name'] }} </small>
                </v-chip>
              </div>
            </div>
          </v-col>


          <v-col cols="12">
            <div>
              <label for="" class="font-weight-medium text-subtitle-2">DESCRIPCIÓN</label>
              <small class="d-block">Sea breve y claro en explicar el problema</small>
              <v-textarea class="d-none" ref="descripcion" name="descripcion" :rules="[rules.required]" v-model="ticket.body">

              </v-textarea>

              <Editor :api-key="apiTynyMCE" :init="configTiny"
                v-model="ticket.body" />

                <b v-if="!ticket.body" class="text-red text-caption font-weight-bold py-2">* obligatorio</b>
                <b v-else class="text-green text-caption font-weight-bold py-2">Completado</b>
            </div>
          </v-col>
        </v-row>
        <v-row>

          <v-col cols="12">
            <v-btn @click="sendTicket()" size="large"  variant="flat" color="indigo 900"
              prepend-icon="fa:fa-regular fa-paper-plane">
              ENVIAR TICKET
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>


  <!---------------------------------------------------->
  <v-dialog v-model="dialogPrioridades" width="500">
    <v-card>
      <v-card-text>
        <v-img :src="require('@/assets/prioridades.jpeg')"></v-img>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue" block @click="dialogPrioridades = false">CERRAR</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!---------------------------------------------------->
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { mapActions, mapState, mapMutations } from "vuex";
import Filters from "@/modules/Shared/Filters"
export default {
  mixins: [Filters],
  components: {
    Editor,
  },
  data() {
    return {
      dialogPrioridades: false,
      formValidate: true,
      isFormValid: false,
      configTiny: {
        selector: "textarea#basic-example",
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "preview",
          "anchor",
          "code",
          "fullscreen",
          "table",
          "wordcount",
          "fullscreen",
          "image",
          "imagetools"
        ],
        menubar: "table",
        toolbar:
          "undo redo |  " +
          "bold italic backcolor | alignleft aligncenter  " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat preview link image",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
        fullscreen_native: false,
        automatic_uploads: true,
        file_picker_types: 'image',
        image_title: true,
        images_upload_handler: function (blobInfo, success, failure) {
          const reader = new FileReader();
          reader.onload = function () {
            const base64 = reader.result.split(',')[1];
            success('data:image/png;base64,' + base64);
          };
          reader.readAsDataURL(blobInfo.blob());
        },
      },
      prioridadObject: null,
      hiddenPrioridad: false,

      prioridadesWithFilter: []
    };
  },
  watch: {
    'ticket.tipo_ticket': function (newvalue, oldvalue) {
      if (newvalue) {
        this.ticket.categoria_ticket = ""
        this.filterCategoria.tipo_id = newvalue

        if (this.user.role_user.name == 'VISITANTE') {
          this.filterCategoria.tipo_usuario = this.user.tipouser.split(',')

        }

        this.getCategoriasAxios()
        if (this.ticket_referencia) {
          this.ticket.categoria_ticket = this.ticket_referencia.categoria_ticket
        }


        //ver que tipo de ticket es y ocultar la prioridad
       let resultadoFiltrado = this.listTipo.filter(tipo => tipo.idtipo === newvalue);
        let IsRequerimiento = resultadoFiltrado.some(tipo => tipo.nombre === "REQUERIMIENTO");

        if(IsRequerimiento)
        {
          this.prioridadesWithFilter =  this.listPrioridad.filter(prioridad => prioridad.nombre !== 'CRITICA');

        }else
        {
          this.prioridadesWithFilter =  this.listPrioridad
        }
      }
    },

    'ticket.categoria_ticket': function (newvalue, oldvalue) {
      if (newvalue) {
        this.ticket.subcategoria_id = ""
        this.filterSubcategoria.categoria_id = newvalue

        if (this.user.role_user.name == 'VISITANTE') {
          this.filterSubcategoria.tipo_usuario = this.user.tipouser.split(',')
        }


        this.getSubCategoriasAxios()
        if (this.ticket_referencia && this.ticket_referencia.subcategoria_id) {
          this.ticket.subcategoria_id = this.ticket_referencia.subcategoria_id
        }
      }
    },

    'ticket.otro_usuario': function (newvalue) {
      if (newvalue) {
        this.resetInitTicket()
        this.ticket.correo = ''
      } else {
        this.initTicket()
      }
    },

    'ticket_referencia': function (newvalue) {
      if (newvalue) {
        this.setTicketWithReferencia(this.ticket_referencia)

      }
    },

    'ticket.correo': function (newvalue) {
      if (this.ticket.otro_usuario) {
        this.getUsuarioSolicitante()
      }
    },

    'ticket.prioridad_ticket': function (newvalue){
      if(newvalue)
      {
        let prioridadObject = this.listPrioridad.filter(p => p.idprioridad == newvalue)
         this.prioridadObject = prioridadObject[0] ?? null
   
      }
    },

    'ticket.celular': function (newval){
      
      if(newval && this.ticket.whatsapp == '')
      {
        this.ticket.whatsapp = newval
      }
    }



  },
  computed: {
    ...mapState("configuracion/tipo", ["listTipo"]),
    ...mapState("configuracion/categoria", ["listCategorias", "loadingcategorias", "filterCategoria"]),
    ...mapState("configuracion/subcategoria", ["listsubcategorias", "loadingsubcategorias", "filterSubcategoria"]),
    ...mapState("configuracion/prioridad", ["listPrioridad"]),
    ...mapState("configuracion/estado", ["listEstados"]),
    ...mapState("configuracion/sede", ["listSedes"]),
    ...mapState("configuracion/agente", ["listAgentes"]),
    ...mapState(["user"]),
    ...mapState('tickets/utiles',["apiTynyMCE"]),
    ...mapState('usuarios', ["listaUsuarios"]),
    ...mapState("tickets/registroticket", ["ticket", "solicitante", "loadingSolicitante", 'solicitantenofound', 'copiaCorreo', "ticket_referencia", "loadingSearchTicketReferencia", "notfoundreferencia"]),
    
    listaUsuariosFilter (){

      const filterUser = this.listaUsuarios.filter(elm => elm.email !== this.ticket.correo && elm.role_user.name !== 'AGENTE' && elm.role_user.name !== 'SUPERADMINISTRADOR')

      return filterUser;

    }
  
  },

  methods: {
    ...mapActions("tickets/registroticket", ["createTicketAxios", "getColaborador", "getEstudiante", "getUsuarioSolicitante", "getTicketReferencia"]),

    ...mapMutations("tickets/registroticket", ['changeFileInput', 'deleteFileUpload', 'initTicket', 'resetInitTicket', 'addEmailCC', 'removeCC', 'setTicketWithReferencia', 'resetTicketReferencia', 'resetTicket']),

    ...mapActions('configuracion/categoria', ['getCategoriasAxios']),

    ...mapActions('configuracion/subcategoria', ['getSubCategoriasAxios']),

    ...mapActions('configuracion/agente', ['getAgentes']),

    ...mapActions('usuarios', ['getAllUser']),

    getApiDNI() {
      if (this.ticket.tipo_solicitante === 'COLABORADOR') {
        this.getColaborador()
      }

      if (this.ticket.tipo_solicitante === 'ESTUDIANTE') {
        this.getEstudiante()
      }
    },

    async sendTicket()
    {
      this.$refs.formTicket.validate()
      this.isFormValid = await this.$refs.formTicket.validate();

      if (!this.isFormValid.valid) {
        console.log(this.isFormValid)
        let incompleteField = this.isFormValid.errors[0].id;
        this.scrollToField(incompleteField);
        return
      }

      this.createTicketAxios()
    },

    scrollToField(fieldName) {
      const fieldRef = this.$refs[fieldName];
      if (fieldRef) {
        fieldRef.$el.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    },


  },

  created() {
    if (this.$vuetify.display.lgAndUp) {
      this.configTiny.height = 600

    } else if (this.$vuetify.display.mdAndDown) {
      this.configTiny.height = 300
    }
  },

  mounted() {
    this.ticket.otro_usuario = false
    this.resetInitTicket()
    this.resetTicket()
    this.initTicket()
    this.getAgentes()
    this.resetTicketReferencia()
    if (this.$route.query.ref) {
      this.getTicketReferencia(this.$route.query.ref)
    }

    this.getAllUser()


  }
};
</script>
