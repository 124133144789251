import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";

export default class SedeService {
    constructor() {
        this.axios = axios.create(
            {
                baseURL: utiles.state.api,
                headers: {
                    Accept: "application/json",
                    'Content-Type': "application/json",
                    Authorization: "Bearer " + store.state.user.token
                }
            }
        );

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }

    async getAll() {
        const response = await this.axios.get('/sede');
        return response.data;
    }

    async create(data){
        const response = await this.axios.post('/sede', data)
        return response.data
    }

    async update(data)
    {
        const response = await this.axios.put('/sede', data)
        return response.data
    }

    async delete(idsede)
    {
        const response = await this.axios.delete('/sede?idsede=' + idsede)
        return response.data
    }
}