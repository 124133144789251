import store from "@/store";
import moment from "moment/moment";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Campo Requerido.",
        requiredSelect: (value) => value.length > 0 || "Campo Requerido.",
        counter: (value) => value.length <= 20 || "Max 20 caracteres",
        counter15: (value) => value.length <= 15 || "Max 15 caracteres",
        counter10: (value) => value.length <= 10 || "Max 10 caracteres",
        counter30: (value) => value.length <= 30 || "Max 30 caracteres",
        textmedium: (value) => value.length <= 50 || "Max 50 caracteres",
        counter100: (value) => value.length <= 100 || "Max 100 caracteres",
        textsimple: (value) => value.length >= 5 || "Min 5 caracteres",
        textareaSimple: (value) => value.length >= 20 || "Min 20 caracteres",
        celular: (value) => value.length == 9 || "Número de celular no válido",
        dni: (value) => value.length == 8 || "DNI: Solo debe tener 8 dígitos",
        onlyNumber: (value) => {
          const pattern = /^[0-9]*(\.?)[0-9]+$/;
          return pattern.test(value) || "Solo ingrese numeros";
        },
        mayorcero: (value) =>
          parseInt(value) > 0 || "El monto debe ser mayor que cero",
        notnegative: (value) =>
          parseInt(value) > -1 || "No se aceptan números negativos",
        email: (value) => {
          const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          return pattern.test(value) || "Email no válido";
        },
      },
      mesesLista: [
        {
          title: "Enero",
          value: 1,
        },
        {
          title: "Febrero",
          value: 2,
        },
        {
          title: "Marzo",
          value: 3,
        },
        {
          title: "Abril",
          value: 4,
        },
        {
          title: "Mayo",
          value: 5,
        },
        {
          title: "Junio",
          value: 6,
        },
        {
          title: "Julio",
          value: 7,
        },
        {
          title: "Agosto",
          value: 8,
        },
        {
          title: "Septiembre",
          value: 9,
        },
        {
          title: "Octubre",
          value: 10,
        },
        {
          title: "Noviembre",
          value: 11,
        },
        {
          title: "Diciembre",
          value: 12,
        },
      ],
    };
  },

  methods: {
    initialName(value) {
      if (value == null) return;
      var separate = value.split(" ");
      var fullname = separate[0].charAt(0);
      if (separate.length >= 2) {
        fullname += separate[1].charAt(0).toUpperCase();
      }
      return fullname;
    },

    oneName(value) {
      if (value == null) return;
      var separate = value.split(" ");
      return separate[0];
    },

    firstName(value) {
      if (value == null) return;
      var separate = value.split(" ");
      var lastname = "";
      var fullname = "";
      if (separate.length > 3) {
        lastname = separate[2].charAt(0).toUpperCase();
      }
      if (separate.length >= 2) {
        lastname = separate[1].charAt(0).toUpperCase();
      }

      fullname = separate[0] + " " + lastname;

      return fullname;
    },

    threeName(value) {
      if (value == null) return;
      var separate = value.split(" ");
      var fullname = "";
      var lastChart = "";
      if (separate.length >= 3) {
        lastChart = separate[2].charAt(0).toUpperCase();
        separate.pop();
      }
      fullname = separate.join(" ");
      return fullname + " " + lastChart;
    },

    fechasmall(format = "D MMM YYYY", fecha) {
      return moment(fecha).format(format);
    },

    capitalizeWords(inputString) {
      if (inputString == "") {
        return;
      }
      const words = inputString.split(" ");
      const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
          return word;
        }
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      });
      const resultString = capitalizedWords.join(" ");
      return resultString;
    },

    disableClass(estado) {
      if (estado && estado.isCierre) {
        return "close-ticket";
      }

      if (estado && estado.isAnulado) {
        return "cancel-ticket";
      }

      if (estado && estado.isAnulado && estado.isCierre) {
        return "close-ticket";
      }
    },

    isDisabled(estado) {
      if (estado && estado.isCierre) {
        return true;
      }

      if (estado && estado.isAnulado) {
        return true;
      }

      if (estado && estado.isAnulado && estado.isCierre) {
        return true;
      }

      return false;
    },

    validatePassword(newpass, confirmpass) {
      return newpass === confirmpass || "Las contraseñas no coinciden";
    },

    isRoleVisitor() {
      return store.state.user.role_user.role_default === 1;
    },

    isAgente() {
      return store.state.user.role_user.name === "AGENTE";
    },

    isAdminstrador(){
      return store.state.user.role_user.name === "SUPERADMINISTRADOR";
    },

    isMyID(user_id) {
      return store.state.user.iduser === user_id;
    },

    getFileNameFromURL(url) {
      if (!url) {
        return null;
      }
      const index = url.lastIndexOf("/");
      const fullName = url.substring(index + 1);
      const extensionIndex = fullName.lastIndexOf(".");
      const name = fullName.substring(0, extensionIndex);
      const extension = fullName.substring(extensionIndex + 1);
      const nameandextension = name + "." + extension;
      return { name, extension, nameandextension };
    },

    getTypeFile(type) {
      switch (type) {
        case "application/pdf":
          return ".pdf";
          break;
        case "image/jpeg":
          return ".jpeg";
          break;
        case "image/png":
          return ".png";
          break;
        case "image/jpg":
          return ".jpg";
          break;
        case "video/mp4":
          return ".jpg";
          break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return ".docx";
        case "application/vnd.ms-excel":
          return ".xls";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return ".xlsx";
        case "text/csv":
          return ".csv";
        case "application/msword":
          return ".doc";
        case "application/zip":
          return ".zip";
        default:
          return ".exe";
      }
    },
    iconExtension(extension) {
      switch (extension) {
        case "pdf":
          return "pdf.png";
          break;
        case "jpg":
          return "imagen.png";
          break;
        case "jpeg":
          return "imagen.png";
          break;
        case "png":
          return "imagen.png";
          break;
        case "svg":
          return "imagen.png";
          break;
        case "mp4":
          return "video.png";
          break;
        case "ppt":
          return "powerpoint.png";
          break;
        case "pptx":
          return "powerpoint.png";
          break;
        case "docx":
          return "doc.png";
          break;
        case "xls":
          return "excel.png";
          break;
        case "xlsx":
          return "excel.png";
          break;
        case "csv":
          return "excel.png";
          break;
        case "zip":
          return "zip.png";
          break;
        case "txt":
          return "txt.png";
          break;
        case "txt":
          return "txt.png";
          break;
        case "mp3":
          return "audio.png";
          break;
        default:
          return "file.png";
          break;
      }
    },

    ByteToMB(float) {
      const byte = float / 1000;
      return float ? Math.ceil(byte) : 0;
    },

    calculeHeightVH() {
      let navbar = document.getElementById("navbarcentral");
      let alturaNavbar;
      let footer = document.getElementById("footercentral");
      let alturaFooter;
      const windowHeight = window.innerHeight;

      if (navbar && footer) {
        alturaNavbar = navbar.offsetHeight || navbar.clientHeight;
        alturaFooter = footer.offsetHeight || footer.clientHeight;
      }
      const adjustedHeight = windowHeight - alturaNavbar - alturaFooter;

      console.log(windowHeight);

      return `${adjustedHeight}px`;
    },

    formatFechaCustom(value, format) {
      if (!value) return "--/--/--";
      return moment(value).format(format);
    },
  },
};
