<template>
    <v-row dense>
        <v-col cols="12" md="6" class="d-none d-md-flex">
            <v-img :src="require('@/assets/user-not-found.svg')" class="mx-auto" width="100%"></v-img>
        </v-col>
        <v-col cols="12" md="6">
            <v-card elevation="0">
                <v-card-text class="pa-0 pa-md-2">
                    <div class="mb-2 mt-2">
                        <div class="text-md-h5 text-h6 font-weight-bold mb-2">🚫 Acceso denegado
                        </div>
                        <span class="text-body-1">
                            "¡Ups! 😕 Parece que tu cuenta no está registrada con nosotros... aún.
                        </span>
                    </div>

                    <div class="my-2">
                        <div class="text-subtitle-1 font-weight-bold py-4">
                            ¿Qué debo hacer?
                        </div>
                        <div class="text-body-2">
                            <p class="py-2">
                                <b> 🔍 Revisa tu correo: </b>
                                Asegúrate de haber ingresado el correo asociado a tu cuenta
                                de la empresa.
                            </p>

                            <p class="py-2">
                                <b> 🛠️ Solicita acceso: </b>
                                Si crees que deberías poder acceder, contacta a soporte o a tu
                                administrador de TI para verificar tu registro.
                            </p>

                            <p class="py-2">
                                <b> 🚀 Únete a nosotros: </b>
                                Si eres nuevo, contacta al equipo de TIC para iniciar el
                                proceso de registro con tu cuenta.
                            </p>


                        </div>

                        <div class="my-3">
                            <v-btn @click="redirectLogin()" rounded="xl" size="large" color="indigo 900" block
                            class="font-weight-medium py-5" elevation="0">REGRESAR AL LOGIN</v-btn>
                        </div>
                    </div>

                    <v-img :src="require('@/assets/logo-tic.svg')" width="70px" class="mx-auto my-5" cover></v-img>
                </v-card-text>
            </v-card>

        </v-col>

    </v-row>
</template>


<script>
import { mapMutations } from 'vuex';


export default{

    methods:{
        ...mapMutations('login', ['switchComponent']),
        redirectLogin(){
            this.switchComponent('FormLoginComponent')
            this.$router.push({name: 'Login'})
        }
    }
}
</script>