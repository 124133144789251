<template>
  <v-navigation-drawer elevation="0" rail class="bgBlackTheme" width="60">
    <v-card class="bgLightTheme" elevation="0" rounded="0">
      <v-card-text class="text-white pa-2">
        <v-img :src="require('@/assets/cetemin-logo.png')" width="100%" cover></v-img>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>

    <v-list nav density="compact">

      <v-tooltip text="Dashboard">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" :to="{ name: 'Dashboard' }" color="white" class="text-white my-4"
            v-if="$abillity.can('read', 'panel-metricas')">
            <template v-slot:prepend>
              <v-icon size="18" style="margin-left: 2px;">fa:fa-solid fa-gauge</v-icon>
            </template>
          </v-list-item>
        </template>
      </v-tooltip>


      <v-tooltip text="Mi perfil">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" :to="{ name: 'profileUser' }" color="white" class="text-white my-4">
            <template v-slot:prepend>
              <v-icon size="18" style="margin-left: 2px;">fa:fa-solid fa-user</v-icon>
            </template>
          </v-list-item>
        </template>
      </v-tooltip>


      <v-tooltip text="Tickets">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" :to="{ name: 'tickets' }" color="white" class="text-white my-4"
            v-if="$abillity.can('read', 'tickets-area')">
            <template v-slot:prepend>
              <v-icon size="18" style="margin-left: 2px;">fa:fa-solid fa-ticket</v-icon>
            </template>
          </v-list-item>
        </template>
      </v-tooltip>


      <v-tooltip text="Gestionar Usuarios">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" :to="{ name: 'usuarios' }" color="white" class="text-white my-4"
            v-if="$abillity.can('read', 'usuarios-area')">
            <template v-slot:prepend>
              <v-icon size="18" style="margin-left: 2px;">fa:fa-solid fa-users-gear</v-icon>
            </template>
          </v-list-item>
        </template>
      </v-tooltip>


      <v-tooltip text="Configuración">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" :to="{ name: 'configuracion' }" color="white" class="text-white my-4"
            v-if="$abillity.can('read', 'configuracion-area')">
            <template v-slot:prepend>
              <v-icon size="18" style="margin-left: 2px;">fa:fa-solid fa-gear</v-icon>
            </template>
          </v-list-item>
        </template>
      </v-tooltip>

<!--
      <v-tooltip text="Chats">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" :to="{ name: 'ChatHome' }" color="white" class="text-white my-4"
            v-if="$abillity.can('read', 'configuracion-area')">
            <template v-slot:prepend>
              <v-icon size="18" style="margin-left: 2px;">fa:fa-solid fa-comment-dots</v-icon>
            </template>
          </v-list-item>
        </template>
      </v-tooltip>-->





    </v-list>
  </v-navigation-drawer>
</template> 

<style>
.bg-active {
  background-color: rgba(255, 255, 255, 0.086) !important;
}
</style>
