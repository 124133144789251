import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";

export default class AnswersService
{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers: {
                'Content-Type': "multipart/form-data",
                Accept: "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        })

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }


    async getList(idticket)
    {
        const response =  await this.axios.get('/answer?idticket=' + idticket); 
        return response.data;
    }

    async insert(data)
    {
        const response = await this.axios.post('/answer', data);
        return response.data;
    }

    async delete($idanswer)
    {
        const response = await this.axios.delete('/answer?idanswer=' + $idanswer);
        return response.data;
    }
}