import Module from './Module.vue';
import ListaUsuariosView from './views/ListaUsuariosView'
const moduleRoute = {
    path: "/usuarios",
    component: Module,
    children: [
      {
        path: "", 
        component: ListaUsuariosView,
        name: "usuarios",
        meta: {
          requiresAuth: true,
          title: "Usuarios",
        },
      },


    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };