export default{
    namespaced: true,
    state:{
        listaAgentes: []
    },

    mutations:{
        setAgentes(state, payload){
            state.listaAgentes = payload;
        }
    },


    actions:{

        getAgentesAxios({commit, state}){
            let data = [
                {
                    id: 1,
                    nombre: "Daniel Arellano"
                },
                {
                    id: 2,
                    nombre: "Roy Chavez"
                },
                {
                    id: 3,
                    nombre: "Abel Quiroz"
                },
                {
                    id: 4,
                    nombre: "Henry Cardenas"
                }
            ]
            setTimeout( () => commit('setAgentes', data),300);
        }
    }
}