import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";

export default class MetricaService{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers: {
                'Content-Type': "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        })

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }

    async getCountTicketPorMes()
    {
        const response =  await this.axios.get('/count-tickets-mensual' );  
        return response.data;
    }

    async getCountTipoTicket(params)
    {
        const response =  await this.axios.get('/count-tickets-tipo', {params: params});  
        return response.data;
    }


    async getCountCategoriaTicket(params)
    {
        const response =  await this.axios.get('/count-tickets-categoria', {params: params}); 
        return response.data;
    }


    async getCountTicketEstado(params)
    {
        const response =  await this.axios.get('/count-tickets-estado', {params: params});  
        return response.data;
    }

    /**
     * AGENTE
     */

    async getCountTicketAgente(params)
    {
        const response =  await this.axios.get('/count-tickets-agente', {params: params});  
        return response.data;
    }


    async getCountTicketAgenteEstado(params)
    {
        const response =  await this.axios.get('/count-tickets-agente-estado', {params: params});  
        return response.data;
    }


    async getCountTicketAssigned(params)
    {
        const response =  await this.axios.get('/count-tickets-asignaciones', {params: params});  
        return response.data;
    }
}