<template>
  <v-card elevation="0" class="bg-white" variant="outlined" style="border: 1px solid #cfd7df !important;">
    <v-card-title class="text-subtitle-2 bgLight2Theme">Detalles del contacto</v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <div class="text-caption py-1"><v-icon start>mdi:mdi-email-outline</v-icon>{{ ticket.correo }}</div>
      <div class="text-caption py-1"><v-icon start>mdi:mdi-phone-in-talk</v-icon>{{ ticket.celular }}</div>
      <div class="text-caption py-1"><v-icon start>mdi:mdi-whatsapp</v-icon>{{ ticket.whatsapp }}</div>
    </v-card-text>

  </v-card>
</template>


<script>
import Filters from '@/modules/Shared/Filters';


export default {
  mixins: [Filters],
  props: {
    ticket: {
      type: Object,
      require: true
    }
  }
}
</script>