<template>
    <v-select hide-details="auto" density="compact" :items="listAgentes"  item-value="idagente" :readonly="readonly"
    :style="{width: width}"
        style="border-bottom: none !important;" bg-color="bg-transparent" hide-no-data class="select-template"
        v-model="setectedItem" :menu-icon="readonly ? '' : 'fa:fa-solid fa-chevron-down fa-2xs'" :loading="loadingEstado"
        @update:modelValue="changeSelect({ idticket: idticket, agente_new: setectedItem })">
        <template v-slot:selection="{ item }">
            <small class="font-italic font-weight-bold" v-if="item.title == ''">{{readonly ? 'sin asignar' : 'Asignar agente'}}</small>
            <div class="d-flex align-center" v-else>
              <v-avatar start size="20" rounded="sm" 
                        class="mr-2 text-grey-darken-4 bg-grey-lighten-2"><v-icon size="10">fa:fa-solid fa-person</v-icon></v-avatar>
                <div style="font-size: 12px" class="font-weight-bold">{{ oneName(item.raw.user.nombres) + ' ' + oneName(item.raw.user.apellidos)  }}</div>
            </div>
        </template>
        <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="''" :title="''">
                <template v-slot:prepend>
                    <v-avatar start size="25" rounded="sm" 
                        class="mr-2 text-grey-darken-4 bg-grey-lighten-2"><v-icon size="15">fa:fa-solid fa-person</v-icon></v-avatar>
                </template>
                <v-list-item-title style="font-size: 12px" class="font-weight-bold">
                    {{  oneName(item.raw.user.nombres) + ' ' + oneName(item.raw.user.apellidos) }}
                </v-list-item-title>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import { mapActions, mapState } from "vuex";


export default {
  mixins:[
  Filters
  ],
  props: {
    agente: {
      type: Object,
    },
    width:
    {
      type: String,
      default: '180px'
    },
    idticket:
    {
      type: Number
    },
    readonly:{
      type: Boolean,
      default: false
    }
    
  },
  data()
  {
    return{
      loadingEstado:false,
      setectedItem: null
    }
  },
  computed: {
    ...mapState('configuracion/agente', ['listAgentes'])
  },
  methods: {
    ...mapActions('tickets', ['changeAgenteAxios']),
    async changeSelect(data) {
            this.loadingEstado = true
            await this.changeAgenteAxios(data)
            this.loadingEstado = false

        }
  },
  mounted(){
    this.setectedItem = this.agente ? this.agente.idagente : ''
  }
};
</script>
