import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";

export default class RegisterUserService
{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers:
            {
                Accept: "application/json",
                'Content-Type': "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        });

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }

    async getAll(data)
    {
        const response = await this.axios.get('/user', { params: data})
        return response.data
    }

    async createUser(data)
    {
        const response = await this.axios.post('/user', data)
        return response.data
    }

    async registerSolicitante(data)
    {
        const response = await this.axios.post('/register-solicitante', data)
        return response.data
    }

    async updatePassword(data)
    {
        const response = await this.axios.put('/password-update-solicitante', data)
        return response.data
    }

    async updateUser(data)
    {
        const response = await this.axios.put('/user', data)
        return response.data
    }

    async deleteUser(iduser)
    {
        
        return await this.axios.delete('/user?iduser='+iduser)
    }
}