<template>
  <v-app-bar :elevation="0" density="compact" class="" id="navbarcentral">
    <v-container fluid class="d-flex align-center">
      <div class="d-flex align-center">
        <v-img :src="require('@/assets/logo.svg')" width="120px" cover></v-img>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-img :src="require('@/assets/logo-tic.svg')" width="70" class="mr-4" cover></v-img>
      </div>
      <v-menu v-model="menu" :close-on-content-click="false" location="bottom" transition="scale-transition">
        <template v-slot:activator="{ props }">
          <div class="d-flex align-center justify-end" v-bind="props" style="cursor: pointer;">
            <v-avatar v-if="user.avatar" class="bgBlackTheme text-white">
              <img :src="user.avatar" alt="Avatar" width="40">
            </v-avatar>
            <v-avatar v-else class="bgBlackTheme text-white">
              <h4>{{ initialName(user.nombres + ' ' + user.apellidos) }}</h4>
            </v-avatar>
            <div class="ml-2 d-none d-md-block pt-2">
              <div class="text-subtitle-2 font-weight-bold" style="line-height: 0.3;">{{
                capitalizeWords(oneName(user.nombres) + ' '
                  + oneName(user.apellidos)) }}</div>
              <small>{{ user.role_user.name.toLowerCase() }}</small>

            </div>
          </div>
        </template>
        <v-card :width="$vuetify.display.mdAndUp ? '400' : '350'" elevation="0" class="rounded-lg px-4"
          style="box-shadow: 0 2px 6px #2553b91a!important;">
          <v-card-title>
            <div class="d-flex align-center text-subtitle-1">Perfil de usuario</div>
          </v-card-title>
          <v-card-text class="d-flex align-center">
            <v-avatar v-if="user.avatar" class="mr-3" style="background-color: #e2f5fb !important;" size="80">
              <img :src="user.avatar" alt="Avatar" width="80">
            </v-avatar>
            <v-avatar v-else class="mr-3" style="background-color: #e2f5fb !important;" size="80">
              <div class="text-h4">{{ initialName(user.nombres + ' ' + user.apellidos) }}</div>
            </v-avatar>
            <div>
              <div class="d-block text-h6 font-weight-bold" style="line-height: 1.5;">{{
                capitalizeWords(oneName(user.nombres) +
                  ' '
                  + oneName(user.apellidos)) }}</div>
              <div class="d-block mr-1" style="line-height: 1;">{{ user.role_user.name.toLowerCase() }}</div>
              <div class="d-block mt-2" style="line-height: 1;">{{ user.email }}</div>
            </div>
          </v-card-text>
          <div class="px-2 px-md-4">
            <v-list density="compact">
              <v-list-item :to="{ name: 'profileUser' }" density="compact">
                <template v-slot:prepend>
                  <v-avatar size="48" class="rounded-lg bg-teal-lighten-5 text-teal-accent-4">
                    <v-icon icon="fa:fa-solid fa-user"></v-icon>
                  </v-avatar>
                </template>
                <v-list-item-title>Mi perfil</v-list-item-title>
                <v-list-item-subtitle>Editar mi perfil</v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-btn @click="logout()" rounded="xl" block  elevation="0" variant="outlined" color="teal-accent-4" class="text-teal-accent-4 my-4">Cerrar sesión</v-btn>
          </div>
        </v-card>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapState, mapActions } from 'vuex';
export default {
  mixins: [
    Filters
  ],
  data() {
    return {
      menu: false
    }
  },

  computed:
  {
    ...mapState(['user'])
  },

  methods: {
    ...mapActions('login', ['logout'])
  }
}
</script>