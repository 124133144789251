<template>
    <div id="wrapper2">
          <div id="div2" :style="{'width':widthScroll}">
          </div>
        </div>
</template>

<script>
export default {
    name: "scroller",
    data(){
        return{
            widthScroll: ""
        }
    },
    mounted(){
      const table = document.querySelector('.table-reference .v-table__wrapper table')
      this.widthScroll = table.scrollWidth + 'px';
      let wrapper_1 = document.querySelector('.table-reference .v-table__wrapper');
      let wrapper_2 = document.querySelector('#wrapper2');
      wrapper_1.onscroll = function() {
        wrapper_2.scrollLeft = wrapper_1.scrollLeft;
      };
        
      wrapper_2.onscroll = function() {
        wrapper_1.scrollLeft = wrapper_2.scrollLeft;
      };
    },
}
</script>


<style>

/* This is the size of the path of the scrollbar */
#wrapper2{
      width: 100%;
      overflow-x: scroll; 
      overflow-y:hidden;
  }

/* This div allow to make the scroll function and show the scrollbar */
  #div2 {
      height: 1px;
      overflow: scroll;
  }

  /* This is the size of the scrollbar */
  .width-scroll{
    width:2000px;
  }

</style>