<template>
    <v-card v-if="ticket" elevation="0" class="bg-white" variant="outlined" style="border: 1px solid #cfd7df !important;" :key="component_key">
        <v-card-title class="text-subtitle-2">
            <small>Código Tickeet</small>
            <div class="pa-2 bg-grey-darken-4 text-white rounded">
                <code style="font-size: 20px;">
                        {{ ticket.codigo_ticket }}
                        </code>
            </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <small>Sede seleccionada</small>
            <div class="font-weight-bold text-subtitle-2">{{ ticket.sede }}</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
            <small v-if="!readonly">Actualizar ticket</small>

            <div class="mt-2">
                <div class="mb-2 text-subtitle-2">Tipo</div>
                <TipoTicketComponent :readonly="readonly" :disabled="isDisabled(ticket.estado)" :tipo="ticket.tipo"
                    :idticket="ticket.idticket" :width="'100%'"></TipoTicketComponent>
            </div>
            <v-divider v-if="!readonly"></v-divider>
            <div class="mt-2">
                <div class="mb-2 text-subtitle-2">Estado</div>
                <EstadoTIcketComponent :readonly="readonly" :disabled="ticket.cierre_confirmado == 1 || (ticket.estado && ticket.estado.isAnulado)" :estado="ticket.estado" :idticket="ticket.idticket"
                    :width="'100%'">
                </EstadoTIcketComponent>
            </div>
            <v-divider v-if="!readonly"></v-divider>
            <div class="mt-2">
                <div class="mb-2 text-subtitle-2">Prioridad</div>
                <PrioridadTicketComponent :readonly="readonly" :disabled="isDisabled(ticket.estado)"
                    :prioridad="ticket.prioridad" :idticket="ticket.idticket" :width="'100%'"></PrioridadTicketComponent>
            </div>
            <v-divider v-if="!readonly"></v-divider>
            <div class="mt-2">
                <div class="mb-2 text-subtitle-2">Agente</div>
                <AgenteTicketComponent :readonly="readonly" :disabled="isDisabled(ticket.estado)"
                    :idticket="ticket.idticket" :width="'100%'" :agente="ticket.agente">
                </AgenteTicketComponent>
            </div>
        </v-card-text>
    </v-card>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import TipoTicketComponent from '../TipoTicketComponent.vue';
import PrioridadTicketComponent from '../PrioridadTicketComponent.vue';
import EstadoTIcketComponent from '../EstadoTIcketComponent.vue';

import Filters from '@/modules/Shared/Filters';
import AgenteTicketComponent from '../AgenteTicketComponent.vue';
import { socket } from '@/ws-client';

export default {
    mixins: [Filters],
    props:
    {
        readonly: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TipoTicketComponent,
        PrioridadTicketComponent,
        EstadoTIcketComponent,
        AgenteTicketComponent
    },

    data()
    {
        return{
            component_key: 0
        }
    },

    computed:
    {
        ...mapState("configuracion/tipo", ["listTipo"]),
        ...mapState("configuracion/categoria", ["listCategorias", "loading"]),
        ...mapState("configuracion/prioridad", ["listPrioridad"]),
        ...mapState("configuracion/estado", ["listEstados"]),
        ...mapState('tickets', ['ticket']),
    },

    methods:{
        ...mapActions('tickets', ['getTicket']),
    },

    mounted() {
        socket.on('UPDATE_PREVIEW_TICKET', data => {
            this.getTicket(this.$route.params.codigoticket)

            
        })

        this.getTicket(this.$route.params.codigoticket)
    },

    watch:
    {
        ticket:{
            handler(newlav, oldval)
            {
                this.component_key += 1
            }
        }
    }
}
</script>


