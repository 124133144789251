import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";

export default class TipoService {
    constructor() {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers: {
                'content-type': "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        });

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }

    async getAll() {
        const response = await this.axios.get('tipo');
        return response.data;
    }


    async createTipo(data) {
        const response = await this.axios.post('tipo', data);
        return response.data;
    }


    async updateTipo(data) {
        const response = await this.axios.put('tipo', data);
        return response.data;
    }

    async deleteTipo(id) {
        const response = await this.axios.delete('tipo?idtipo=' + id );  
        return response.data;
    }
}