import Module from './Module.vue';
import DashboardView from './views/DashboardView.vue';

const moduleRoute = {
    path: "/dashboard",
    component: Module,
    children: [
      {
        path: "",
        redirect: "/ticket-overview",
        component: DashboardView,
        name: "Dashboard",
        meta:{
          title: "Dashboard"
        }
      },
      {
        path: "/ticket-overview",
        component: () => import('./views/DashboardView.vue'),
        children: [
          {
            path: "",
            components: {
              dashboardview: () => import('./components/TicketOverviewComponent.vue'),
            },
            name: "dashboard-ticketsopen",
            meta: {
              requiresAuth: true,
              title: "Configuración - Resumen de Tickets (Ticket Overview) ",
            },
          }
        ],
      },
      {
        path: "/agente-perfomance",
        component: () => import('./views/DashboardView.vue'),
        children: [
          {
            path: "",
            components: {
              dashboardview: () => import('./components/AgentPerfomanceComponent.vue'),
            },
            name: "dashboard-agente-perfomance",
            meta: {
              requiresAuth: true,
              title: "Configuración - Resumen de Tickets (Ticket Overview) ",
            },
          }
        ],
      },
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };