<template>
    <v-card v-if="ticket" elevation="0" class="bg-white" variant="outlined"
        style="border: 1px solid #cfd7df !important;" :key="component_key">
        <v-card-title v-if="isAdminstrador() || (user.iduser === ticket.solicitante.iduser)">
            <div class="text-subtitle-2">Ticket con copia a:</div>
            <div style="font-size: 10px !important;" class="text-caption"> Envia una copia de este ticket.
            </div>
        </v-card-title>
        <v-card-text>
            <div v-if="isAdminstrador() || (user.iduser === ticket.solicitante.iduser)">
                <label for="" class="font-weight-medium" style="font-size: 12px !important;">Agregar correo/s:</label>
                <v-autocomplete variant="outlined" density="compact" bg-color="grey-lighten-5" hide-details="auto"
                    item-value="email" :item-title="(val) => oneName(val.nombres) + ' ' + val.apellidos"
                    :items="listarCorreosSinCC" multiple chips v-model="computedNewCopyTicketUsers" closable-chips
                    :disabled="loadingAddCopyTicketUsers">
                    <template v-slot:chip="{ props, item }">
                        <v-chip label v-bind="props">
                            <v-avatar v-if="item.raw.avatar" size="20" class="bg-indigo-accent-4 text-white mr-2">
                                <img :src="item.raw.avatar" alt="Avatar" width="20">
                            </v-avatar>
                            <v-avatar v-else size="20" class="bg-indigo-accent-4 text-white mr-2">
                                <span style="font-size: 11px;">{{ initialName(item.raw.nombres + ' ' +
                                    item.raw.apellidos)
                                    }}</span>
                            </v-avatar>
                            <span class="text-truncate d-inline-block font-weight-bold"
                                style="font-size: 11px; max-width: 150px;">{{ item.title }}</span>
                        </v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                        <v-list-item v-bind="props" density="compact" :subtitle="item.raw.group"
                            :title="item.title"></v-list-item>
                    </template>
                </v-autocomplete>
                <v-btn :disabled="computedNewCopyTicketUsers.length == 0" @click="addCopyTicketUsers" elevation="0"
                    variant="outlined" color="primary" size="small" class="mt-2"><v-icon
                        start>mdi:mdi-send</v-icon>ENVIAR</v-btn>
            </div>
            <div v-else>
                <div class="text-subtitle-2" v-if="ticket.copy_ticket_users.length > 0">Este ticket ha sido compartido con:</div>
                <div class="text-center" v-else>
                    <v-chip label class="text-center bg-white" size="small"><v-icon class="mr-2">mdi:mdi-lock-check-outline</v-icon>Ticket privado</v-chip>
                </div>
            </div>
            <div class="py-2">
                <v-card v-for="us in ticket.copy_ticket_users" variant="outlined"
                    class="modern-elevated rounded-lg my-2 pa-1" width="100%" style="border: 1px solid #e0e0e0;">
                    <v-card-text class="d-flex align-center pa-0 px-1">
                        <v-avatar class="bg-indigo-accent-4 text-white mr-2" size="30">
                            <span>{{ initialName(us.user.nombres + ' ' + us.user.apellidos) }}</span>
                        </v-avatar>
                        <v-card-text class="pa-0">
                            <span class=" mr-2 font-weight-bold text-truncate d-inline-block"
                                style="max-width: 150px; font-size: 12px; line-height: 1;">
                                {{ capitalizeWords(oneName(us.user.nombres) + ' ' + oneName(us.user.apellidos)) }}
                            </span>
                            <span class="text-truncate d-inline-block" style="font-size: 11px; max-width: 150px;">
                                {{ us.email }}
                            </span>
                        </v-card-text>
                        <v-icon size="18" class="ml-auto" v-if="user.iduser === ticket.solicitante.iduser"
                            @click="deleteOneCopyTicketUsers(us.id)">mdi:mdi-trash-can-outline</v-icon>
                    </v-card-text>

                 </v-card>

                <v-progress-linear
                    v-if="loadingGetCopyTicketUsers || loadingAddCopyTicketUsers || loadingDeleteCopyTicketUsers"
                    indeterminate color="primary" size="18"></v-progress-linear>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Filters from '@/modules/Shared/Filters';
export default {
    mixins: [Filters],
    computed: {
        ...mapState(['user']),
        ...mapState('tickets', ['ticket', 'newCopyTicketUsers', 'loadingAddCopyTicketUsers', 'loadingGetCopyTicketUsers', 'loadingDeleteCopyTicketUsers']),
        ...mapState('usuarios', ['listaUsuarios']),
        listarCorreosSinCC() {
            let listaCc = this.listaUsuarios.filter(usuario => !this.ticket.copy_ticket_users.some(user => user.email === usuario.email));
            let newLista = listaCc.filter(elm => elm.email !== this.ticket.correo && elm.role_user.name !== 'AGENTE' && elm.role_user.name !== 'SUPERADMINISTRADOR')
            return newLista;
        },
        computedNewCopyTicketUsers: {
            set(value) {
                this.setNewCopyTicketUsers(value)
            },
            get() {
                return this.newCopyTicketUsers
            }
        }
    },

    methods: {
        ...mapActions('tickets', ['addCopyTicketUsers', 'deleteOneCopyTicketUsers', 'getCopyTicketUsers']),
        ...mapMutations('tickets', ['setNewCopyTicketUsers']),
    },
    watch: {
        ticket: {
            handler(newVal, oldVal) {
                //this.getCopyTicketUsers()
                this.component_key += 1
            },
            deep: true
        }
    },


}
</script>