import utiles from "@/modules/Shared/utiles/utiles";
import SedeService from "../services/SedeService"
import Swal from "sweetalert2";

const SedeSV = new SedeService();

export default
{
    namespaced: true,
    modules: {
        utiles
    },
    state:
    {
        listSedes: [],
        sede:
        {
            nombresede: "",
            ubicacion: "",
            abreviatura: ""
        },

        sedeDefault:
        {
            nombresede: "",
            ubicacion: "",
            abreviatura: ""
        },

        loading: false
    },

    mutations:
    {
        setSede(state, payload)
        {
            state.sede = Object.assign({}, payload)
        },

        setSedes(state, payload)
        {
            state.listSedes = payload
        },

        editSede(state, sede)
        {
            state.sede = sede
        },

        resetSede(state)
        {
            state.sede = Object.assign({}, state.sedeDefault)
        }
    },


    actions:
    {
        async getSedesAxios({commit,state})
        {
            state.loading = true;

            try{

                const data = await SedeSV.getAll();

                state.loading = false;

                commit('setSedes', data)

            }catch(error)
            {
                console.log(error)

                state.loading = false;
            }
        },

        async createSedeAxios({commit, dispatch, state})
        {
            commit('utiles/alertLoading')
            try
            {
                const data = await SedeSV.create(state.sede)

                commit('resetSede')

                commit('utiles/alertConfirm', 'Se registró una nueva sede')

                dispatch('getSedesAxios')
            }
            catch(error)
            {
                console.log(error)

                commit('utiles/alertError')
            }
        },

        async updateSedeAxios({commit, dispatch, state})
        {
            commit('utiles/alertLoading')
            try
            {
                const data = await SedeSV.update(state.sede)

                commit('resetSede')

                commit('utiles/alertConfirm', 'Se actualizó la sede')

                dispatch('getSedesAxios')
            }
            catch(error)
            {
                console.log(error)

                commit('utiles/alertError')
            }
        },

        async deleteSedeAxios({commit, dispatch, state}, idsede)
        {
           const confirm = await Swal.fire({
                title: "¿Desea eliminar esta sede?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: "CONFIRMAR"
            })

            if(!confirm.isConfirmed){return }

            commit('utiles/alertLoading')
            try
            {
                await SedeSV.delete(idsede)

                commit('utiles/alertConfirm', 'Se eliminó la sede')

                dispatch('getSedesAxios')
            }
            catch(error)
            {
                console.log(error)

                commit('utiles/alertError')
            }
        },
    }

}