export default {
  data(){
    return {
      rules: {
        required: (value) => !!value || "Campo Requerido.",
        counter: (value) => value.length <= 20 || "Max 20 caracteres",
        textmedium: (value) => value.length <= 50 || "Max 50 caracteres",
        textsimple: (value) => value.length >= 5 || "Min 5 caracteres",
        textareaSimple: (value) => value.length >= 20 || "Min 20 caracteres",
        celular: (value) => value.length == 9 || "Número de celular no válido",
        dni: (value) => value.length == 8 || "DNI: Solo debe tener 8 dígitos",
        onlyNumber: (value) => {
          const pattern = /^[0-9]*(\.?)[0-9]+$/;
          return pattern.test(value) || "Solo ingrese numeros";
        },
        mayorcero: (value) =>
          parseInt(value) > 0 || "El monto debe ser mayor que cero",
        notnegative: (value) =>
          parseInt(value) > -1 || "No se aceptan números negativos",
        email: (value) => {
          const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          return pattern.test(value) || "Email no válido";
        },

      },
    }
  },
  methods: {
    initialName(value) {
      if (value == null) return;
      var separate = value.split(" ");
      var fullname = separate[0].charAt(0);
      if (separate.length >= 2) {
        fullname += separate[1].charAt(0).toUpperCase();
      }
      return fullname;
    },

    firstName(value) {
      if (value == null) return;
      var separate = value.split(" ");
      var lastname = "";
      var fullname = "";
      if (separate.length > 3) {
        lastname = separate[2].charAt(0).toUpperCase();
      }
      if (separate.length >= 2) {
        lastname = separate[1].charAt(0).toUpperCase();
      }

      fullname = separate[0] + " " + lastname;

      return fullname;
    },

    fechasmall(format = "D MMM YYYY", fecha) {
      return moment(fecha).format(format);
    },
  },
};
